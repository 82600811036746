import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Checkout.css'
import { GoogleMap, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';
import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Box, TextField, FormControlLabel, Radio, FormHelperText, CircularProgress, Skeleton, FormControl, InputLabel , LinearProgress, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';    
import { CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { calculateAreaFromCoordinates } from '../../functions/calculateAreaFromCoordinates';
import { calculateCoordinatesFromPath } from '../../functions/calculateCoordinatesFromPath';
import { calculatePolygonArea } from '../../functions/calculatePolygonArea';
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import { returnZipCodeLatLng } from '../../functions/zipCodes';
import { convertSquareMetersToSquareKm } from '../../functions/convertSquareMetersToSquareKm';
import PaymentMethod from '../PaymentMethod/PaymentMethod';

import { AddCircle, Check, CreditCard, Error, Explore, Info, InfoRounded, NavigateBefore, NavigateNext, RestartAlt, TravelExplore, } from '@mui/icons-material';


import googleMapsStyle from '../../assets/googleMapsStyle.json'
import usZipCodes from '../../assets/usZipCodes.json'
import { formatAsDollar } from '../../functions/formatAsDollar';
import AuthInterface from '../AuthInterface/AuthInterface';
import { ControlCamera } from '@mui/icons-material';


function Checkout(props) {

    // get lotId from url 
    const { lotId, zipCode } = useParams();

    const mode = props.mode
    const sharedStateObj = props.sharedStateObj
    const devMode = props.devMode

    const configOptions = {
        "createLot": {
            header: "Create Lot",
            headerIcon: <TravelExplore style={{marginRight: '10px'}}/>,
            marks: {
                select: {
                    value: 0,
                    label: 'Select',
                },
                configure: {
                    value: 1,
                    label: 'Configure',
                },                
                account: {
                    value: 2,
                    label: 'Account',
                },
                choose: {
                    value: 3,
                    label: 'Choose',
                },
                checkout: {
                    value: 4,
                    label: 'Checkout',
                }
            },
            getOrgPaymentMethodsOnLoad: true
        },
        "addSubscription": {
            header: "Add Subscription",
            headerIcon: <TravelExplore style={{marginRight: '10px'}}/>,
            marks: {               
                account: {
                    value: 2,
                    label: 'Account',
                }, 
                choose: {
                    value: 3,
                    label: 'Choose',
                },
                checkout: {
                    value: 4,
                    label: 'Checkout',
                }
            },
            getOrgPaymentMethodsOnLoad: true
        },
        "welcome":{
            "header": "Start Exploring",
            "headerIcon": <TravelExplore style={{marginRight: '10px'}}/>,
            "marks": {
                select: {
                    value: 0,
                    label: 'Select',
                },
                configure: {
                    value: 1,
                    label: 'Configure',
                },
                account: {
                    value: 2,
                    label: 'Account',
                },
                choose: {
                    value: 3,
                    label: 'Choose',
                },
                checkout: {
                    value: 4,
                    label: 'Checkout',
                }
            },
            getOrgPaymentMethodsOnLoad: false
        }
    }

    const configObj = configOptions[mode]

    const orgObj = props.organizationObj
    const firestore = firebase.firestore();
    const theme = useTheme();
    const stripe = useStripe();
    const auth = props.auth;
    const user = props.user
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
    const currentLocationRef  = useRef({lat: 28.5015, lng: -81.38541});

    const pricingSchedule = props.generalAppConfig?.StripePricingSchedule ?? {}


    const mapRef = useRef(null);
    const [polygonBounds, setPolygonBounds] = React.useState([{lat: 0, lng: 0}]);
    const polygonInstanceRef = useRef(null);
    const polygonAreaRef = useRef(0);
    const [polygonArea, setPolygonArea] = React.useState(0);

    const [tifSquares, setTifSquares] = React.useState([])

    const [lotBillingData, setLotBillingData] = React.useState({})
    const [lotDisplayName, setLotDisplayName] = React.useState("My Lot")
    const [lotDisplayNameTouched, setLotDisplayNameTouched] = React.useState(false)
    const lotDisplayNameMaxLength = 50
    const [lotDescription, setLotDescription] = React.useState("")
    const [lotDescriptionTouched, setLotDescriptionTouched] = React.useState(false)
    const lotDescriptionMaxLength = 100
    const [lotUseCase, setLotUseCase] = React.useState("")
    const [lotUseCaseSelect, setLotUseCaseSelect] = React.useState("")
    const [lotUseCaseTouched, setLotUseCaseTouched] = React.useState(false)
    const lotUseCaseMaxLength = 500
    const [selectedPricingPlan, setSelectedPricingPlan] = React.useState("monthlyBasemaps");

    const [zipCodeTextInput, setZipCodeTextInput] = useState(zipCode)
    const [userLot, setUserLot] = useState({})
    const [mapTypeId, setMapTypeId] = useState('roadmap');

    const [currentStep, setCurrentStep] = useState(getActiveMarks()[0].key)

    const errorMessageRef = useRef(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const [getPaymentMethodsLoading, setGetPaymentMethodsLoading] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
    const [createLoading, setCreateLoading] = useState(false)

    const [organizationLots, setOrganizationLots] = useState({})

    const navigate = useNavigate()
    const routeLocation = useLocation()

    const rectangleOptions = {
        fillColor: "#000000",
        fillOpacity: .4,
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    }

    const mapOptions = {
        styles: googleMapsStyle
    }

    const { isLoaded } = useLoadScript({    
        googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
     })

    const lotUseCases = [
        {
            key: "null",
            value: "",
            display: '',
        },
        {
            key: 'agriculture',
            value: 'Agriculture',
            display: 'Agriculture',
        },
        {
            key: 'climageMonitoring',
            value: 'Climate Monitoring',
            display: 'Climate Monitoring',
        },
        {
            key: 'educationResearch',
            value: 'Education & Research',
            display: 'Education & Research',
        },
        {
            key: 'environmentalMonitoring',
            value: 'Environmental Monitoring',
            display: 'Environmental Monitoring',
        },
        {
            key: 'landUsePlanning',
            value: 'Land Use Planning',
            display: 'Land Use Planning',
        },
        {
            key: 'realEstate',
            value: 'Real Estate',
            display: 'Real Estate',
        },
        {
            key: 'recreation',
            value: 'Recreation',
            display: 'Recreation',
        },
        {
            key: 'urbanPlanning',
            value: 'Urban Planning',
            display: 'Urban Planning',
        },
        {
            key: 'other',
            value: 'Other',
            display: 'Other',
        }
    ]

     
    const handlePolygonDrag = () => {
        if (polygonInstanceRef.current) {
            const area = calculatePolygonArea(polygonInstanceRef.current);
            polygonAreaRef.current = area;
            setPolygonArea(area);

            //set the polygon bounds
            const path = polygonInstanceRef.current.getPath();
            const coordinates = calculateCoordinatesFromPath(path)
            setPolygonBounds(coordinates)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_polygonBounds: coordinates
            }
        }
    }

    const handlePolygonLoad = (polygon) => {
        polygonInstanceRef.current = polygon;
        const area = calculatePolygonArea(polygon);
        polygonAreaRef.current = area;
        setPolygonArea(area);
    }
    

    const handleOnLoad = map => {
        mapRef.current = map;
        handleCenterChanged(map);

        var initCreatePolygon = setInterval(() => {
            if(mapRef.current.getBounds() != null){
                            
                clearInterval(initCreatePolygon)

                if(zipCode != null && returnZipCodeLatLng(zipCode) != null){
                    createPolygonAtCenter()
                    setMapTypeId('hybrid')

                }

                calculatePolygonArea(polygonInstanceRef.current)
            }
        }, 500);


    };

    useEffect(() => {

        //init shared state
        if(sharedStateObj.sharedStateRef.current.checkout_polygonBounds != null){
            const coordinates = sharedStateObj.sharedStateRef.current.checkout_polygonBounds
            setPolygonBounds(coordinates)            
            const area = calculateAreaFromCoordinates(coordinates);
            polygonAreaRef.current = area;
            setPolygonArea(area);
        }
        if(sharedStateObj.sharedStateRef.current.checkout_selectedPricingPlan != null){
            setSelectedPricingPlan(sharedStateObj.sharedStateRef.current.checkout_selectedPricingPlan)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_lotDisplayName != null){
            setLotDisplayName(sharedStateObj.sharedStateRef.current.checkout_lotDisplayName)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_lotUseCase != null){
            setLotUseCase(sharedStateObj.sharedStateRef.current.checkout_lotUseCase)
        }
        if(sharedStateObj.sharedStateRef.current.checkout_currentStep != null){

            var nextOption = null
            try{
                nextOption = getActiveMarks().find((mark) => mark.value >= configObj['marks'][sharedStateObj.sharedStateRef.current.checkout_currentStep].value)?.key
            }catch(err){
                console.error(err)
            }

            if(getActiveMarks().map((mark) => mark.key).includes(sharedStateObj.sharedStateRef.current.checkout_currentStep)){
                changeStep(sharedStateObj.sharedStateRef.current.checkout_currentStep)
            }else if(nextOption != null){                
                changeStep(nextOption)
                
            }else{
                changeStep(getActiveMarks()[0].key)

            }

        }

        getOrganizationBillingLots()

    }, [])

    useEffect(() => {
        if(zipCode != null && returnZipCodeLatLng(zipCode) != null){
            navigateToZipCode(zipCode)

        }else{
            if (navigator.geolocation) {
                try{
                  navigator.geolocation.getCurrentPosition(function(resp){
                    var userLocationElement = {
                      lat: resp.coords.latitude,
                      lng: resp.coords.longitude
                    }
          
                    setLocation(userLocationElement)                      
                  });
                }catch(err){ 
                  console.error(err)
                }
              }
        }

        if(configObj.getOrgPaymentMethodsOnLoad){
            getOrgPaymentMethods()
        }

      }, []);

    useEffect(() => {

        //if lotId is in the url, get the lot data
        if(lotId != null){ 
            const query = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').doc(lotId).get();
            query.then((doc) => {
                if(doc.exists){
                    const data = doc.data()

                    setLotBillingData(data ?? {})
                    setPolygonBounds(data.polygon ?? [{lat: 0, lng: 0}])
                    setLotDisplayName(data.displayName ?? "")
                    setLotUseCase(data.useCase ?? "")
                    setLotUseCaseSelect(data.useCaseSelect ?? "")
                    
                    const area = calculateAreaFromCoordinates(data.polygon ?? [{lat: 0, lng: 0}]);
                    polygonAreaRef.current = area;
                    setPolygonArea(area);

                }
            })
            .catch((error) => {
                createAlert("error", "There was an error getting the lot data")
                console.error("Error getting lot data: ", error)
            });


        }

    }, [])

    useEffect(() => {

        var errorMessageInterval = setInterval(() => {
            if(errorMessageRef.current != null){
                setErrorMessage(errorMessageRef.current)
            }else{
                setErrorMessage(null)
            }
        }, 100);

        //clear interval on unmount
        return () => {
            clearInterval(errorMessageInterval)
        }

    }, [])

    
    useEffect(() => {
        if(orgObj.selectedOrganization != null && loggedIn == true){
            getOrgPaymentMethods()
        }

    }, [loggedIn, orgObj.selectedOrganization])

    useEffect(() => { 
        if(lotDisplayName != ""){
            setLotDisplayNameTouched(true)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_lotDisplayName: lotDisplayName
            }
        }        
    }, [lotDisplayName])    
    useEffect(() => { 
        if(lotUseCaseSelect != ""){
            
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_lotUseCaseSelect: lotUseCaseSelect
            }
        }
    }, [lotUseCaseSelect])    
    useEffect(() => { 
        if(lotUseCase != ""){
            setLotUseCaseTouched(true)
            sharedStateObj.sharedStateRef.current = {
                ...sharedStateObj.sharedStateRef.current,
                checkout_lotUseCase: lotUseCase
            }
        }
    }, [lotUseCase])
    useEffect(() => {
        sharedStateObj.sharedStateRef.current = {
            ...sharedStateObj.sharedStateRef.current,
            checkout_selectedPricingPlan: selectedPricingPlan
        }
    }, [selectedPricingPlan])
    useEffect(() => {
        sharedStateObj.sharedStateRef.current = {
            ...sharedStateObj.sharedStateRef.current,
            checkout_currentStep: currentStep
        }
    }, [currentStep])

    useEffect(() => {      
        if(devMode){
            calculateTifSquares()                     
        }   
    }, [polygonBounds])

    function navigateToZipCode(_zipCode){ 
        if(_zipCode != null && returnZipCodeLatLng(_zipCode) != null){
            const zipCodeObj = returnZipCodeLatLng(_zipCode)
            setLocation({lat: zipCodeObj.lat, lng: zipCodeObj.lng})
        }
    }

    const handleCenterChanged = (_map) => {

        if (_map) {
          currentLocationRef.current = {lat:_map.center.lat(), lng: _map.center.lng()}
        }
    };


    function getOrganizationBillingLots() {
        if(user != null && orgObj?.selectedOrganization != null){
            const query = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').where("metaData.active", "==", true).get();
            query.then((querySnapshot) => {
                var lots = {}
                querySnapshot.forEach((doc) => {
                    lots[doc.id] = doc.data();
                });

                setOrganizationLots(lots)


            })
            .catch((error) => {
                console.error("Error getting organization billing lots: ", error);
            })
        }
    }
    
    function changeStep(_step){

        //add step as an argument to the url using the navigate function
        //check if the user is on the /Welcome/CreateLot page
        if(mode == "welcome"){
            //get the current url
            const currentUrl = routeLocation.pathname
            //check if the url has a query string
            if(currentUrl.includes("?")){
                //if it does, remove the query string
                const newUrl = currentUrl.split("?")[0]
                //navigate to the new url with the step as a query string
                console.log(`${newUrl}?step=${_step}`)
                //navigate(`${newUrl}?step=${_step}`)
            }else{                
                //if it doesn't, navigate to the new url with the step as a query string
                navigate(`${currentUrl}?step=${_step}`)
            }
        }

        setCurrentStep(_step)
    }

    const resetPolygonToEmpty = () => {

        setPolygonBounds([{lat: 0, lng: 0}])
        polygonAreaRef.current = 0;
        setPolygonArea(0)
    }

    const createPolygonAtCenter = () => {
        if(mapRef.current != null){

            //get the current bounds of the map using mapref
            const bounds = mapRef.current.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const verticalDistance = Math.abs(ne.lat() - sw.lat());
            const horizontalDistance = Math.abs(ne.lng() - sw.lng());

            // create a polygon at the center of the map where the user is looking, make it reach half way from the center to the edge of the map in each direction
            // it will be an array of 4 points, each object will have lat and lng
            setPolygonBounds([
                { lat: currentLocationRef.current.lat - verticalDistance / 4, lng: currentLocationRef.current.lng - horizontalDistance / 4 },
                { lat: currentLocationRef.current.lat + verticalDistance / 4, lng: currentLocationRef.current.lng - horizontalDistance / 4 },
                { lat: currentLocationRef.current.lat + verticalDistance / 4, lng: currentLocationRef.current.lng + horizontalDistance / 4 },
                { lat: currentLocationRef.current.lat - verticalDistance / 4, lng: currentLocationRef.current.lng + horizontalDistance / 4 }
            ]);
            

            setTimeout(() => {
                handlePolygonDrag()            
            }, 100);
        }
    }

    const calculateTifSquares = () => {

        try{
            //each square is .25 degrees by .25 degrees
            const squareSize = .25
            const bounds = mapRef.current.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            //round down and up to the nearest .25
            const north = Math.ceil(ne.lat() / squareSize) * squareSize;
            const south = Math.floor(sw.lat() / squareSize) * squareSize;
            const east = Math.ceil(ne.lng() / squareSize) * squareSize;
            const west = Math.floor(sw.lng() / squareSize) * squareSize;
        
            const tempSquares = []

            for (let i = south; i < north; i += squareSize) {
                for (let j = west; j < east; j += squareSize) {
                    tempSquares.push([
                        { lat: i, lng: j },
                        { lat: i + squareSize, lng: j },
                        { lat: i + squareSize, lng: j + squareSize },
                        { lat: i, lng: j + squareSize }
                    ])
                }
            }

            setTifSquares(tempSquares)

        }catch(err){
            
        }

    }

    
    
    function validateSelectLot(){
        //validate lot is larger than 50 km²
        if(convertSquareMetersToSquareKm(polygonAreaRef.current) < 50){
            return {
                error: true,
                message: "Lots must be at least 50 km²"
            }
        }else if(convertSquareMetersToSquareKm(polygonAreaRef.current) > 3000){
            return {
                error: true,
                message: "Lots must be less than 3000 km²"
            }
        }else{
            return {
                error: false,
                message: null
            }
        }
    }

    function validateLotDisplayName(){
        if(lotDisplayName.length < 3 || lotDisplayName.length > lotDisplayNameMaxLength){
            return {
                error: true,
                message: `Display name must be between 3 and ${lotDisplayNameMaxLength} characters`
            }
        }else{
            return {
                error: false,
                message: null
            }
        }
    }

    function validateLotDescription(){
        if(lotDescription.length > lotDescriptionMaxLength){
            return {
                error: true,
                message: `Description must be less than ${lotDescriptionMaxLength} characters`
            }
        }else{
            return {
                error: false,
                message: null
            }
        }
    }

    function validateLotUseCase(){
        if(lotUseCase.length > lotUseCaseMaxLength){
            return {
                error: true,
                message: `Use case must be less than ${lotUseCaseMaxLength} characters`
            }
        }else{
            return {
                error: false,
                message: null
            }
        }
    }

    function validateConfigure(){
        const displayNameValidation = validateLotDisplayName()
        const descriptionValidation = validateLotDescription()
        const useCaseValidation = validateLotUseCase()
        if(displayNameValidation.error || descriptionValidation.error || useCaseValidation.error){
            return {
                error: true,
                message: displayNameValidation.message ?? descriptionValidation.message ?? useCaseValidation.message
            }        
        }else{
            return {
                error: false,
                message: null
            }
        }
    }

    function validateChooseOption(){
        if(!(selectedPricingPlan in pricingSchedule)){
            return {
                error: true,
                message: "Please select a valid pricing plan"
            }
        }else if(lotBillingData?.stripeSubscription?.[selectedPricingPlan]?.status == 'active'){
            return {
                error: true,
                message: "Please select a pricing plan"
            }
        }else{
            return {
                error: false,
                message: null
            }
        }    
    }

    function validatePaymentMethod(){
        if(selectedPaymentMethod.length < 1 || selectedPaymentMethod == "" || selectedPaymentMethod == null){
            return {
                error: true,
                message: "Please select a payment method"
            }
        }else if(!paymentMethods.map((paymentMethod) => paymentMethod.id).includes(selectedPaymentMethod)){
            return {
                error: true,
                message: "Please select a valid payment method"
            }
        }else{
            return {
                error: false,
                message: null
            }
        }    
    }
    
    function validateStepDisabled(_step){

        const selectLotValidation = validateSelectLot()
        const configureValidation = validateConfigure()
        const chooseOptionValidation = validateChooseOption()
        const paymentMethodValidation = validatePaymentMethod()


        switch(_step){
            case "select":
                if(selectLotValidation.error){
                    errorMessageRef.current = selectLotValidation.message
                    return true
                }else{
                    errorMessageRef.current = null
                    return false
                }                
            case "configure":
                if(selectLotValidation.error || configureValidation.error){
                    errorMessageRef.current = (selectLotValidation.message ?? configureValidation.message)
                    return true
                }else{
                    errorMessageRef.current = null
                    return false
                }                
            case "account":
                if(selectLotValidation.error || configureValidation.error || loggedIn != true){
                    errorMessageRef.current = (selectLotValidation.message ?? configureValidation.message)
                    return true
                }else{
                    errorMessageRef.current = null
                    return false
                }
            case "choose":
                if(selectLotValidation.error || configureValidation.error || loggedIn != true || chooseOptionValidation.error){
                    errorMessageRef.current = (selectLotValidation.message ?? configureValidation.message ?? chooseOptionValidation.message)
                    return true
                }else{
                    errorMessageRef.current = null
                    return false
                }
            case "checkout":
                if(selectLotValidation.error || configureValidation.error || loggedIn != true || chooseOptionValidation.error || paymentMethodValidation.error){
                    errorMessageRef.current = (selectLotValidation.message ?? configureValidation.message ?? chooseOptionValidation.message ?? paymentMethodValidation.message)
                    return true
                }else{
                    errorMessageRef.current = null
                    return false
                }                
            default:
                return true
        }
    }

    function optionSummaryAlt(_option){

        const summaryFunctions = {
            "monthlyBasemaps": () => {
                //get the previous month and the cooresponding year
                const date = new Date()
                const month = date.getMonth()
                const year = date.getFullYear()
                const lastMonth = month == 0 ? 11 : month - 1
                const lastMonthString = new Date(year, lastMonth).toLocaleString('default', { month: 'long' })
                const lastYear = month == 0 ? year - 1 : year
                //get the last 10 instances of this month down to 2019
                const lastInstances = []
                for(let i = 0; i < 10; i++){
                    if(lastYear - i >= 2019){
                        lastInstances.push(`${lastYear - i}`)
                    }
                }
                return <><strong style={{fontWeight:'bold'}}>This month:</strong><span style={{fontWeight:'400'}}>{` Each ${lastMonthString} basemap from ${lastInstances[0]} to ${lastInstances[lastInstances.length - 1]}`}</span></>
            },
            "annualBasemaps": () => {
                //get the previous month and the cooresponding year
                const date = new Date()
                const month = date.getMonth()
                const year = date.getFullYear()
                const lastMonth = month == 0 ? 11 : month - 1
                const lastMonthString = new Date(year, lastMonth).toLocaleString('default', { month: 'long' })
                const lastYear = month == 0 ? year - 1 : year
                //get the last 10 instances of this month down to 2019
                const lastInstances = []
                for(let i = 0; i < 10; i++){
                    if(lastYear - i >= 2019){
                        lastInstances.push(`${lastYear - i}`)
                    }
                }
                return <><strong style={{fontWeight:'bold'}}>This month:</strong><span style={{fontWeight:'400'}}>{` All basemaps from ${lastInstances[0]} to ${lastInstances[lastInstances.length - 1]}`}</span></>
            }
        }

        if(_option in summaryFunctions){
            return summaryFunctions[_option]()
        }else{
            return ""
        }

    }

    function getOrgPaymentMethods() {

        setGetPaymentMethodsLoading(true)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
        }

        fetchFromAPI('stripe', "organization/wallet/listCards", {method: 'POST', body: fetchBody})
        .then((data) => {
            if(data?.length > 0){
                setPaymentMethods(data)

                setSelectedPaymentMethod(data.sort((a, b) => {
                    //sort by isDefaultPaymentMethod to be first
                    if(a?.isDefaultPaymentMethod){
                        return -1
                    }else if(b?.isDefaultPaymentMethod){
                        return 1
                    }else{
                        return 0
                    }                                                
                })[0].id)

            }else{
                setPaymentMethods([])
            }
        })
        .catch((error) => {
            console.error("Error getting payment methods: ", error);
            const message = error.message || "There was an error getting the payment methods"
            setPaymentMethods([])
            createAlert("error", message)
        })
        .finally(() => {
            setGetPaymentMethodsLoading(false)
        })
    }

    function getActiveMarks(){
        //get value of "account"
        const accountValue = configObj['marks']?.['account']?.value

        return Object.entries(configObj['marks'])
            .sort((a, b) => a[1].value - b[1].value)
            .filter(([key, value]) => {
                if(value.value == accountValue && loggedIn == true){
                    return false
                }else{
                    return true
                }
            })
            .map(([key, value]) => {

                if(loggedIn == true){
                    if(value.value > accountValue){
                        return {
                            value: value.value - 1, 
                            label: value.label,
                            key: key,
                        }
                    }else{
                        return {
                            value: value.value, 
                            label: value.label,
                            key: key,
                        }
                    }
                }else{
                    return {
                        value: value.value, 
                        label: value.label,
                        key: key,
                    }                                  
                }                
            })
    }

    function clearSharedState(){
        //clear shared state
        sharedStateObj.sharedStateRef.current.checkout_polygonBounds = null
        sharedStateObj.sharedStateRef.current.checkout_selectedPricingPlan = null
        sharedStateObj.sharedStateRef.current.checkout_lotDisplayName = null
        sharedStateObj.sharedStateRef.current.checkout_lotUseCaseSelect = null
        sharedStateObj.sharedStateRef.current.checkout_lotUseCase = null
        sharedStateObj.sharedStateRef.current.checkout_currentStep = null
    }

    function submitCheckout() {
        setCreateLoading(true)
        const dataObj = {
            "organizationId": orgObj?.selectedOrganization,
            "pricingScheduleId": selectedPricingPlan,
            "paymentMethodId": selectedPaymentMethod,
            "polygon": polygonBounds,            
            "displayName": lotDisplayName,  
            "lotUseCaseSelect": lotUseCaseSelect,
            "lotUseCase": lotUseCase                  
        }

        fetchFromAPI('stripe', "organization/subscription/create", {method: 'POST', body: dataObj})
        .then(async (data) => {
            
            console.log("DATA: ", data)

            const initialPaymentIntent = data?.initialPaymentIntent
            
            if(initialPaymentIntent.status == "succeeded"){
                createAlert("success", "Lot created successfully")
                if(data.lotDocumentId){

                    //clear shared state
                    clearSharedState()

                    navigate(`/Organization/Lots/${data.lotDocumentId}?source=checkout`)
               }
            }else if(initialPaymentIntent.status == "requires_confirmation" || initialPaymentIntent.status == "requires_action"){
                
                const { error } = await stripe.confirmCardPayment(initialPaymentIntent.client_secret);

                if(error){
                    console.error("Error confirming payment: ", error)
                    createAlert("error", "There was an error billing your payment method, please update your lot's billing details.")
                    if(data.lotDocumentId){
                        clearSharedState()
                        navigate(`/Organization/Lots/${data.lotDocumentId}/Subscription?source=checkoutError`)
                    }
                }else{
                    createAlert("success", "Lot created successfully")                    
                    if(data.lotDocumentId){
                        clearSharedState()
                        navigate(`/Organization/Lots/${data.lotDocumentId}?source=checkout`)
                    }
                }

                

                

            }else{
                createAlert("error", "There was an error creating the lot")
                setCreateLoading(false)
            }
            
        })
        .catch((error) => {
            console.error("Error creating lot: ", error);
            const message = error.message || "There was an error creating the lot"
            createAlert("error", message)
            setCreateLoading(false)
        })

    }
    
    if(!isLoaded) {
        return <div></div>
    }

    var nextStep = null
    var previousStep = null

    try{
        nextStep = getActiveMarks().sort((a, b) => a.value - b.value).find((mark) => mark.value > getActiveMarks()?.find((mark) => mark.key == currentStep).value)?.key
        previousStep = getActiveMarks().sort((a, b) => b.value - a.value).find((mark) => mark.value < getActiveMarks()?.find((mark) => mark.key == currentStep).value)?.key
    }catch(err){
        console.error(err)
    }
    

    const polygonSet = JSON.stringify(polygonBounds) != JSON.stringify([{lat: 0, lng: 0}])

    return (
        <div className="checkoutContent">
            <div className='checkoutContentScroller'>
                <div className='checkoutContentHolder'>
                    <Paper elevation={2} className='checkoutContentHeader'>
                        <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>                            
                            {configObj['headerIcon']}
                            {configObj['header']}
                            <div style={{flex: 1,}}></div>
                            <h3><span >Total Area:</span><br/> <span >{`${convertSquareMetersToSquareKm(polygonArea)} km²`}</span></h3>
                        </div>
                        <div className='checkoutInfoArea'>
                             
                        </div>
                    </Paper>
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 'select' ? 'flex':'none', flex: 100, }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2 className='hidden-mobile'>Select Lot</h2>                            
                            <Button
                                variant="text"
                                disabled
                                color="primary"
                                startIcon={errorMessage ? <Error />:null} 
                                style={{color: theme.palette.primary.main}}>
                                {errorMessage ?? ""}
                            </Button>
                        </div>                        
                        <GoogleMap    
                            zoom={11} 
                            center={location} 
                            mapContainerClassName="checkoutMapContainer"
                            options={mapOptions}
                            onLoad={handleOnLoad}
                            mapTypeId={mapTypeId}
                            onCenterChanged={() => { handleCenterChanged(mapRef.current) }}>
                            <Polygon
                                editable={true}
                                draggable={true}
                                paths={polygonBounds}                
                                onLoad={handlePolygonLoad}
                                onMouseUp={handlePolygonDrag}
                                options={{
                                    ...rectangleOptions,
                                    fillColor: "#f9a825",
                                    strokeColor: "#f9a825",
                                    strokeWeight: 3,
                                }}/>
                            {
                                Object.keys(organizationLots ?? {}).map((key) => {
                                    const lot = organizationLots[key]
                                    return (
                                        <Polygon
                                            paths={lot.polygon}
                                            editable={false}
                                            options={{
                                                ...rectangleOptions,
                                                fillColor: theme.palette.secondary.main,
                                                fillOpacity: 0.3,
                                                strokeColor: theme.palette.secondary.main,
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}/>
                                    )
                                })
                            }
                            {
                                tifSquares.map((square) => {
                                    return (
                                        <Polygon
                                            paths={square}
                                            options={{
                                                ...rectangleOptions,
                                                fillColor: "#000000",
                                                fillOpacity: 0,
                                                strokeColor: "#000000",
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}/>
                                    )
                                })
                            }
                            </GoogleMap>           
                        <div className="checkoutMapButtonHolder">
                            <IconButton
                                onClick={() => { 
                                    navigateToZipCode(zipCodeTextInput)
                                 }}
                                disabled={returnZipCodeLatLng(zipCodeTextInput) == null}
                                className="hidden-mobile"
                                style={{
                                    marginRight: '10px', 
                                    backgroundColor: returnZipCodeLatLng(zipCodeTextInput) == null ? theme.palette.gray.main:theme.palette.secondary.main, 
                                    color: returnZipCodeLatLng(zipCodeTextInput) == null ? theme.palette.gray.contrastText:theme.palette.secondary.contrastText,
                                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
                                }}>
                                <ControlCamera />
                            </IconButton>
                            <TextField
                                label="Zip / Postal Code"
                                variant="outlined"
                                value={zipCodeTextInput}
                                onChange={(e) => { setZipCodeTextInput(e.target.value)}}
                                className="hidden-mobile"
                                style={{marginRight: '10px', }}
                                size='small'/>
                            

                            <Button
                                variant="contained"
                                color={!polygonSet?"secondary":"primary"}
                                onClick={() => { !polygonSet ? createPolygonAtCenter():resetPolygonToEmpty() }}
                                startIcon={!polygonSet ? <AddCircle />:<RestartAlt />}
                                style={{flex: 1,}}>
                                {
                                    !polygonSet ? "Select Area" : "Restart"
                                }
                            </Button>

                            

                        </div>         
                    </Paper>     
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 'configure' ? 'flex':'none' }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Configure</h2>
                            <Button
                                variant="text"
                                disabled
                                color="primary"
                                startIcon={errorMessage ? <Error />:null} 
                                style={{color: theme.palette.primary.main}}>
                                {errorMessage ?? ""}
                            </Button>
                        </div>
                        <TextField  
                            label="Display Name" 
                            helperText={`Required* (${lotDisplayName.length} of ${lotDisplayNameMaxLength} characters)`}                            
                            variant="outlined" 
                            value={lotDisplayName}
                            maxLength={lotDisplayNameMaxLength}
                            onChange={(e) => setLotDisplayName(e.target.value)}
                            error={validateLotDisplayName().error && lotDisplayNameTouched}
                            style={{
                                marginBottom: '15px',
                            }}/>           
                        {/* <TextField
                            label="Description"
                            helperText="Optional: A short description of the lot"
                            variant="outlined"
                            value={lotDescription}
                            maxLength={lotDescriptionMaxLength}
                            onChange={(e) => { setLotDescription(e.target.value)}}
                            style={{
                                marginBottom: '10px',
                            }}/> */}
                        <FormControl style={{flex: 1, marginBottom: '10px'}}>
                            <InputLabel 
                                id="lotUseCaseSelect"
                                color="primary"
                                
                                size="small">Use Case</InputLabel>
                            <Select
                                labelId="lotUseCaseSelect"
                                id="lotUseCaseSelect"
                                value={lotUseCaseSelect}
                                onChange={(e) => {
                                    setLotUseCaseSelect(e.target.value)
                                }}
                                label="Use Case">
                                {
                                    lotUseCases.map((useCase) => {
                                        

                                        return <MenuItem key={useCase.key} value={useCase?.value}>{useCase.display}</MenuItem>
                                    })
                                }                  
                            </Select>
                            <FormHelperText>Optional: What industry are you intending on using this imagery for?</FormHelperText>
                        </FormControl>

                        {
                            lotUseCaseSelect == 'Other' ? (
                                <TextField
                                    label="Description"
                                    helperText={`Description (${lotUseCase.length} of ${lotUseCaseMaxLength} characters)`}
                                    placeholder="Other"
                                    value={lotUseCase}
                                    maxLength={lotUseCaseMaxLength}
                                    maxRows={3}
                                    multiline={true}
                                    error={validateLotUseCase().error && lotUseCaseTouched}
                                    onChange={(e) => setLotUseCase(e.target.value)}
                                    style={{
                                        marginTop: '10px',
                                    }}/>):null
                        }
                        


                    </Paper>       
                    <Paper elevation={2} className='checkoutContentPaper' style={{display: currentStep == 'account' ? 'flex':'none', }}>
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Create an Account</h2>                                                                
                        </div>
                        <p>Manage your lots by signing in.</p>
                        <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert} loadPage={"welcomeCreateAccount"}/>
                    </Paper>        
                    <Paper elevation={2} className='checkoutContentPaper checkoutContentPaperChoose' style={{display: currentStep == 'choose' ? 'flex':'none' }}>                        
                        <div className='checkoutContentPaperHeaderArea'>
                            <h2>Choose</h2>
                            <Button
                                variant="text"
                                disabled
                                color="primary"
                                startIcon={errorMessage ? <Error />:null} 
                                style={{color: theme.palette.primary.main}}>
                                {errorMessage ?? ""}
                            </Button>
                        </div>
                        <div className="checkoutOptionsArea">
                            {
                                Object.keys(pricingSchedule).filter(e => true).sort((a, b) => pricingSchedule[a].index - pricingSchedule[b].index).map((key, index) => {
                                    const element = pricingSchedule[key]

                                    const costPer = convertSquareMetersToSquareKm(polygonArea) * element?.price/100
                                    const existingSubscription = lotBillingData?.stripeSubscription?.[key]?.status == 'active'
                                    return (
                                        <div 
                                            className='checkoutOption'
                                            onClick={() => {
                                                setSelectedPricingPlan(key)                                                
                                            }}
                                            style={{/*backgroundColor: selectedPricingPlan == key ? '#f9a825':""*/}}>
                                            <h1>{element?.name}</h1>
                                            <h3>{element?.tagDescription}</h3>
                                            <h2>{formatAsDollar(costPer)}<span>/{element?.billingSchedule}</span></h2>
                                            <h4>{element?.savings}</h4>
                                            <Button 
                                                variant='contained'
                                                color={selectedPricingPlan == key ? 'secondary':'primary'}
                                                style={{marginBottom: '10px', }}
                                                startIcon={existingSubscription ? null:selectedPricingPlan == key ? <Check />:null}
                                                disabled={existingSubscription}                                                
                                                onClick={() => {
                                                    setSelectedPricingPlan(key)
                                                }}>
                                                {existingSubscription ? "Already Subscribed" : selectedPricingPlan == key ? "Selected Plan":"Choose Plan"} 
                                            </Button>
                                            {/* <p>{`${formatAsDollar(element?.price/100)}/${element?.units} each ${element?.billingSchedule}`}</p>
                                            <p>{optionSummaryAlt(key)}</p>
                                            <h3>{`${formatAsDollar(costPer)}/${element?.billingSchedule}`}</h3>
                                            <p>Lot Size: {convertSquareMetersToSquareKm(polygonArea)} km²</p>
                                            <p><strong>{element?.savings ?? ""}</strong></p> */}
                                        </div>
                                    )

                                })
                            }                                                        
                        </div>
                    </Paper>
                    
                    

                    <Paper elevation={2} className='checkoutContentPaper checkoutContentPaperChoose' style={{display: currentStep == 'checkout' ? 'flex':'none', }}>

                        {
                            loggedIn ? (
                                orgObj?.selectedOrganization != null ? (
                                    <>
                                        <div className='checkoutContentPaperHeaderArea'>
                                            <h2>Checkout</h2>                            
                                            <Button
                                                variant="text"
                                                disabled
                                                color="primary"
                                                startIcon={errorMessage ? <Error />:null} 
                                                style={{color: theme.palette.primary.main}}>
                                                {errorMessage ?? ""}
                                            </Button>
                                        </div>        
                                        <div className='checkoutTotal'><strong>Total:</strong> {formatAsDollar(convertSquareMetersToSquareKm(polygonArea) * pricingSchedule[selectedPricingPlan]?.price/100)} billed each {pricingSchedule[selectedPricingPlan]?.billingSchedule}</div>                                
                                        <div className="checkoutPaymentOptionsArea" style={{overflowX: createLoading ? 'hidden':'auto'}}>
                                            {
                                                createLoading ?
                                                    <Skeleton variant="rectangular" width={2000} height={297} />
                                                    :
                                                    <div 
                                                        className='checkoutPaymentOptionsAreaHolder'
                                                        style={{width: `${(paymentMethods.length + 1)*430}px`}}>
                                                        {
                                                            paymentMethods.sort((a, b) => {
                                                                //sort by isDefaultPaymentMethod to be first
                                                                if(a?.isDefaultPaymentMethod){
                                                                    return -1
                                                                }else if(b?.isDefaultPaymentMethod){
                                                                    return 1
                                                                }else{
                                                                    return 0
                                                                }                                                
                                                            }).map((paymentMethod) => {
                                                                return (
                                                                    <PaymentMethod 
                                                                        key={paymentMethod.id}
                                                                        selected={selectedPaymentMethod == paymentMethod.id} 
                                                                        loading={false} 
                                                                        create={false} 
                                                                        organizationId={orgObj?.selectedOrganization} 
                                                                        paymentMethodObj={paymentMethod} 
                                                                        createAlert={createAlert} 
                                                                        refreshWallet={getOrgPaymentMethods}
                                                                        manageOnClick={() => setSelectedPaymentMethod(paymentMethod.id)}/>
                                                                )
                                                            })
                                                        }
                                                        <PaymentMethod 
                                                            key={"create"}
                                                            selected={false}
                                                            loading={getPaymentMethodsLoading} 
                                                            create={true} 
                                                            organizationId={orgObj?.selectedOrganization} 
                                                            paymentMethodObj={{
                                                                userDefaultPaymentMethodSet: paymentMethods?.length > 0 ? paymentMethods[0]?.userDefaultPaymentMethodSet == true : false
                                                            }}
                                                            createAlert={createAlert} 
                                                            refreshWallet={getOrgPaymentMethods}/>
                                                        <div className='shown-mobile' style={{height: '50px', width: '100%'}}></div>
                                                    </div>
                                            }                            
                                        </div>
                                        <p>{['monthlyBasemaps'].includes(selectedPricingPlan) ? "This lot will be billed today for the current month's data, then on the 1st of each month moving forward.":""}</p>
                                    </>):(<>
                                        <div className='checkoutContentPaperHeaderArea'>
                                            <h2>Building Organization Profile</h2>
                                            <p>Hang tight, may take a minute</p>
                                        </div>
                                        <LinearProgress color='secondary'/>                 
                                    </>)
                            ):(<>
                                
                            </>)
                        }

                        
                    </Paper>
                    
                    <div style={{flex: 1}}></div>
                    <div className='checkoutContentNavigationHolder'>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => { 
                                changeStep(previousStep)
                            }}
                            disabled={previousStep == undefined || createLoading}
                            startIcon={<NavigateBefore />}
                            style={{width: '120px', opacity: previousStep == undefined ? 0:1}}>
                            Back
                        </Button>
                        <div style={{width: '30px'}}></div>
                        <Box sx={{ flex: 1 }} className="checkoutContentNavigationHolderSliderElement">
                            <Slider
                                aria-label=""
                                defaultValue={getActiveMarks().find((mark) => mark.key == currentStep)?.value}                                
                                value={getActiveMarks().find((mark) => mark.key == currentStep)?.value}
                                // onChange={(event, newValue) => { changeStep(newValue) } }
                                step={null}
                                min={Math.min(...getActiveMarks().map(e => e.value)) - .25}
                                max={Math.max(...getActiveMarks().map(e => e.value)) + .25}
                                color='secondary'
                                valueLabelDisplay={'off'}
                                disabled
                                sx={{                                    
                                    '& .MuiSlider-thumb.Mui-disabled': {
                                      bgcolor: theme.palette.secondary.main,
                                    },
                                  }}                          
                                marks={getActiveMarks()}
                            />
                        </Box>
                        <div style={{width: '30px'}}></div>
                        {
                            currentStep == "checkout" ? 
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { submitCheckout() }}
                                disabled={validateStepDisabled(currentStep) || createLoading}
                                endIcon={createLoading ? <CircularProgress color="inherit" size={14}/>: <AddCircle />}
                                style={{width: '120px'}}>
                                Create
                            </Button>
                            :
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => { 
                                    changeStep(nextStep)
                                 }}
                                disabled={validateStepDisabled(currentStep)}
                                endIcon={<NavigateNext />}
                                style={{width: '120px'}}>
                                Next
                            </Button>
                        }
                    </div>
                </div>
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Checkout