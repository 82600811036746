import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import { getStorage, ref, listAll, getDownloadURL, list } from "firebase/storage";
import './ExportImageryManager.css'

import Footer from '../Footer/Footer'
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import { Badge, Button, Chip, Paper, Skeleton, Tabs, Tab, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton,  } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { invoiceStatuses } from '../StripeStatuses/StripeStatuses';

import { BurstMode, Dashboard, Filter1Outlined, Filter2Outlined, Filter3Outlined, Gif, Public } from '@mui/icons-material';
import { create } from '@mui/material/styles/createTransitions';

function ExportImageryManager(props) {

    const firestore = firebase.firestore();
    const storage = getStorage();

    const actionTimeout = 5000

    const adminMode = props.adminMode
    const orgObj = props.organizationObj
    const orgId = orgObj?.selectedOrganization /*props.user.uid*/;
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    
    const [imageryRequests, setImageryRequests] = useState({})
    const lastVisibleRequestDocRef = useRef(null)

    const imageryRequestPaginationSize = 10
    const processingDateOptions = { 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }
      
    useEffect(() => {

        getImageryRequests()


    }, [])

    function getImageryRequests() {

        try{
            ///Operations/ImageryOperations/BkfVOr73Q8okvodh4FCJ/ExportImagery/Requests 
            firestore
            .collection('Operations')
            .doc('ImageryOperations')
            .collection(orgId)
            .doc('ExportImagery')
            .onSnapshot((doc) => {

                if(doc.exists){

                    const data = doc.data()
                    if(data != null){
                        console.log(data)
                        setImageryRequests(data)
                    }
                }
            }, (error) => {
                console.log(error)
                createAlert('error', error.message)
            })          
            //listen for changes to this document


        }catch(e ){
            console.log(e)
            createAlert('error', e.message)
        }
    }    

    function downloadFile(_url, _filename) {
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = _url;
        a.target = "_blank"; // Open in a new tab
        a.download = _filename; // You can specify a filename here if desired
        document.body.appendChild(a);
        a.click(); // Trigger the download
        document.body.removeChild(a); // Clean up
      }
      

    function downloadImage(_organizationId, _requestId, _type, _subType){

        const requestPath = `/OrganizationData/${_organizationId}/ExportImagery/${_requestId}`
        var url = ""
        var filename = ""
        if(_type == 'gif'){
            filename = `${_requestId}.gif`
            const fileRef = ref(storage, `${requestPath}/GIFs/${filename}`)
            url = getDownloadURL(fileRef)

                  

        }else if(_type == 'png'){            
            switch(_subType){
                case 'first':
                    filename = `${_requestId}-firstDate.png`
                    break
                case 'middle':
                    filename = `${_requestId}-middleDate.png`
                    break
                case 'last':
                    filename = `${_requestId}-lastDate.png`
                    break
                default:
                    return
            }
            const fileRef = ref(storage, `${requestPath}/PNGs/${filename}`)
            url = getDownloadURL(fileRef)

        }else{
            createAlert('error', 'Invalid image type')
        }

        url.then((url) => {
            downloadFile(url, filename)
        }).catch((error) => {
            createAlert('error', error.message)
        })      
    }

    return (
        <div className="exportImageryManagerContent">
            <div className='exportImageryManagerContentScroller'>
                <Paper elevation={2} className='exportImageryManagerContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <BurstMode style={{marginRight: '10px'}}/>
                        Imagery Manager
                    </div>
                    
                    <div className="sectionContent">
                    <TableContainer component={Paper} elevation={3} style={{  width: 'calc(100% - 0px)', maxHeight: 'calc(100vh - 500px)', display: true ? 'block':'none'}} >
                        <Table aria-label="" stickyHeader >
                            <TableHead >
                                <TableRow style={{minHeight: '60px !important'}}>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Range</TableCell>   
                                    <TableCell>Frames</TableCell>   
                                    <TableCell>Square ID</TableCell>                                            
                                    <TableCell>Status</TableCell>                                                

                                    <TableCell>Updated</TableCell>      
                                    <TableCell>Action</TableCell>                                            
                                </TableRow>
                                </TableHead>
                            <TableBody>
                            {
                            Object.keys(imageryRequests)
                            .sort((a, b) => {
                                return imageryRequests[b].updated.seconds - imageryRequests[a].updated.seconds
                            })
                            .map((key) => {
                                const task = imageryRequests[key]
                                console.log(key, task)
                                return (
                                    <TableRow
                                        key={`analyticRequest-${key}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}>
                                        <TableCell >
                                            {
                                                task.name
                                            }
                                        </TableCell>
                                        <TableCell>
                                                <div>{(new Date(task.first.substring( 11, 21 ))).toLocaleDateString('en-US', { year: 'numeric', month: 'short'}) }</div><div>{(new Date(task.last.substring( 11, 21 ))).toLocaleDateString('en-US', { year: 'numeric', month: 'short'}) }</div>  
                                        </TableCell>
                                        <TableCell>
                                            {
                                                task.frames
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                task.squareId
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                <Chip label={task.status?.toUpperCase() ?? ""} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {(new Date(task?.updated?.seconds * 1000)).toLocaleDateString("en-US", processingDateOptions)}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                task?.complete == true ? <>                                                    
                                                        <IconButton 
                                                            onClick={() => downloadImage(orgId, key, 'gif', null)}>
                                                            <Gif />                                                
                                                        </IconButton>
                                                        <IconButton 
                                                            onClick={() => downloadImage(orgId, key, 'png', 'first')}>
                                                            <Filter1Outlined />                                                
                                                        </IconButton>                                                    
                                                        <IconButton 
                                                            onClick={() => downloadImage(orgId, key, 'png', 'middle')}>
                                                            <Filter2Outlined />                                                
                                                        </IconButton>
                                                        <IconButton 
                                                            onClick={() => downloadImage(orgId, key, 'png', 'last')}>
                                                            <Filter3Outlined />                                                
                                                        </IconButton>                                                    
                                                </>:null
                                            }
                                                
                                            
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    </div>
                </Paper>
                
            </div>            
            <Footer></Footer>
            
        </div>
    )

}


export default ExportImageryManager