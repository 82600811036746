import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './ViewLot.css'
import { GoogleMap, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';
import googleMapsStyle from '../../assets/googleMapsStyle.json'
import Footer from '../Footer/Footer'
import { Button, IconButton, Badge, Paper, Tabs, Tab, CircularProgress, Divider, TableContainer, 
    Table, TableHead, TableRow, TableCell, TableBody, Chip, Skeleton, Dialog, DialogTitle, 
    Alert, DialogContent, DialogActions, DialogContentText } from '@mui/material';

import { AddCircleOutline, Autorenew, CreditCard, Cancel, CropDin, CropFree, DonutLarge, 
    Download, Event, Layers, RestartAlt, ViewInAr, AccountTree, Check, TravelExplore, PublishedWithChangesOutlined, ChangeCircle,
    FontDownloadOffSharp,  } from '@mui/icons-material';

import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { dateIdToUTCDateObject } from '../../functions/dateIdToUTCDateObject'
import { convertSquareMetersToSquareKm } from '../../functions/convertSquareMetersToSquareKm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { calculateSqurareKmFromSquareMeters } from '../../functions/calculateSquareKmFromSquareMeters';
import { subscriptionStatuses, invoiceStatuses } from '../StripeStatuses/StripeStatuses';
import LinearProgressWithLabel from '../LinearProgressWithLabel/LinearProgressWithLabel';
import { CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import DataDisplay from '../DataDisplay/DataDisplay';

import CountdownTimerButton from '../CountdownTimerButton/CountdownTimerButton'

import { HighlightOff } from '@mui/icons-material';

import { formatAsDollar } from '../../functions/formatAsDollar';
import { create } from '@mui/material/styles/createTransitions';
import HeaderTitleEditable from '../HeaderTitleEditable/HeaderTitleEditable';

function ViewLot(props) {

    const firestore = firebase.firestore();
    const orgObj = props.organizationObj
    const sharedStateObj = props.sharedStateObj

    const adminMode = props.adminMode
    const devMode = props.devMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const { lotId, tab } = useParams()
    const stripe = useStripe();


    const mapRef = React.useRef(null);
    const [mapZoom, setMapZoom] = useState(15) //default zoom

    const [lotDisplayName, setLotDisplayName] = useState("")
    const [lotCreatedDate, setLotCreatedDate] = useState(0)
    const [lotTotalArea, setLotTotalArea] = useState(0)
    const [lotExactArea, setLotExactArea] = useState(0)

    const [editLotTitleLoading, setEditLotTitleLoading] = useState(false)

    const [polygonBounds, setPolygonBounds] = React.useState([{lat: 0, lng: 0}]);
    const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
    
    const fbBillingObjRef = useRef(null)    
    const [fbBillingObj, setFbBillingObj] = useState(null)
    const [billingLoading, setBillingLoading] = useState(true)

    const stripeSubscriptionObjRef = useRef(null)
    const [stripeSubscriptionObj, setStripeSubscriptionObj] = useState(null)
    const [stripeBillingLoading, setStripeBillingLoading] = useState(true)
    
    const [stripePaymentMethods, setStripePaymentMethods] = useState(null)
    const [stripePaymentMethodsLoading, setStripePaymentMethodsLoading] = useState(true)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
    const [showSelectedPaymentMethodDialog, setShowSelectedPaymentMethodDialog] = useState(false)

    const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false)
    const [openReactivateSubscriptionDialog, setOpenReactivateSubscriptionDialog] = useState(false)

    const dataRequstsListenerInitialized = useRef(false)
    const dataRequestRef = useRef({})    

    const formattedDataRequestsRef = useRef({})
    const [formattedDataRequests, setFormattedDataRequests] = useState({})
    const selectedProcessingProductKeyRef = useRef(null)
    const [selectedProcessingProductKey, setSelectedProcessingProductKey] = useState(null)

    const [updateSubscriptionPriceKey, setUpdateSubscriptionPriceKey] = useState(null)
    const [updateSubscriptionPriceId, setUpdateSubscriptionPriceId] = useState(null)
    const [updateSubscriptionModalOpen, setUpdateSubscriptionModalOpen] = useState(false)
    const [updateSubscriptionTargetMilliseconds, setUpdateSubscriptionTargetMilliseconds] = useState(0)

    const pricingSchedule = props.generalAppConfig?.StripePricingSchedule ?? {}

    const navigate = useNavigate()


    const tabNames = ["View", "Subscription"]

    const [selectedTab, setSelectedTab] = useState(tabNames.indexOf(tab) != -1 ? tabNames.indexOf(tab) : 0)
    
    const [updateSubscriptionLoading, setUpdateSubscriptionLoading] = useState(false)

    const [selectedBillingProductKey, setSelectedBillingProductKey] = useState(null)


    const rectangleOptions = {
        fillColor: "#000000",
        fillOpacity: .4,
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    }

    const mapOptions = {
        styles: googleMapsStyle
    }

    const { isLoaded } = useLoadScript({    
        googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
     })

    const stripeBillingDateOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        timeZone: 'UTC', 
    }

    const processingDateOptions = { 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }

    const processingDateShortOptions = { 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric',
        timeZone: 'UTC',
    }



    const invoiceActions = {
        draft: () => {},
        deleted: () => {},
        open: (_invoiceId, _subscriptionId) => { return <Button variant='contained' size='small' color='stripeGreen' startIcon={<Autorenew />} onClick={() => {retrySubscriptionInvoice(_invoiceId, _subscriptionId)}}>Retry Payment</Button>},
        paid: () => {},
        uncollectible: (_invoiceId, _subscriptionId) => { return <Button variant='contained' size='small' color='stripeGreen' startIcon={<Autorenew />} onClick={() => {retrySubscriptionInvoice(_invoiceId, _subscriptionId)}}>Retry Payment</Button>},
        void: () => {},        
    }



    useEffect(() => {
        if(orgObj?.selectedOrganization != null){
            getFirebaseBillingInfo() 
            if(dataRequstsListenerInitialized.current == false){
                dataRequstsListenerInitialized.current = true
                initListenToLotDataRequests()
            }           
        }
    }, [])

    useEffect(() => {
        // if the user orgObj?.userIsOrgSuperAdmin then allow them to see tab 1
        if(selectedTab == 2){
            if(orgObj?.userIsOrgSuperAdmin){                
                if(stripePaymentMethods == null){
                    getOrgPaymentMethods()
                }

            }else{
                setSelectedTab(0)
            }
        }

        if(orgObj?.userIsOrgSuperAdmin){
            if(stripeSubscriptionObjRef.current == null){
                getStripeBillingInfo(false)
            }
        }

    }, [selectedTab, fbBillingObj])



    React.useEffect(() => {

        setTimeout(() => {
            if (mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
          
                polygonBounds.forEach(coord => {
                  bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
                });
          
                mapRef.current.panToBounds(bounds);
                mapRef.current.fitBounds(bounds);

                setMapZoom(mapRef.current.getZoom())
                //set map center
                setLocation(mapRef.current.getCenter())
              }            
        }, 5);
      }, [polygonBounds, selectedTab]);
    
    React.useEffect(() => {

        if (mapRef.current) {
            //set map zoom
            setMapZoom(mapRef.current.getZoom())
            //set map center
            setLocation(mapRef.current.getCenter())
        }

    }, [selectedTab])


    function initListenToLotDataRequests(){
        
        //listen to /Billing/{orgId}/Lots/{lotId}/DataRequests/
        var ref = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').doc(lotId).collection('DataRequests')
        ref.onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                
                if(change.type === "added"){
                    //add the data request to the dataRequestRef
                    dataRequestRef.current[change.doc.id] = change.doc.data()
                }
                if(change.type === "modified"){
                    //modify the data request in the dataRequestRef
                    dataRequestRef.current[change.doc.id] = change.doc.data()
                }
                if(change.type === "removed"){
                    //remove the data request from the dataRequestRef
                    delete dataRequestRef.current[change.doc.id]
                }

                
                
            })

            var tempDataRequests = {}

            Object.values(dataRequestRef.current).forEach((dataRequests) => {
                Object.values(dataRequests ?? {}).forEach((request) => {
                    if(tempDataRequests[request.productType] == null){
                        tempDataRequests[request.productType] = {}
                    }
                    tempDataRequests[request.productType][request.dateRangeId] = request
                    tempDataRequests[request.productType][request.dateRangeId].id = `${request.productType}-${request.dateRangeId}`
                    tempDataRequests[request.productType][request.dateRangeId].processingTime = request?.startProcessingDate?.seconds * 1000 < (new Date()).getTime() && request?.startProcessingDate?.seconds != null

                })
            })


            formattedDataRequestsRef.current = tempDataRequests
            setFormattedDataRequests(formattedDataRequestsRef.current)

            if(selectedProcessingProductKeyRef.current == null){
                changeProcessingProduct(0)
            }
        })

    }
    

    function getFirebaseBillingInfo(){

        
        setBillingLoading(true)
        var ref = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').doc(lotId);        
        ref.onSnapshot((doc) => {           
            setBillingLoading(false) 
            if(doc.exists){
                var billingObj = doc.data()

                fbBillingObjRef.current = billingObj
                setFbBillingObj(billingObj)

                //set all of the variables
                setLotDisplayName(billingObj.displayName)
                setLotCreatedDate(billingObj?.dateCreated?.seconds * 1000)
                setLotTotalArea(billingObj.totalArea)
                //round to two decimal places
                setLotExactArea(calculateSqurareKmFromSquareMeters(billingObj.exactArea))
                setPolygonBounds(billingObj.polygon)
                
                
            }else{ 
                
                //navigate to all lots
                //navigate('/AllLots')
                createAlert("error", "Lot not found")
            }
        })        
    }

    function getOrgPaymentMethods() {

        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
        }

        fetchFromAPI('stripe', "organization/wallet/listCards", {method: 'POST', body: fetchBody})
        .then((data) => {
            if(data?.length > 0){
                setStripePaymentMethods(data)

            }else{
                setStripePaymentMethods([])
            }
        })
        .catch((error) => {
            console.error("Error getting payment methods: ", error);
            const message = error.message || "There was an error getting the payment methods"
            setStripePaymentMethods([])
            createAlert("error", message)
        })
        .finally(() => {
            setStripePaymentMethodsLoading(false)
        })
    }

    function getStripeBillingInfo(showPaymentMethodsUi){

        setStripeBillingLoading(true)
        if(fbBillingObj == null){
            return
        }else if(fbBillingObj?.stripeSubscription == undefined){
            return
        }

        var promiseArrayIndex = Object.keys(fbBillingObj?.stripeSubscription)
        var promiseArray = []

        promiseArrayIndex.forEach((key) => {

            const element = fbBillingObj?.stripeSubscription[key]
            const dataObj = {
                organizationId: orgObj?.selectedOrganization,
                subscriptionId: element?.subscriptionId
            }

            //fetch the stripe billing info
            promiseArray.push(fetchFromAPI('stripe', 'organization/subscription/get', {method: 'POST', body: dataObj}))            
        })

        Promise.all(promiseArray)
        .then((promises) => {

            var subscriptionData = {}

            promises.forEach((data, index) => {

                const key = promiseArrayIndex[index]

                //if data has the keys invoices and subscription
                if(data.hasOwnProperty('subscription') && data.hasOwnProperty('invoices')){
                    subscriptionData[key] = data
                }

            })

            stripeSubscriptionObjRef.current = subscriptionData
            setStripeSubscriptionObj(subscriptionData)
            if(selectedBillingProductKey == null){
                changeBillingProduct(0)
            }
            setStripeBillingLoading(false)
            if(showPaymentMethodsUi){
                setStripePaymentMethodsLoading(false)
            }
        })        

    }

    function retrySubscriptionInvoice(_invoiceId, _subscriptionId){

        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: _subscriptionId,
            invoiceId: _invoiceId
        }

        setStripeBillingLoading(true)
        fetchFromAPI('stripe', "organization/subscription/retryInvoicePayment", {method: 'POST', body: fetchBody})
        .then(async (data) => {
            const paymentIntent = data
            
            if(paymentIntent.status == "success"){
                createAlert("success", "Invoice paid successfully")
                getStripeBillingInfo(false)
            }else if(paymentIntent.status == "requires_confirmation" || paymentIntent.status == "requires_action"){
                
                const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);

                if(error){
                    console.error("Error retrying invoice payment: ", error);
                    createAlert("error", error.message)
                    setStripeBillingLoading(false)
                }else{
                    createAlert("success", "Invoice paid successfully")
                    getStripeBillingInfo(false)
                }

            }else{
                console.error("Error retrying invoice payment: ", paymentIntent);
                createAlert("error", "There was an error retrying the invoice payment")
                setStripeBillingLoading(false)
            }
            
        })
        .catch((error) => {
            console.error("Error retrying invoice payment: ", error);
            const message = error.message || "There was an error retrying the invoice payment"
            createAlert("error", message)
            setStripeBillingLoading(false)
        })


    }
    
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    function downloadGeoJson(){

        const polygon = fbBillingObj?.polygon
        const polygonArray = []
        polygon.forEach(point => {
            polygonArray.push([point.lng, point.lat])
        })
        //make sure the first and last point are the same
        //check if the first and last point are the same
        if(polygonArray[0][0] != polygonArray[polygonArray.length - 1][0] || polygonArray[0][1] != polygonArray[polygonArray.length - 1][1]){
            polygonArray.push(polygonArray[0])
        }

        const geoJson = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [polygonArray]
                    }
                }
            ]
        }
        const blob = new Blob([JSON.stringify(geoJson)], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${lotDisplayName}.geojson`;
        document.body.appendChild(a);
        a.click();
        a.remove();

    }

    function changeBillingProduct(_newValue){

        //find key in stripeSubscriptionObj that matches the value
        const _newKey = Object.keys(stripeSubscriptionObjRef.current)[_newValue]

        setSelectedBillingProductKey(_newKey)    
    }


    function changeProcessingProduct(_newValue){

        //find key in stripeSubscriptionObj that matches the value
        const _newKey = Object.keys(formattedDataRequestsRef.current)[_newValue]

        selectedProcessingProductKeyRef.current = _newKey
        setSelectedProcessingProductKey(_newKey)    
    }


    function changeTabs(_newTab){
        setSelectedTab(_newTab)

    }

    function updateSubscriptionDefaultPaymentMethod(){

        setStripePaymentMethodsLoading(true)
        setStripeBillingLoading(true)
        setShowSelectedPaymentMethodDialog(false)
        setTimeout(() => {
            setSelectedPaymentMethod(null)                
        }, 100);
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId,
            paymentMethodId: selectedPaymentMethod.id
        }
        fetchFromAPI('stripe', "organization/subscription/updatePaymentMethod", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", "Payment method updated")
            
            
            getStripeBillingInfo(true)

            //close the dialog
            setShowSelectedPaymentMethodDialog(false)
            
        })
        .catch((error) => {
            console.error("Error updating payment method: ", error);
            const message = error.message || "There was an error updating the payment method"
            createAlert("error", message)
            getStripeBillingInfo(true)
            
        })

    }

    function cancelSubscription(){

        setStripeBillingLoading(true)
        setOpenCancelSubscriptionDialog(false)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: stripeSubscriptionObjRef.current?.[selectedBillingProductKey]?.subscription?.id
        }
        fetchFromAPI('stripe', "organization/subscription/cancel", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", "Subscription cancelled")
            getStripeBillingInfo(false)
        })
        .catch((error) => {
            console.error("Error cancelling subscription: ", error);
            const message = error.message || "There was an error cancelling the subscription"
            createAlert("error", message)
            setStripeBillingLoading(false)  
        })

    }

    function reactivateSubscription(){

        setStripeBillingLoading(true)
        setOpenReactivateSubscriptionDialog(false)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: stripeSubscriptionObjRef.current?.[selectedBillingProductKey]?.subscription?.id
        }
        fetchFromAPI('stripe', "organization/subscription/reactivate", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", `Subscription for ${ lotDisplayName } reactivated`)
            getStripeBillingInfo(false)
        })
        .catch((error) => {
            console.error("Error reactivating subscription: ", error);
            const message = error.message || "There was an error reactivating the subscription"
            createAlert("error", message)
            setStripeBillingLoading(false)  
        })
    }

    function updateSubscriptionPrice(){

        setUpdateSubscriptionLoading(true)
        setStripeBillingLoading(true)
        setOpenReactivateSubscriptionDialog(false)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: stripeSubscriptionObjRef.current?.[selectedBillingProductKey]?.subscription?.id,
            priceId: updateSubscriptionPriceId
        }
        fetchFromAPI('stripe', "organization/subscription/updatePrice", {method: 'POST', body: fetchBody})
        .then(async (data) => {
            
            const latestPaymentIntent = data?.latestPaymentIntent

            if(latestPaymentIntent.status == "succeeded"){
                setUpdateSubscriptionModalOpen(false)
                createAlert("success", "Subscription updated successfully")
                getStripeBillingInfo(false)
                //close the dialog


            }else if(latestPaymentIntent.status == "requires_confirmation" || latestPaymentIntent.status == "requires_action"){
                
                const { error } = await stripe.confirmCardPayment(latestPaymentIntent.client_secret);

                if(error){
                    console.error("Error confirming payment: ", error)
                    createAlert("error", "There was an error billing your payment method, please update your lot's billing details.")
                    getStripeBillingInfo(false)
                }else{
                    setUpdateSubscriptionModalOpen(false)
                }
                    createAlert("success", "Subscription updated successfully")                    
                    getStripeBillingInfo(false)
                    //close the dialog
                    

                

                

            }else{
                createAlert("error", "There was an error Updating the subscription")
                getStripeBillingInfo(false)
            }

            
        })
        .catch((error) => {
            console.error("Error reactivating subscription: ", error);
            const message = error.message || "There was an error updating the subscription"
            createAlert("error", message)
            setStripeBillingLoading(false)  
        })
        .finally(() => {
            setUpdateSubscriptionLoading(false)
        })

        
    }

    function updateLotTitle(_newTitle){
        return new Promise((resolve, reject) => {
            try{
                //make sure the new title is not the same as the old title and is between 3 and 50 characters
                if(_newTitle.length < 3 || _newTitle.length > 50){
                    createAlert("error", "The lot name must be between 3 and 50 characters")
                    reject()
                }else if(_newTitle == lotDisplayName){
                    createAlert("error", "The lot name must be different than the current name")
                    reject()
                }else{
                    setEditLotTitleLoading(true)
                    const fetchBody = {
                        organizationId: orgObj?.selectedOrganization,
                        subscriptionId: fbBillingObj?.subscriptionId,
                        displayName: _newTitle
                    }
                    fetchFromAPI('stripe', "organization/subscription/updateLotDisplayName", {method: 'POST', body: fetchBody}) 
                    .then((data) => {
                        createAlert("success", "Lot name updated")
                        setLotDisplayName(_newTitle)
                        setEditLotTitleLoading(false)
                        resolve()
                    })
                    .catch((error) => {
                        console.error("Error updating lot title: ", error);
                        const message = error.message || "There was an error updating the lot title"
                        createAlert("error", message)
                        setEditLotTitleLoading(false)
                        reject()
                    })
                }
            }catch(error){
                console.error("Error updating lot title: ", error);
                const message = error.message || "There was an error updating the lot title"
                createAlert("error", message)
                setEditLotTitleLoading(false)
                reject()
            }
        })
    }

    function updateInvoiceDetails(_invoiceId){

        console.log("update invoice details: ", _invoiceId)

        //send post to stripe to update the invoice details
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: stripeSubscriptionObjRef.current?.[selectedBillingProductKey]?.subscription?.id,
            invoiceId: _invoiceId
        }

        fetchFromAPI('stripe', "organization/subscription/refreshInvoiceDetails", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", "Invoice details updated")

            getStripeBillingInfo(false)
        })
        .catch((error) => {
            console.error("Error updating invoice details: ", error);
            const message = error.message || "There was an error updating the invoice details"
            createAlert("error", message)
            setStripeBillingLoading(false)
        })

    }

    //check if there are any subscriptions that are set to cancel at the end of the period
    const endingSubscriptions = Object.keys(stripeSubscriptionObj ?? {}).filter(key => stripeSubscriptionObj[key]?.subscription?.cancel_at_period_end == true)
    //check if all subscriptions have been canceled
    const allSubscriptionsCanceled = stripeSubscriptionObj == null ? false:Object.keys(stripeSubscriptionObj ?? {}).filter(key => stripeSubscriptionObj[key]?.subscription?.status != "canceled").length == 0


    var categoryProcessingItems = {}
    Object.keys(formattedDataRequests ?? {}).forEach((key) => {
        categoryProcessingItems[key] = Object.values(formattedDataRequests[key] ?? {}).filter((request) => request?.processingTime == true && !request.processingComplete).length
    })
    const processingItemsLoaded = Object.keys(formattedDataRequests ?? {}).length > 0
    const totalProcessingItems = Object.values(categoryProcessingItems ?? {}).reduce((a, b) => a + b, 0)
    
    const stripePriceIdKey = devMode == true ? "stripePriceIdTest":"stripePriceId"
    const stripeDisplayKey = devMode == true ? "displayTest":"display"

    return (
        <div className="viewLotContent">
            <div className='viewLotContentScroller'>                
                <Paper elevation={2} className='viewLotContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <IconButton
                            onClick={() => {navigate(`/Organization/Lots`)}}>
                            <Layers color="primary"/>                        
                        </IconButton>
                        <HeaderTitleEditable label="Lot Name" value={lotDisplayName} permissionToEdit={orgObj?.userIsOrgSuperAdmin} saveChanges={updateLotTitle} loading={editLotTitleLoading} />                        
                        <div style={{flex: 1}}></div>
                        <div>
                            {/* <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddCircleOutline />}
                                onClick={() => {
                                    navigate(`/Organization/Lots/${lotId}/AddSubscription`)
                                }}>
                                Add Subscription
                            </Button> */}
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<ViewInAr />}
                                className='hidden-mobile'
                                onClick={() => {
                                    //navigate to Map view passing the lot id
                                    navigate(`/Organization/MapView/${lotId}`)
                                }}>
                                Map View
                            </Button>
                        </div>
                                         
                    </div>     
                    <Tabs 
                        value={selectedTab} 
                        onChange={(event, newValue) => changeTabs(newValue)} 
                        aria-label="basic tabs example"
                        variant="scrollable" 
                        style={{
                            display: 'flex',
                            
                        }}>
                        <Tab 
                            label="View Lot" 
                            icon={<CropFree />} 
                            iconPosition="start"  
                            {...a11yProps(0)} />
                        <Tab 
                            label={

                                processingItemsLoaded ?
                                    totalProcessingItems > 0 ?
                                        <Badge badgeContent={totalProcessingItems} color="secondary">
                                            <span style={{paddingRight: '15px'}}>Processing</span>
                                        </Badge>
                                        :"Processing":
                                    <Badge badgeContent={<CircularProgress size={8} style={{alignSelf: 'center'}}/>} color="secondary">
                                        <span style={{paddingRight: '15px'}}>Processing</span>
                                    </Badge>
                            } 
                            icon={<AccountTree />} 
                            iconPosition="start"  
                            {...a11yProps(1)} />
                        <Tab 
                            label="Subscription" 
                            icon={<CreditCard />} 
                            iconPosition="start"  
                            {...a11yProps(2)} 
                            style={{
                                display: orgObj?.userIsOrgSuperAdmin ? 'flex':'none',
                            }}/>
                    </Tabs>
                    <Divider 
                        style={{
                            display: 'flex',
                        }}/>
                    <div
                        role="tabpanel"
                        hidden={selectedTab !== 0}
                        id={`simple-tabpanel-0`}
                        aria-labelledby={`simple-tab-0`}
                        className="viewLotTabPannel">
                        {selectedTab === 0 && (
                            <div 
                                className='viewLotGeneralTabScrollArea'>
                                {!isLoaded && (
                                    <div className='viewLotMapContainer'>
                                        <CircularProgress />    
                                    </div>
                                )}
                                {isLoaded && (
                                    <>
                                        <Alert variant="filled" severity={"warning"} style={{display: endingSubscriptions.length > 0 && !allSubscriptionsCanceled ? 'flex':'none'}} >
                                            {`Your ${endingSubscriptions.map(key => pricingSchedule[key]?.name ?? key)} subcription${endingSubscriptions.length > 1 ? 's':''} has been cancelled and will end at the end of the period`}
                                        </Alert>
                                        <Alert variant="filled" severity={"info"} style={{display: allSubscriptionsCanceled ? 'flex':'none'}} >
                                            {`This lot's subscription${endingSubscriptions.length > 1 ? 's':''} ${endingSubscriptions.length > 1 ? 'have':'has'} been cancelled.`}
                                        </Alert>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<ViewInAr />}
                                            className='shown-mobile'
                                            style={{width: '100%', marginBottom: '10px'}}
                                            onClick={() => {
                                                //navigate to Map view passing the lot id
                                                navigate(`/Organization/MapView/${lotId}`)
                                            }}>
                                            Map View
                                        </Button>
                                        <GoogleMap           
                                            zoom={mapZoom} 
                                            center={location} 
                                            mapContainerClassName="viewLotMapContainer"
                                            options={mapOptions}
                                            onLoad={map => (mapRef.current = map)}>
                                            <Polygon
                                                editable={false}
                                                draggable={false}
                                                paths={polygonBounds}                
                                                options={{
                                                    ...rectangleOptions,
                                                    fillColor: "#f9a825",
                                                    strokeColor: "#f9a825",
                                                    strokeWeight: 3,
                                                }}/>                        
                                            </GoogleMap> 
                                    </> 
                                )}
                                <div className='viewLotButtonHolder'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Download />}
                                        onClick={() => {
                                            downloadGeoJson()                                            
                                        }}
                                        style={{}}>
                                        Download GeoJson
                                    </Button>                                    
                                </div>
                                <DataDisplay loading={billingLoading} icon={CropDin} title="Total Area" data={`${lotExactArea} km²`}></DataDisplay>
                                <DataDisplay loading={billingLoading} icon={Event} title="Date Created" data={calculateDateFromMillisecond(lotCreatedDate)}></DataDisplay>    
                                
                                                                                     
                            </div>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={selectedTab !== 1}
                        id={`simple-tabpanel-1`}
                        aria-labelledby={`simple-tab-0`}
                        className="viewLotTabPannel">
                        {selectedTab === 1 && (
                            <div className=''>
                                {
                                    Object.keys(formattedDataRequests ?? {}).length == 0 && (
                                        <div className='viewLotMapContainer'>
                                            <CircularProgress />    
                                        </div>
                                    )
                                }
                                {
                                    Object.keys(formattedDataRequests ?? {}).length > 0 && (
                                        <div>
                                            <Tabs
                                                value={Object.keys(formattedDataRequests ?? {})?.indexOf(selectedProcessingProductKey) ?? 0} 
                                                onChange={(event, newValue) => changeProcessingProduct(newValue)} 
                                                aria-label="basic tabs example"
                                                key={"processingTabs"}
                                                style={{
                                                    display: 'flex',
                                                }}>
                                                {



                                                    Object.keys(formattedDataRequests ?? {}).map((key, index) => {
                                                        return <Tab 
                                                                label={
                                                                    categoryProcessingItems[key] > 0 ?
                                                                        <Badge badgeContent={categoryProcessingItems[key] ?? ''} color="secondary">
                                                                            <span style={{paddingRight: '15px'}}>{pricingSchedule[key]?.name ?? key}</span>
                                                                        </Badge>
                                                                        :pricingSchedule[key]?.name ?? key
                                                                    } 
                                                                // icon={<CropFree />} 
                                                                iconPosition="start"  
                                                                {...a11yProps(index)} />

                                                    })
                                                }                                                                        
                                            </Tabs>                                
                                            <Divider 
                                                style={{
                                                    display: 'flex',
                                                }}/>                        
                                            <div style={{padding: '5px', paddingTop: '15px'}}>
                                                <TableContainer component={Paper} elevation={3} style={{  width: 'calc(100% - 0px)', maxHeight: 'calc(100vh - 500px)', display: true ? 'block':'none'}} >
                                                    <Table aria-label="" stickyHeader >
                                                        <TableHead >
                                                            <TableRow style={{minHeight: '60px !important'}}>
                                                                <TableCell>Scheduled</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>% Complete</TableCell>
                                                                <TableCell>Display Imagery</TableCell>
                                                                <TableCell>Analytics</TableCell>                                                
                                                                <TableCell>Last Updated</TableCell>                                                        
                                                            </TableRow>
                                                            </TableHead>
                                                        <TableBody>
                                                            {Object.values(formattedDataRequests[selectedProcessingProductKey] ?? {})
                                                            .sort((a, b) => { 
                                                                //first sort by processing time then by startProcessingDate
                                                                if(a.processingTime && !b.processingTime){
                                                                    return -1
                                                                }else if(!a.processingTime && b.processingTime){
                                                                    return 1
                                                                }
                                                                
                                                                return b.startProcessingDate?.seconds - a.startProcessingDate?.seconds 
                                                            })
                                                            .map((request) => {


                                                                
                                                                const processing = request?.processingTime && (request?.processingStatus?.squares != null || request?.startedProcessingByServer == true)
                                                                const processingMetrics = processing && Object.keys(request?.processingStatus?.squares ?? {}).length > 0  

                                                                var percentageComplete = request?.processingComplete == true ? 100:request?.totalPercentageComplete ?? 0

                                                                var displayProcessedFiles = 0
                                                                var displayTotalFiles = 0
                                                                
                                                                var analyticsProcessedFiles = 0
                                                                var analyticsTotalFiles = 0

                                                                if(processingMetrics){
                                                                    
                                                                    displayProcessedFiles = Object.keys(request?.processingStatus?.squares ?? {}).map((squareId) => {
                                                                        return request?.processingStatus?.squares[squareId]?.pngImagery?.processedFiles ?? 0
                                                                    }).reduce((a, b) => a + b, 0)
                                                                    displayTotalFiles = Object.keys(request?.processingStatus?.squares ?? {}).map((squareId) => {
                                                                        return request?.processingStatus?.squares[squareId]?.pngImagery?.totalFiles ?? 0
                                                                    }).reduce((a, b) => a + b, 0)

                                                                    analyticsProcessedFiles = Object.keys(request?.processingStatus?.squares ?? {}).map((squareId) => {
                                                                        return Object.values(request?.processingStatus?.squares[squareId]?.basicAnalytics?.analytics ?? {}).filter((value) => value == true).length ?? 0
                                                                    }).reduce((a, b) => a + b, 0)
                                                                    analyticsTotalFiles = Object.keys(request?.processingStatus?.squares ?? {}).map((squareId) => {
                                                                        return Object.keys(request?.processingStatus?.squares[squareId]?.basicAnalytics?.analytics ?? {}).length ?? 0
                                                                    }).reduce((a, b) => a + b, 0)


                                                                }
                                                                

                                                                return (
                                                                    <TableRow
                                                                        key={request.id}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, minHeight: '60px !important' }}>
                                                                        <TableCell
                                                                            style={{
                                                                                fontWeight: request?.processingTime ? 'bold':'normal',
                                                                            }}
                                                                            >{(new Date(request.startProcessingDate.seconds * 1000)).toLocaleDateString("en-US", processingDateShortOptions)}</TableCell>
                                                                        <TableCell component="th" scope="row">
                                                                            {
                                                                                request?.processingComplete == true ?
                                                                                    <Chip label="Complete" color="success" />:
                                                                                    processing ?
                                                                                        <Chip label="Processing" color="secondary" />:
                                                                                        request?.startProcessingDate != null ?
                                                                                            <Chip label="Queued" color="primary" />:
                                                                                            null
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {processingMetrics ? <LinearProgressWithLabel value={percentageComplete}/>:''}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {processingMetrics ? `${displayProcessedFiles} of ${displayTotalFiles}`:''}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {processingMetrics ? `${analyticsProcessedFiles} of ${analyticsTotalFiles}`:''}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {(new Date(request?.dateUpdated?.seconds * 1000)).toLocaleDateString("en-US", processingDateOptions)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div> 
                                        </div>
                                    )
                                }                                                                                
                            </div>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={selectedTab !== 2}
                        id={`simple-tabpanel-2`}
                        aria-labelledby={`simple-tab-2`}
                        className="viewLotTabPannel">
                        {selectedTab === 2 && (
                            <div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <Tabs 
                                        value={Object.keys(stripeSubscriptionObj ?? {})?.indexOf(selectedBillingProductKey)} 
                                        onChange={(event, newValue) => changeBillingProduct(newValue)} 
                                        variant="scrollable" 
                                        key={"billingTabs"}
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                        }}>
                                        {
                                            Object.keys(stripeSubscriptionObj ?? {}).map((key, index) => {
                                                const subscriptionObj = stripeSubscriptionObj[key]?.subscription ?? {}
                                                const label = pricingSchedule[subscriptionObj?.metadata?.productType]?.name ?? subscriptionObj?.metadata?.productType ?? key
                                                return <Tab 
                                                        label={label} 
                                                        // icon={<CropFree />} 
                                                        iconPosition="start"  
                                                        {...a11yProps(index)} />

                                            })
                                        }                                                                        
                                    </Tabs>                                                                    
                                </div>
                                <Divider 
                                    style={{
                                        display: 'flex',
                                    }}/>
                                {
                                    Object.keys(stripeSubscriptionObj ?? {}).filter(key => Object.keys(stripeSubscriptionObj ?? {}) == selectedBillingProductKey).map((key) => {
                                        
                                        const subscriptionId = key
                                        const subscriptionObj = stripeSubscriptionObj[subscriptionId]?.subscription ?? {}
                                        const invoiceArray = stripeSubscriptionObj[subscriptionId]?.invoices ?? []

                                        return (
                                            <div 
                                                className='viewLotGeneralTabScrollArea'>                                                
                                                <Alert variant="filled" severity={subscriptionObj?.status != "canceled" ? "warning":"info"} style={{margin: '15px', marginTop: '0px', display: subscriptionObj?.cancel_at != null ? 'flex':'none'}} >
                                                    This subcription has been cancelled { subscriptionObj?.status != "canceled" ? `and will end on ${calculateDateFromMillisecond(subscriptionObj?.cancel_at * 1000)}`:'' } 
                                                </Alert>
                                                <div className='viewLotRefeshButtonHolder'>
                                                    <DataDisplay loading={stripeBillingLoading} icon={DonutLarge} title="Subscription Status" data={subscriptionStatuses[subscriptionObj?.status]}></DataDisplay>                                
                                                
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<Autorenew />}
                                                        onClick={() => {
                                                            getStripeBillingInfo(false)
                                                            getOrgPaymentMethods()
                                                        }}>
                                                        Refresh
                                                    </Button>
                                                </div>
                                                <DataDisplay loading={stripeBillingLoading} icon={Event} title="Created Date" data={(new Date(subscriptionObj?.created * 1000)).toLocaleString("en-US", stripeBillingDateOptions)}></DataDisplay>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<ChangeCircle />}
                                                    style={{
                                                        marginBottom: '15px',
                                                        width: '100%',
                                                    }}
                                                    onClick={() => {setUpdateSubscriptionModalOpen(true)}}>
                                                    Update Subscription
                                                </Button>

                                                <h4 style={{display: subscriptionObj?.status == 'canceled' ? 'none':'flex'}}>Payment Method</h4>
                                                <div className="createLotPaymentOptionsArea" style={{overflowX: stripePaymentMethodsLoading ? 'hidden':'auto', display: subscriptionObj?.status == 'canceled' ? 'none':'flex'}}>
                                                {
                                                    stripePaymentMethodsLoading ?
                                                        <Skeleton variant="rectangular" width={2000} height={280} />
                                                        :
                                                        <div 
                                                            className='createLotPaymentOptionsAreaHolder'
                                                            style={{width: `${(stripePaymentMethods?.length + 1)*430}px`}}>
                                                            {
                                                                stripePaymentMethods?.sort((a, b) => {
        
                                                                    // if the subscriptionObj.default_payment_method == paymentMethod.id the that item should be first
                                                                    // if the item isDefaultPaymentMethod then it should be second (it can be first if it is the default payment method as well)
                                                                    if(a.id == subscriptionObj?.default_payment_method){
                                                                        return -1
                                                                    }else if(b.id == subscriptionObj?.default_payment_method){
                                                                        return 1
                                                                    }else if(a.userDefaultPaymentMethodSet){
                                                                        return -1
                                                                    }else if(b.userDefaultPaymentMethodSet){
                                                                        return 1
                                                                    }else{
                                                                        return 0
                                                                    }
        
                                                                                            
                                                                }).map((paymentMethod) => {
                                                                    return (
                                                                        <PaymentMethod 
                                                                            key={paymentMethod.id}
                                                                            selected={subscriptionObj?.default_payment_method == paymentMethod.id} 
                                                                            loading={false} 
                                                                            create={false} 
                                                                            organizationId={orgObj?.selectedOrganization} 
                                                                            paymentMethodObj={paymentMethod} 
                                                                            createAlert={createAlert} 
                                                                            refreshWallet={getOrgPaymentMethods}
                                                                            manageOnClick={() => {
                                                                                if(paymentMethod.id != subscriptionObj?.default_payment_method){
                                                                                    setSelectedPaymentMethod(paymentMethod)
                                                                                    setShowSelectedPaymentMethodDialog(true)
                                                                                }
                                                                            }}/>
                                                                    )
                                                                })
                                                            }
                                                            <PaymentMethod 
                                                                key={"create"}
                                                                selected={false}
                                                                loading={stripePaymentMethodsLoading} 
                                                                create={true} 
                                                                organizationId={orgObj?.selectedOrganization} 
                                                                paymentMethodObj={{
                                                                    userDefaultPaymentMethodSet: stripePaymentMethods?.length > 0 ? stripePaymentMethods?.[0]?.userDefaultPaymentMethodSet == true : false
                                                                }}
                                                                createAlert={createAlert} 
                                                                refreshWallet={getOrgPaymentMethods}/>
                                                        </div>
                                                }                            
                                                </div>
        
                                                <h4>Invoices</h4>
                                                <Paper elevation={3} style={{marginRight: '5px', marginLeft: '5px', marginBottom: '50px', width: 'calc(100% - 0px)', overflow: 'hidden', display: stripeBillingLoading ? 'block':'none'}} >
                                                    <Skeleton variant="rectangular" width={2000} height={60+(60*invoiceArray.length)} />
                                                </Paper>
                                                <TableContainer component={Paper} elevation={3} style={{marginRight: '5px', marginLeft: '5px', marginBottom: '50px', width: 'calc(100% - 10px)', display: !stripeBillingLoading ? 'block':'none'}} >
                                                    <Table aria-label="simple table">
                                                        <TableHead >
                                                            <TableRow style={{minHeight: '60px !important'}}>
                                                                <TableCell>Amount</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Invoice Number</TableCell>
                                                                <TableCell>Period</TableCell>                                                
                                                                <TableCell>Created</TableCell>
                                                                <TableCell>Manage</TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                        <TableBody>
                                                        {invoiceArray.map((invoice) => {
                                                        

                                                            var periodStartDate = new Date(invoice.period_start * 1000)                                                           
                                                            var periodEndDate = new Date(invoice.period_end * 1000)
                                                            //periiod start date is one month before the end date
                                                            
                                                            if(invoice?.subscription_details?.metadata?.billingSchedule == "month"){

                                                                //set the start date to one month before the end date
                                                                periodStartDate = new Date(periodEndDate.toLocaleDateString("en-US", stripeBillingDateOptions))                                                                
                                                                periodStartDate.setUTCMonth(periodStartDate.getMonth() - 1)
                                                            }
                                                            //if period start and end date are the same then check the invoice.lines.data[0].period.start and end
                                                            else if(periodStartDate.getDate() == periodEndDate.getDate() && periodStartDate.getMonth() == periodEndDate.getMonth() && periodStartDate.getFullYear() == periodEndDate.getFullYear()){
                                                                periodStartDate = typeof invoice?.lines.data[0]?.period?.start == 'number' ? new Date(invoice?.lines.data[0]?.period?.start * 1000) : periodStartDate
                                                                periodEndDate = typeof invoice?.lines.data[0]?.period?.end == 'number' ? new Date(invoice?.lines.data[0]?.period?.end * 1000) : periodEndDate
                                                            }
        
        
                                                            return (
                                                                <TableRow
                                                                key={invoice.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, minHeight: '60px !important' }}>
                                                                    <TableCell component="th" scope="row">
                                                                        {`${(new Intl.NumberFormat('en-US', {style: 'currency', currency: invoice.currency.toUpperCase(),}).format(invoice.amount_due/100))} ${invoice.currency.toUpperCase()}`}
                                                                    </TableCell>
                                                                    <TableCell>{invoiceStatuses?.[invoice.status]}</TableCell>
                                                                    <TableCell>{invoice.number}</TableCell>
                                                                    <TableCell>{periodStartDate.toLocaleDateString("en-US", stripeBillingDateOptions)} - {periodEndDate.toLocaleDateString("en-US", stripeBillingDateOptions)}</TableCell>
                                                                    <TableCell>{(new Date(invoice.created * 1000)).toLocaleDateString("en-US", stripeBillingDateOptions)}</TableCell>
                                                                    <TableCell>
                                                                        {invoiceActions?.[invoice.status](invoice.id, subscriptionId)} 
                                                                        <Button 
                                                                            variant='contained'
                                                                            color='primary'
                                                                            startIcon={<Autorenew />}
                                                                            onClick={() => {
                                                                                updateInvoiceDetails(invoice.id)
                                                                            }}
                                                                            style={{display: adminMode == true ? 'flex':'none'}}
                                                                            disabled={!adminMode}>
                                                                            Update
                                                                        </Button>   </TableCell>                                                                    
                                                                </TableRow>
                                                            )
                                                        })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
        
                                                {
                                                    //if the subcription has been canceled
                                                    subscriptionObj?.status == 'canceled' ?
                                                    <h4 style={{marginBottom: '5px'}}>Subscription Cancelled {calculateDateFromMillisecond(subscriptionObj?.canceled_at * 1000) }</h4>
                                                    :subscriptionObj?.cancel_at_period_end ?
                                                    <>
                                                        {
                                                            //if the stripesubscriptonobj.canceled_at is set the display
                                                            subscriptionObj?.cancel_at != null ?
                                                            <>
                                                                <h4 style={{marginBottom: '5px'}}>Subscription Cancelled</h4>
                                                                {
                                                                    (() => {                                                        
                                                                        switch(subscriptionObj?.status){
                                                                            case 'paused':
                                                                                return <p>Your subscription has been paused</p>
                                                                            default:
                                                                                return <p>This lot's subcription has been cancelled and will end on {calculateDateFromMillisecond(subscriptionObj?.cancel_at * 1000)}</p>                                                                
                                                                        }
                                                                    })() //inline function notation
                                                                }
                                                                
                                                            </>
                                                            :null
                                                        }
                                                        
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            startIcon={<RestartAlt />}
                                                            onClick={() => {
                                                                setOpenReactivateSubscriptionDialog(true)
                                                            }}
                                                            disabled={stripeBillingLoading}>
                                                            Reactivate Subscription
                                                        </Button>
                                                    </>
                                                    :
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<Cancel />}
                                                        onClick={() => {
                                                            setOpenCancelSubscriptionDialog(true)
                                                            
                                                        }}
                                                        disabled={stripeBillingLoading}>
                                                        Cancel Subscription
                                                    </Button>
                                                }
                                                
                                            </div>  
                                        )
                                    })
                                }                            
                            </div>                   
                        )}
                    </div>
                    
                </Paper>                
            </div>            
            
            <Footer></Footer>

            <Dialog
                open={showSelectedPaymentMethodDialog}
                onClose={() => setShowSelectedPaymentMethodDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Update this subscription's selected payment method?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`All future invoices for ${lotDisplayName} will be charged to the card ending in ${selectedPaymentMethod?.card?.last4}.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowSelectedPaymentMethodDialog(false)}>Cancel</Button>
                <Button variant='contained' color='secondary' onClick={updateSubscriptionDefaultPaymentMethod} autoFocus>
                    Update
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openCancelSubscriptionDialog}
                onClose={() => setOpenCancelSubscriptionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to cancel this lot's subscription?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Cancelling the subscription for ${lotDisplayName} will stop all future charges for this lot, you will still be able to acces your exiting data.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenCancelSubscriptionDialog(false)}>Nevermind</Button>
                <Button variant='contained' startIcon={<Cancel />} onClick={cancelSubscription} autoFocus>
                    Cancel Subscription
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openReactivateSubscriptionDialog}
                onClose={() => setOpenReactivateSubscriptionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Would you like to reactivate your subscription?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Reactivating the subscription for ${lotDisplayName} will resume billing for this lot.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenReactivateSubscriptionDialog(false)}>Nevermind</Button>
                <Button variant='contained' color="secondary" startIcon={<RestartAlt />} onClick={reactivateSubscription} autoFocus>
                    Reactivate Subscription
                </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={updateSubscriptionModalOpen}
                onClose={() => setUpdateSubscriptionModalOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "900px",  // Set your width here
                      },
                    },
                }}>
                <DialogTitle id="alert-dialog-title">
                {"Update Subscription"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{marginBottom: '10px'}}>
                        {`${lotDisplayName} will be updated to the selected product level. This will take effect immediately and may result in a change to the billing amount.`}
                    </DialogContentText>
                    <div className="viewLotCheckoutOptionsArea">
                        {
                            Object.keys(pricingSchedule)
                            .filter(e => {
                                const currentSelectedProduct = stripeSubscriptionObj?.[selectedBillingProductKey]?.subscription


                                const elementPriceId = pricingSchedule[e]?.[stripePriceIdKey];
                                const previousPriceInterval = currentSelectedProduct?.plan?.interval;
                                const newPriceInterval = pricingSchedule[e]?.billingSchedule;

                                const previousType = pricingSchedule[currentSelectedProduct?.plan?.metadata?.stripePricingSchedule]?.type;
                                const newType = pricingSchedule[e].type;

                                //if the selected product billingSchedule is month then show all products with month and year
                                // var billingScheduleCheck = false
                                // if(currentSelectedProduct?.metadata?.billingSchedule == "month"){
                                //     billingScheduleCheck = pricingSchedule[e]?.billingSchedule == "month" || pricingSchedule[e]?.billingSchedule == "year"
                                // }else if(currentSelectedProduct?.metadata?.billingSchedule == "year"){
                                //     billingScheduleCheck = pricingSchedule[e]?.billingSchedule == "year"
                                // }

                                var prorationCheck = false
                                if(currentSelectedProduct?.plan?.id == elementPriceId){
                                    prorationCheck = true
                                }else if(previousType == "analytics" && newType == "basemap" && (previousPriceInterval == newPriceInterval)){
                                    prorationCheck = true
                                }else if(previousType == newType && (previousPriceInterval == "month" && newPriceInterval == "year")){
                                    prorationCheck = true
                                }else if(previousType == "basemap" && newType == "analytics" && (previousPriceInterval == 'month')){
                                    prorationCheck = true
                                }else if(previousType == "basemap" && newType == "analytics" && (newPriceInterval == 'year')){
                                    prorationCheck = true
                                }

                                // if(previousType == newType && previousPriceInterval == 'month' && newPriceInterval == 'year'){
                                //     prorationCheck = true;            
                                // }else if(previousType == "basemap" && newType == "analytics" && (previousPriceInterval == 'month')){
                                //     prorationCheck = true   
                                // }else if((previousPriceInterval == newPriceInterval)){
                                //     //if the previous type is basemap and the new type is analytics and the intervals are the same or going from monthly to yearly then prorate the subscription
                                //     prorationCheck = true;
                                // }else{
                                //     prorationCheck = false;
                                // }


                                var activeCheck = false
                                if(pricingSchedule[e]?.[stripeDisplayKey] == true){
                                    activeCheck = true
                                }

                                return prorationCheck && activeCheck
                            })
                            .sort((a, b) => pricingSchedule[a].index - pricingSchedule[b].index)
                            .map((key, index) => {
                                const element = pricingSchedule[key]

                                const costPer = lotExactArea * element?.price/100

                                const existingSubscription = Object.values(fbBillingObj?.stripeSubscription ?? {}).find((subscription) => subscription?.priceId == element?.[stripePriceIdKey])?.status == "active" ?? false 
                                
                                return (
                                    <div 
                                        className='checkoutOption'
                                        onClick={() => {
                                            if(!(element?.[stripePriceIdKey] == null || (Object.values(fbBillingObj?.stripeSubscription ?? {}).find((subscription) => subscription?.priceId == element?.[stripePriceIdKey])?.status == "active" ?? false))){
                                                setUpdateSubscriptionTargetMilliseconds((new Date()).getTime() + 4000)     
                                            }else{
                                                setUpdateSubscriptionTargetMilliseconds(0)
                                            }
                                            setTimeout(() => {
                                                setUpdateSubscriptionPriceKey(key)
                                                setUpdateSubscriptionPriceId(element?.[stripePriceIdKey])          
                                                    
                                            }, 100);
                                 
                                        }}
                                        style={{/*backgroundColor: selectedPricingPlan == key ? '#f9a825':""*/}}>
                                        <h1>{element?.name}</h1>
                                        <h3>{element?.tagDescription}</h3>
                                        <h2>{formatAsDollar(costPer)}<span>/{element?.billingSchedule}</span></h2>
                                        <h4>{element?.savings}</h4>
                                        <Button 
                                            variant='contained'
                                            color={updateSubscriptionPriceKey == key ? 'secondary':'primary'}
                                            style={{marginBottom: '10px', }}
                                            startIcon={existingSubscription ? null:updateSubscriptionPriceKey == key ? <Check />:null}
                                            disabled={existingSubscription}                                                
                                            onClick={() => {
                                                if(!(element?.[stripePriceIdKey] == null || (Object.values(fbBillingObj?.stripeSubscription ?? {}).find((subscription) => subscription?.priceId == element?.[stripePriceIdKey])?.status == "active" ?? false))){                                            
                                                    setUpdateSubscriptionTargetMilliseconds((new Date()).getTime() + 4000)
                                                }else{
                                                    setUpdateSubscriptionTargetMilliseconds(0)
                                                }
                                                setTimeout(() => {
                                                    setUpdateSubscriptionPriceKey(key)
                                                    setUpdateSubscriptionPriceId(element?.[stripePriceIdKey])          
                                                        
                                                }, 100);
                                            }}>
                                            {existingSubscription ? "Already Subscribed" : updateSubscriptionPriceKey == key ? "Selected Plan":"Choose Plan"} 
                                        </Button>
                                    </div>
                                )

                            })
                        }                                                        
                    </div>
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setUpdateSubscriptionModalOpen(false)}>Nevermind</Button>
                <CountdownTimerButton 
                    targetMilliseconds={updateSubscriptionTargetMilliseconds} 
                    loading={updateSubscriptionLoading}
                    disabled={updateSubscriptionPriceId == null || (Object.values(fbBillingObj?.stripeSubscription ?? {}).find((subscription) => subscription?.priceId == updateSubscriptionPriceId)?.status == "active" ?? false)}
                    icon={<RestartAlt />} 
                    text="Update Subscription"
                    action={() => {updateSubscriptionPrice()}}/>
                
                </DialogActions>
            </Dialog>
            
            
        </div>
    )

}


export default ViewLot