import { dateToLocaleUTCDateString } from './dateToLocaleUTCDateString';

export function calculateDateSliderMarks(_lotData, _stepInterval){
    try{

      const availableImageryDates = Object.values(_lotData ?? {})
                                        .map(a => Object.values(a.timeData)
                                        .filter(b => b?.endDate != null)
                                        .map(c => c.endDate.getTime()))
                                        .flat(Infinity)
    
     
      //filter out duplicate date values 
      const flattenedData = Object.values(_lotData ?? {}).map(a => Object.values(a.timeData)).flat(Infinity)
      var dedupedDates = [...new Set(availableImageryDates)].map(time => flattenedData.find(a => a.endDate.getTime() == time))

      if(_stepInterval == 'yearly'){
        //get the closest date to june in each year       
        dedupedDates = dedupedDates.sort((a, b) => a.displayDate.getTime() - b.displayDate.getTime())
        var tempDedupedDatesObj = {}
        dedupedDates.forEach((dateElement) => {

          const month = dateElement.displayDate.getUTCMonth()
          const year = dateElement.displayDate.getUTCFullYear()

          //if the month is closer to july then set the tempDedupedDates to the dateElement
          if(tempDedupedDatesObj[year] == null){
            tempDedupedDatesObj[year] = dateElement
          }else if(Math.abs(month - 6) < Math.abs(tempDedupedDatesObj[year].displayDate.getUTCMonth() - 6)){
            tempDedupedDatesObj[year] = dateElement
          }

          // const year = dateElement.displayDate.getUTCFullYear()
          // if(tempDedupedDates.find(a => a.displayDate.getUTCFullYear() == year) == null){
          //   tempDedupedDates.push(dateElement)
          // }
        })

        // if(tempDedupedDates.map(e => e.dateId).includes(dedupedDates[dedupedDates.length - 1].dateId) == false){
        //   tempDedupedDates.push(dedupedDates[dedupedDates.length - 1])
        // }
        
        //remove duplicates from tempDedupedDates
        var tempDedupedDatesArray = Object.values(tempDedupedDatesObj).sort((a, b) => a.displayDate.getTime() - b.displayDate.getTime())
        //add the most recent date
        //prepend the first date in the array

        const firstDate = dedupedDates[0]
        const lastDate = dedupedDates[dedupedDates.length - 1]
        if(tempDedupedDatesArray[0].displayDate.getTime() != firstDate.displayDate.getTime()){
          tempDedupedDatesArray.unshift(firstDate)
        }
        if(tempDedupedDatesArray[tempDedupedDatesArray.length - 1].displayDate.getTime() != lastDate.displayDate.getTime()){
          tempDedupedDatesArray.push(lastDate)
        }

        dedupedDates = tempDedupedDatesArray


      }else if(_stepInterval == 'monthly'){
        //do nothing
      }

      var firstDateInEachYear = {}
      dedupedDates.forEach((dateElement) => {
        
        if(_stepInterval == 'yearly'){
          const year = dateElement.displayDate.getUTCFullYear()
          if(firstDateInEachYear[year] == null){
            firstDateInEachYear[year] = dateElement
          }else if(dateElement.displayDate.getTime() < firstDateInEachYear[year].displayDate.getTime()){
            firstDateInEachYear[year] = dateElement
          }
        }else if(_stepInterval == 'monthly'){
          const year = dateElement.displayDate.getUTCFullYear()
          if(firstDateInEachYear[year] == null){
            firstDateInEachYear[year] = dateElement
          }else if(dateElement.displayDate.getTime() < firstDateInEachYear[year].displayDate.getTime()){
            firstDateInEachYear[year] = dateElement
          }
        }

      })

      var firstDateInEachYear = Object.values(firstDateInEachYear).map(e => e.dateId)

      if(dedupedDates != null && dedupedDates.length > 0){        
        const minDate = Math.min(...dedupedDates.map(a => a.endDate.getTime()))
        const maxDate = Math.max(...dedupedDates.map(a => a.endDate.getTime()))
        
        const tempDateSliderMarks = dedupedDates.map((dateElement) => {

          // const element = Object.values(_squareData ?? {}).map(a => Object.values(a.timeData)).flat(Infinity).
          
          //if the date is january 1st, display the year
          var label = ""
          //get utc date for the first date in the year
          if(firstDateInEachYear.includes(dateElement.dateId)){

            //get UTC month and year abbreviation            
            label = dateToLocaleUTCDateString(
              dateElement.displayDate, 
              "en-US", 
              {year: 'numeric'})
          }
          

          return {
            value: dateElement.endDate.getTime(),
            label: label,    
            dateElement: {
              dateId: dateElement.dateId,
              displayDate: dateElement.displayDate,
              endDate: dateElement.endDate,
              endDateId: dateElement.endDateId,
              dateRange: dateElement.dateRange ?? "",
              
            }
               
          }
        })


        return {
          "marks": tempDateSliderMarks.sort((a, b) => a.value - b.value),
          "min": minDate,
          "max": maxDate
        }

      }else{
        return {
          "marks": [],
          "min": 0,
          "max": 0
        }
      }

    }
    catch(error){
      console.error("Error calculating date slider marks: " + error)
      return {
        "marks": [],
        "min": 0,
        "max": 0
      }

    
    }
  }