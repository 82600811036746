
import { calculateAreaFromCoordinates } from './calculateAreaFromCoordinates';



export function calculateRectangleArea(_bounds) {
    try{
        const coordinates = [
            {
                lat: _bounds.north,
                lng: _bounds.west
            },
            {
                lat: _bounds.north,
                lng: _bounds.east
            },
            {
                lat: _bounds.south,
                lng: _bounds.east
            },
            {
                lat: _bounds.south,
                lng: _bounds.west
            },
            {
                lat: _bounds.north,
                lng: _bounds.west
            }
        ]
        return calculateAreaFromCoordinates(coordinates);
    } catch (error) {
        console.error(error);
        return 0;
    }
}