import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import firebase from 'firebase/compat/app'
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, 
          InputLabel, IconButton, MenuItem, Paper, Slider, TextField, Divider, Select, CircularProgress,
          Stack,  } from '@mui/material';
import * as turf from '@turf/turf';
import { GoogleMap, StreetViewPanorama, useLoadScript, Marker, Polygon, GroundOverlay, Polyline, Rectangle } from '@react-google-maps/api';
import { getStorage, ref, listAll, getDownloadURL, getMetadata, uploadBytes } from "firebase/storage";
import { ArrowBack, ArrowCircleLeft, CheckBox, CheckCircleOutline, Close, CloseFullscreen, 
        CloudUpload, Collections, Crop, Dangerous, Event, ExitToApp, Filter1, Gif, 
        GridView, HideImage, Photo, Publish, Refresh, Save, SelectAll, Straighten, 
        ViewInAr, FilterOutlined, Filter1Outlined, Filter2Outlined, Filter3Outlined, 
        Filter4Outlined, Filter5Outlined, Filter6Outlined, Filter7Outlined, Filter8Outlined,
        Filter9Outlined, Filter9PlusOutlined, Label, BrightnessLow, BrightnessHigh, AspectRatio,
        CropSquare, Square, TouchApp, Gesture, FormatColorFill, CheckBoxOutlined,
        ViewModule} from '@mui/icons-material';
import PolylineIcon from '@mui/icons-material/Polyline';

import './AdminMapReview.css';
import settings from '../../settings.json';
import googleMapsStyle from '../../assets/googleMapsStyle.json'

import CountdownTimerButton from '../CountdownTimerButton/CountdownTimerButton'

import { calculatePolygonBounds } from '../../functions/calculatePolygonBounds'
import { calculatePolygonsIntersect } from '../../functions/calculatePolygonsIntersect'
import { calculateProcessingGridSquareBounds } from '../../functions/calculateProcessingGridSquareBounds'
import { createAdminSquareFromId } from '../../functions/createAdminSquareFromId'
import { calculateSquareLotsOverlapMask } from '../../functions/calculateSquareLotsOverlapMask'
import { formatDateAsId } from '../../functions/formatDateAsId';
import { calculateRectangleArea } from '../../functions/calculateRectangleArea'
import { calculateSquareLotsOverlap } from '../../functions/calculateSquareLotsOverlap'
import { dateIdToUTCDateObject } from '../../functions/dateIdToUTCDateObject'
import { dateIdToAnalyticsRequestId } from '../../functions/dateIdToAnalyticsRequestId'
import { dateIdToAnalyticsRequestIdShorthand } from '../../functions/dateIdToAnalyticsRequestIdShorthand'
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString'
import { calculateRelativeDateSliderMarkValue } from '../../functions/calculateRelativeDateSliderMarkValue';
import { findClosestObjectId } from '../../functions/findClosestObjectId'
import { calculateDateMarkStyles } from '../../functions/calculateDateMarkStyles';

import { create } from '@mui/material/styles/createTransitions';
import { calculateDateSliderMarks } from '../../functions/calculateDateSliderMarks';
import DataDisplay from '../DataDisplay/DataDisplay';

import { availableAnalytics } from '../../components/AnalyticRendering/AnalyticRendering'
import { boundsToPolygon } from '../../functions/boundsToPolygon';

function AdminMapReview(props) {

  const user = props.user;
  const createAlert = props.createAlert
  const orgObj = props.organizationObj
  const navigate = useNavigate()

  const adminMLAnalyticsNumberOfFrames = 12

  const numberOfGifSections = 10
  const squareSize = .25

  const reviewMode = props.reviewMode
  const routeParams = useParams()

  const propsSquareId = routeParams.squareId
  const propsTaskId = routeParams.taskId
  const propsReviewerId = routeParams.reviewerId

  const activeUserId = propsReviewerId != null ? propsReviewerId : user.uid


  const theme = useTheme();
  const firestore = firebase.firestore();
  const storage = getStorage();
  const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"})

  const mapRef = useRef(null);
  const pegmanListerTimeoutCount = useRef(0)
  
  const lotsRef = useRef({})
  const squaresRef = useRef({})

  const currentSquareLotOverlapRef = useRef(null)
  const lastViewportRenderRef = useRef(0)
  const lastViewportRenderTimeoutRef = useRef(null)
  const currentLocationRef  = useRef({lat: 36.1627, lng: -86.7816});  
  const squarePngImageryRef = useRef({})
  const squareGifImageryRef = useRef({})
  const squareAnalyticsRef = useRef({})

  const [refreshKey, setRefreshKey] = useState(0)
  const [mapZoom, setMapZoom] = useState(12)
  const [location, setLocation] = useState({lat: currentLocationRef.current.lat, lng: currentLocationRef.current.lng,})
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [mapIsStreetViewVisible, setMapIsStreetViewVisible] = useState(false);
  const [mapPanoramaIsDragging, setMapPanoramaIsDragging] = useState(false);
  const [visibleLotsArray, setVisibleLotsArray] = useState([])
  const [visibleSquaresArray, setVisibleSquaresArray] = useState([])
  const [confirmExitWithoutSavingDialogOpen, setConfirmExitWithoutSavingDialogOpen] = useState(false)
  const [squareGifImagery, setSquareGifImagery] = useState(null)
  const [submitChangesDialogOpen, setSubmitChangesDialogOpen] = useState(false)

  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false)
  const [publishChangesDialogOpenTime, setPublishChangesDialogOpenTime] = useState(null)
  const [savingChangesLoading, setSavingChangesLoading] = useState(false)
  const [completeAnalyticProcessingDialogOpen, setCompleteAnalyticProcessingDialogOpen] = useState(false)
  const [completeAnalyticProcessingDialogOpenTime, setCompleteAnalyticProcessingDialogOpenTime] = useState(null)

  

  const currentSquareRef = useRef(null)
  const currentSquarePngDateObjectRef = useRef(null)
  const currentSquareObjectRef = useRef(null)
  const currentSquarePngDatesRef = useRef(null) 
  const [currentSquare, setCurrentSquare] = useState(null)
  const [currentSquareLotMask, setCurrentSquareLotMask] = useState(null)
  const [currentSquareObject, setCurrentSquareObject] = useState(null)
  const [currentSquarePngDates, setCurrentSquarePngDates] = useState(null)
  const [currentSquareVisiblePngs, setCurrentSquareVisiblePngs] = useState(null)
  const [currentSquarePngDateObject, setCurrentSquarePngDateObject] = useState(null)
  const [currentSquareSelectedImageryType, setCurrentSquareSelectedImageryType] = useState("png")

  const currentSquareSelectedToolTypeRef = useRef("clicker")
  const [currentSquareSelectedToolType, setCurrentSquareSelectedToolType] = useState("clicker")

  const polygonToolPolygonPolylineRef = useRef(null)
  const polygonToolPolygonPolygonRef = useRef(null)
  const polygonToolPolygonRef = useRef(null)
  const [polygonToolPolygon, setPolygonToolPolygon] = useState(null)
  const largestMLGroupRef = useRef({})

  const [squareAnalytics, setSquareAnalytics] = useState({})

  const dateSliderMarksRef = useRef(null)
  const dateSliderMarksLoadStateRef = useRef({})
  const dateSliderValueRef = useRef(null)
  const [dateSliderMarks, setDateSliderMarks] = useState(null)
  const [dateSliderMinDate, setDateSliderMinDate] = useState(null)
  const [dateSliderMaxDate, setDateSliderMaxDate] = useState(null)
  const [dateSliderValue, setDateSliderValue] = useState(null)
  const [activeDateObject, setActiveDateObject] = useState(null)
  const [dateSliderMarksLoadStateStyle, setDateSliderMarksLoadStateStyle] = useState({})

  const processingAnalyticChangesRef = useRef(null)
  const processingAnalyticIdRef = useRef(null)
  const processingAnalyticIdShorthandRef = useRef(null)
  const processingAnalyticLastSavedState = useRef(null)
  const [processingAnalyticSaveNecessary, setProcessingAnalyticSaveNecessary] = useState(false) 
  const [processingAnalyticId, setProcessingAnalyticId] = useState(null)
  const [processingAnalyticIdShorthand, setProcessingAnalyticIdShorthand] = useState(null)
  const [processingAnalyticChanges, setProcessingAnalyticChanges] = useState(null)

  const taskObjectRef = useRef(null)
  const taskReviewObjectRef = useRef(null)
  const taskReviewIndexOfKeyRef = useRef(null)
  const [taskObject, setTaskObject] = useState(null)
  const [taskReviewObject, setTaskReviewObject] = useState(null)
  const [taskReviewIndexOfKey, setTaskReviewIndexOfKey] = useState(null)

  const exportImageryRectangleRef = useRef(null)  
  const exportImageryDataRef = useRef({})
  const [exportImageryDialogOpen, setExportImageryDialogOpen] = useState(false)
  // const [exportImageryPolygon, setExportImageryPolygon] = useState(null)
  const [exportImageryRectangle, setExportImageryRectangle] = useState(null)
  const [exportImageryArea, setExportImageryArea] = useState(null)
  const [adminExportImageryStartDate, setAdminExportImageryStartDate] = useState(null)
  const [adminExportImageryEndDate, setAdminExportImageryEndDate] = useState(null)
  const [adminExportImageryDisplayName, setAdminExportImageryDisplayName] = useState(null)
  const [adminExportImageryLoading, setAdminExportImageryLoading] = useState(false)
  const [adminExportImageryAspectRatio, setAdminExportImageryAspectRatio] = useState("")

  const [imageryBrightness, setImageryBrightness] = useState(10)
  const mlConfidenceThresholdRef = useRef(0)
  const [mlConfidenceThreshold, setMlConfidenceThreshold] = useState(mlConfidenceThresholdRef.current)
  
  const showAnalyticsRef = useRef(true)
  const [showAnalytics, setShowAnalytics] = useState(true)

  const procesingAnalyticGridRef = useRef({})

  const [processingAnalyticGrid00, setProcessingAnalyticGrid00] = useState(null)  
  const [processingAnalyticGrid01, setProcessingAnalyticGrid01] = useState(null)
  const [processingAnalyticGrid02, setProcessingAnalyticGrid02] = useState(null)
  const [processingAnalyticGrid03, setProcessingAnalyticGrid03] = useState(null)
  const [processingAnalyticGrid04, setProcessingAnalyticGrid04] = useState(null)
  const [processingAnalyticGrid05, setProcessingAnalyticGrid05] = useState(null)
  const [processingAnalyticGrid10, setProcessingAnalyticGrid10] = useState(null)
  const [processingAnalyticGrid11, setProcessingAnalyticGrid11] = useState(null)
  const [processingAnalyticGrid12, setProcessingAnalyticGrid12] = useState(null)
  const [processingAnalyticGrid13, setProcessingAnalyticGrid13] = useState(null)
  const [processingAnalyticGrid14, setProcessingAnalyticGrid14] = useState(null)
  const [processingAnalyticGrid15, setProcessingAnalyticGrid15] = useState(null)
  const [processingAnalyticGrid20, setProcessingAnalyticGrid20] = useState(null)
  const [processingAnalyticGrid21, setProcessingAnalyticGrid21] = useState(null)
  const [processingAnalyticGrid22, setProcessingAnalyticGrid22] = useState(null)
  const [processingAnalyticGrid23, setProcessingAnalyticGrid23] = useState(null)
  const [processingAnalyticGrid24, setProcessingAnalyticGrid24] = useState(null)
  const [processingAnalyticGrid25, setProcessingAnalyticGrid25] = useState(null)
  const [processingAnalyticGrid30, setProcessingAnalyticGrid30] = useState(null)
  const [processingAnalyticGrid31, setProcessingAnalyticGrid31] = useState(null)
  const [processingAnalyticGrid32, setProcessingAnalyticGrid32] = useState(null)
  const [processingAnalyticGrid33, setProcessingAnalyticGrid33] = useState(null)
  const [processingAnalyticGrid34, setProcessingAnalyticGrid34] = useState(null)
  const [processingAnalyticGrid35, setProcessingAnalyticGrid35] = useState(null)
  const [processingAnalyticGrid40, setProcessingAnalyticGrid40] = useState(null)
  const [processingAnalyticGrid41, setProcessingAnalyticGrid41] = useState(null)
  const [processingAnalyticGrid42, setProcessingAnalyticGrid42] = useState(null)
  const [processingAnalyticGrid43, setProcessingAnalyticGrid43] = useState(null)
  const [processingAnalyticGrid44, setProcessingAnalyticGrid44] = useState(null)
  const [processingAnalyticGrid45, setProcessingAnalyticGrid45] = useState(null)
  const [processingAnalyticGrid50, setProcessingAnalyticGrid50] = useState(null)
  const [processingAnalyticGrid51, setProcessingAnalyticGrid51] = useState(null)
  const [processingAnalyticGrid52, setProcessingAnalyticGrid52] = useState(null)
  const [processingAnalyticGrid53, setProcessingAnalyticGrid53] = useState(null)
  const [processingAnalyticGrid54, setProcessingAnalyticGrid54] = useState(null)
  const [processingAnalyticGrid55, setProcessingAnalyticGrid55] = useState(null)

  
  const processingGridSize = 6
  const processingAnalyticGrid = {
    "00": [processingAnalyticGrid00, setProcessingAnalyticGrid00],
    "01": [processingAnalyticGrid01, setProcessingAnalyticGrid01],
    "02": [processingAnalyticGrid02, setProcessingAnalyticGrid02],
    "03": [processingAnalyticGrid03, setProcessingAnalyticGrid03],
    "04": [processingAnalyticGrid04, setProcessingAnalyticGrid04],
    "05": [processingAnalyticGrid05, setProcessingAnalyticGrid05],
    "10": [processingAnalyticGrid10, setProcessingAnalyticGrid10],
    "11": [processingAnalyticGrid11, setProcessingAnalyticGrid11],
    "12": [processingAnalyticGrid12, setProcessingAnalyticGrid12],
    "13": [processingAnalyticGrid13, setProcessingAnalyticGrid13],
    "14": [processingAnalyticGrid14, setProcessingAnalyticGrid14],
    "15": [processingAnalyticGrid15, setProcessingAnalyticGrid15],
    "20": [processingAnalyticGrid20, setProcessingAnalyticGrid20],
    "21": [processingAnalyticGrid21, setProcessingAnalyticGrid21],
    "22": [processingAnalyticGrid22, setProcessingAnalyticGrid22],
    "23": [processingAnalyticGrid23, setProcessingAnalyticGrid23],
    "24": [processingAnalyticGrid24, setProcessingAnalyticGrid24],
    "25": [processingAnalyticGrid25, setProcessingAnalyticGrid25],
    "30": [processingAnalyticGrid30, setProcessingAnalyticGrid30],
    "31": [processingAnalyticGrid31, setProcessingAnalyticGrid31],
    "32": [processingAnalyticGrid32, setProcessingAnalyticGrid32],
    "33": [processingAnalyticGrid33, setProcessingAnalyticGrid33],
    "34": [processingAnalyticGrid34, setProcessingAnalyticGrid34],
    "35": [processingAnalyticGrid35, setProcessingAnalyticGrid35],
    "40": [processingAnalyticGrid40, setProcessingAnalyticGrid40],
    "41": [processingAnalyticGrid41, setProcessingAnalyticGrid41],
    "42": [processingAnalyticGrid42, setProcessingAnalyticGrid42],
    "43": [processingAnalyticGrid43, setProcessingAnalyticGrid43],
    "44": [processingAnalyticGrid44, setProcessingAnalyticGrid44],
    "45": [processingAnalyticGrid45, setProcessingAnalyticGrid45],
    "50": [processingAnalyticGrid50, setProcessingAnalyticGrid50],
    "51": [processingAnalyticGrid51, setProcessingAnalyticGrid51],
    "52": [processingAnalyticGrid52, setProcessingAnalyticGrid52],
    "53": [processingAnalyticGrid53, setProcessingAnalyticGrid53],
    "54": [processingAnalyticGrid54, setProcessingAnalyticGrid54],
    "55": [processingAnalyticGrid55, setProcessingAnalyticGrid55],
  }

  const numberOfImageIcons = {
    "0": FilterOutlined, 
    "1": Filter1Outlined, 
    "2": Filter2Outlined,
    "3": Filter3Outlined,
    "4": Filter4Outlined,
    "5": Filter5Outlined,
    "6": Filter6Outlined,
    "7": Filter7Outlined,
    "8": Filter8Outlined,
    "9": Filter9Outlined,
    "10": Filter9PlusOutlined,

  }

  useEffect(() => {
    init()
  },[])

  useEffect(() => {
    if(currentSquare != null){
      if(processingAnalyticId != null){

        const minAnalyticDate = (new Date(dateSliderMarksRef.current.marks.find(mark => mark.value == calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current.marks, calculateTempAnalyticKey(), -11))?.dateElement?.dateId)).getTime()
        const maxAnalyticDate = (new Date(calculateTempAnalyticKey())).getTime()

        var filteredDatesObj = {}
        //filter the currentSquarePngDates to only include dates between minAnalyticDateId and maxAnalyticDateId
        Object.keys(currentSquarePngDates)
              .map(dateId => {
                return {
                  "dateId": dateId,
                  "time": (new Date(dateId)).getTime()
                }
              })
              .filter(obj => obj.time >= minAnalyticDate && obj.time < maxAnalyticDate)
              .forEach(obj => {
                filteredDatesObj[obj.dateId] = currentSquarePngDates[obj.dateId]
              })

        renderDateSlider(filteredDatesObj)
      }else{
        renderDateSlider(currentSquarePngDates)
      }

      renderDateSliderStyle(currentSquareRef.current)

    }

  },[currentSquarePngDates, currentSquare, processingAnalyticId])

  useEffect(() => {    
    if(currentSquare == null){
      clearSquareState()
    }else{
      setVisibleLotsArray([])
      setVisibleSquaresArray([])
    }
  },[currentSquare])


  

  const onStreetViewLoad = useCallback((streetViewPanorama) => {
    // Check if the user is in Street View mode
    setMapIsStreetViewVisible(streetViewPanorama.getVisible());

    // Listen for changes to the visibility of Street View
    streetViewPanorama.addListener("visible_changed", () => {
      setMapIsStreetViewVisible(streetViewPanorama.getVisible());
      setMapPanoramaIsDragging(false)
    });
    

  }, []);

 
  function init() {

    initReviewMode()
    
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }

  }



  function initReviewMode(){

    if(reviewMode == "reviewMode"){
      getAllLots()
      getAllSquares()
    }else if(reviewMode == "taskMode" || reviewMode == "validateMode"){
      //get task element from firestore
      const taskRef = firestore.collection('Operations').doc('LotOperations').collection('AnalyticRequests').doc(propsTaskId)
      taskRef.get().then(async (doc) => {
        if (doc.exists) {
          const taskData = doc.data()
          taskObjectRef.current = taskData
          setTaskObject(taskData)
          if(taskData?.manualProcessingObj?.manualProcessingComplete == true){
            createAlert("info", "This task has already been reviewed", 5000)
          }
          if(taskData?.manualProcessingObj?.squareStatus?.[propsSquareId]?.reviewers?.[activeUserId]?.active == true){
            const reviewObject = taskData?.manualProcessingObj?.squareStatus?.[propsSquareId]?.reviewers?.[activeUserId] 
            taskReviewObjectRef.current = reviewObject
            setTaskReviewObject(reviewObject)
            const indexOfKey = taskData?.requiredSquares?.map(e => e.replaceAll(".","")).indexOf(propsSquareId)
            taskReviewIndexOfKeyRef.current = indexOfKey
            setTaskReviewIndexOfKey(indexOfKey)
            try{
              await getTaskLot(taskData.lotId)
              await getTaskSquare(propsSquareId)
              try{
                panToBounds(squaresRef.current[propsSquareId].bounds)
              }catch(err){
              }
              await selectSquare(propsSquareId)
              const taskDateObj = squarePngImageryRef.current[propsSquareId][taskData?.requiredDates?.[taskData?.requiredDates.length - 1]]
              const taskDateMilliseconds = taskDateObj.displayDate.getTime()
              await handleDateSliderChange(taskDateMilliseconds)

              const compositeSquareDateRangeId = `${propsSquareId}-${taskData?.dateRangeId}`
              selectAnalytic(propsSquareId, compositeSquareDateRangeId)


            }catch(err){  
              console.error(err)
              //redirect to the task page
              //throw new Error("Error getting task data")
            }

          }else{
            throw new Error("User does not have access to this task")
          }
        } else {
          //redirect to the task page
          throw new Error("Task does not exist")
        }
      }).catch((error) => {

        createAlert('error', error.message ? error.message:"There was an error", 5000)
        //redirect to the task page
        navigate('/Tasking')
      });

    }
  }
  
  function handleKeyDown(event){

    const activeElement = document.activeElement;

    const isInputField = 
        activeElement.tagName === 'INPUT' || 
        activeElement.tagName === 'TEXTAREA' || 
        activeElement.isContentEditable;

    if(isInputField){
      return
    }

    try{
      if(event.keyCode == 87){
        //w key
        changeSelectedImgType("png")
      }else if(event.keyCode == 69){
        //e key
        changeSelectedImgType("gif")
      }else if(event.keyCode == 82){
        //r key
        changeSelectedImgType("gmapSat")
      }else if(event.keyCode == 83){
        //s key
        changeSelectedImgType("png")
        //set the date to the first date in the dateSliderMarks
        handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current.marks, calculateTempAnalyticKey(), -11))
      }else if(event.keyCode == 68){
        //d key
        changeSelectedImgType("png")
        //set the date to the middle date in the dateSliderMarks
        handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current.marks, calculateTempAnalyticKey(), -6))       
      }else if(event.keyCode == 70){
        //f key
        changeSelectedImgType("png")
        //set the date to the last date in the dateSliderMarks
        handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current.marks, calculateTempAnalyticKey(), 0))
      }else if(event.keyCode == 37){
        //left arrow key
        //check if there is a previous date in the dateSliderMarks
        const currentIndex = dateSliderMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
        if(currentIndex > 0){
          handleDateSliderChange(dateSliderMarksRef.current?.marks[currentIndex - 1].value)
        }
        changeSelectedImgType("png")
      }else if(event.keyCode == 39){
        //right arrow key
        //check if there is a next date in the dateSliderMarks
        const currentIndex = dateSliderMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
        if(currentIndex < dateSliderMarksRef.current?.marks?.length - 1){
          handleDateSliderChange(dateSliderMarksRef.current?.marks[currentIndex + 1].value)
        }
        changeSelectedImgType("png")
      }else if(event.keyCode == 67){
        //c key
        toggleSelectedToolType()
      }else if(event.keyCode == 84){
        //t key
        toggleShowAnalytics()
      }else if(event.keyCode == 32){
        //space key
        centerOnLargestUnreviewedSquare()
      }else{
        // console.log(event.keyCode)
      }
    }catch(err){
      console.error(err)
    }

  }

  function changeSelectedImgType(_type){

    switch(_type){
      case "png":
        setCurrentSquareSelectedImageryType("png")
        break;
      case "gif":
        setCurrentSquareSelectedImageryType("png")
        setTimeout(() => {
          setCurrentSquareSelectedImageryType("gif")        
        }, 1);
        break;
      case "gmapSat":
        setCurrentSquareSelectedImageryType(null)
        toggleGoogleMapType("satellite")
        break;
      case "none":
        setCurrentSquareSelectedImageryType(null)
      default:
        setCurrentSquareSelectedImageryType(null)
        break;
    }
  }

  function toggleShowAnalytics(){

    if(showAnalyticsRef.current == true){
      showAnalyticsRef.current = false
      setShowAnalytics(false)
    }else{
      showAnalyticsRef.current = true
      setShowAnalytics(true)
    }
  }

  function toggleSelectedToolType(){
    console.log("toggle")
    if(currentSquareSelectedToolTypeRef.current == "clicker"){
      changeSelectedToolType("paintBucket")
    }
    // else if(currentSquareSelectedToolType == "brush"){
    //   changeSelectedToolType("paintBucket")
    // }
    else if(currentSquareSelectedToolTypeRef.current == "paintBucket"){
      changeSelectedToolType("polygonTool")
    }else if(currentSquareSelectedToolTypeRef.current == "polygonTool"){
      changeSelectedToolType("clicker")
    }else{
      changeSelectedToolType("clicker")
    }
  }
  
  function changeSelectedToolType(_type){

    //clear state
    polygonToolPolygonRef.current = null
    polygonToolPolygonPolygonRef.current = null
    polygonToolPolygonPolylineRef.current = null
    setPolygonToolPolygon(null)


    switch(_type){
      case "clicker":
        currentSquareSelectedToolTypeRef.current = "clicker"
        setCurrentSquareSelectedToolType("clicker")
        break;

      case "brush":
        currentSquareSelectedToolTypeRef.current = "brush"
        setCurrentSquareSelectedToolType("brush")
        break;

      case "paintBucket":
        currentSquareSelectedToolTypeRef.current = "paintBucket"
        setCurrentSquareSelectedToolType("paintBucket")
        break;
      case "polygonTool":
        currentSquareSelectedToolTypeRef.current = "polygonTool"
        setCurrentSquareSelectedToolType("polygonTool")
        break;      
      default:
        currentSquareSelectedToolTypeRef.current = "clicker"
        setCurrentSquareSelectedToolType("clicker")
        break;
    }
  }

  function getLargestUnreviewedSquares(){
    var largetstKey = null
    var largestArea = 0
    Object.keys(largestMLGroupRef.current).forEach(key => {
      if(largestMLGroupRef.current[key].length > largestArea){
        largetstKey = key
        largestArea = largestMLGroupRef.current[key].length
      }
    })
    return largestMLGroupRef.current[largetstKey]
  }

  function centerOnLargestUnreviewedSquare(){
    try{
      const squaresArray = getLargestUnreviewedSquares()

      //find the smallest and largest x and y values
      var minX = null
      var maxX = null
      var minY = null
      var maxY = null

      squaresArray.forEach(squareId => {
        const x = parseInt(squareId.split("-")[0])
        const y = parseInt(squareId.split("-")[1])

        if(minX == null || x < minX){
          minX = x
        }
        if(maxX == null || x > maxX){
          maxX = x
        }
        if(minY == null || y < minY){
          minY = y
        }
        if(maxY == null || y > maxY){
          maxY = y
        }
      })


      //get the current square metadata to get the bounds
      const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData
      const squareLatLngs = metaData.squareLatLngs

      const north = squareLatLngs.lats[parseInt(minY)]
      const south = squareLatLngs.lats[parseInt(maxY)+5]
      const east = squareLatLngs.lngs[parseInt(maxX)+5]
      const west = squareLatLngs.lngs[parseInt(minX)]
      
      const bounds = {
        north: north,
        south: south,
        east: east,
        west: west
      }

      panToBounds(bounds)

    }catch(err){
      console.error(err)
      createAlert("error", "There was an error centering on the largest unreviewed square")
    }

  }

  function calculateTempAnalyticKey(){
    var tempAnalyticKey = processingAnalyticIdRef.current?.split("-")?.slice(3,6).join("-") ?? null
    
    return tempAnalyticKey
  
  }

  function renderDateSliderStyle(_lotId){
    

    setDateSliderMarksLoadStateStyle(calculateDateMarkStyles(dateSliderMarksRef.current?.marks, dateSliderMarksLoadStateRef.current[_lotId], calculateTempAnalyticKey(), theme))
    
  }

  function updateDateSliderMarksLoadState(_lotId, _squareId, _dateId){

    //convert 

    if(dateSliderMarksLoadStateRef.current[_lotId] == null){
      dateSliderMarksLoadStateRef.current[_lotId] = {}
    }
    if(dateSliderMarksLoadStateRef.current[_lotId][_squareId] == null){
      dateSliderMarksLoadStateRef.current[_lotId][_squareId] = {}
    }
    dateSliderMarksLoadStateRef.current[_lotId][_squareId][_dateId] = true


    renderDateSliderStyle(_lotId)

  }

  function panToBounds(_bounds){
    //ensure west is less than east
    if(_bounds.west > _bounds.east){
      const temp = _bounds.west
      _bounds.west = _bounds.east
      _bounds.east = temp
    }
    //ensure south is less than north
    if(_bounds.south > _bounds.north){
      const temp = _bounds.south
      _bounds.south = _bounds.north
      _bounds.north = temp
    }
    

    if(mapRef.current){
      mapRef.current.fitBounds(_bounds)
    }
  }

  function clearSquareState(){
    currentSquareRef.current = null
    currentSquarePngDateObjectRef.current = null
    currentSquareLotOverlapRef.current = null
    currentSquareObjectRef.current = null
    dateSliderMarksRef.current = null
    dateSliderValueRef.current = null
    currentSquarePngDatesRef.current = null
    setCurrentSquareLotMask(null)
    setCurrentSquareObject(null)
    setCurrentSquarePngDates(null)
    setCurrentSquareVisiblePngs(null)
    setDateSliderMarks(null)
    setDateSliderMinDate(null)
    setDateSliderMaxDate(null)
    setDateSliderValue(null)
    setActiveDateObject(null)
    setCurrentSquarePngDateObject(null)
    changeSelectedImgType("png")
    clearProcessingAnalyticState()
    setMapPanoramaIsDragging(false)
  }

  function clearProcessingAnalyticState(){
    processingAnalyticChangesRef.current = null
    processingAnalyticIdRef.current = null
    processingAnalyticLastSavedState.current = null
    processingAnalyticIdShorthandRef.current = null
    setProcessingAnalyticId(null) 
    setProcessingAnalyticIdShorthand(null)
    setProcessingAnalyticSaveNecessary(false)
    changeSelectedImgType("png")
    setProcessingAnalyticChanges(null)
    setMapPanoramaIsDragging(false)

    //foreach processingAnalyticGrid, set the state to null
    Object.keys(processingAnalyticGrid).forEach((key) => {
      processingAnalyticGrid[key][1](null)
    })

    renderDateSliderStyle(currentSquareRef.current)
  }

  function openPublishDialog(_open){
    if(_open){      
      const date = new Date()
      //add 5 seconds to the current date
      date.setSeconds(date.getSeconds() + 5.99)
      setPublishChangesDialogOpenTime(date.getTime())
      if(reviewMode == "taskMode"){
        setSubmitChangesDialogOpen(true)
      }else{
        setPublishChangesDialogOpen(true)

      }
    }else{
      setPublishChangesDialogOpen(false)
      setSubmitChangesDialogOpen(false)
      setPublishChangesDialogOpenTime(null)
    }
  }

  function openCompleteAnalyticProcessingDialog(_open){

    
    
    if(_open){
      const date = new Date()
      //add 5 seconds to the current date
      date.setSeconds(date.getSeconds() + 3.99)
      setCompleteAnalyticProcessingDialogOpenTime(date.getTime())

      setCompleteAnalyticProcessingDialogOpen(true)
    }else{
      setCompleteAnalyticProcessingDialogOpen(false)
      setCompleteAnalyticProcessingDialogOpenTime(null)
    }

  }

  function mapHandleOnLoad(map) {
    mapRef.current = map;    

    initPegmanListener()

  };

  function initPegmanListener(){

    pegmanListerTimeoutCount.current += 1
    // Select all elements with the class "gm-svpc"
    const elements = document.querySelectorAll('.gm-svpc');
    if(elements.length == 0 && pegmanListerTimeoutCount.current < 20){      
      setTimeout(() => {
        initPegmanListener()
      }, 500);      
    }else{

      // Add event listeners to each element
      elements.forEach(element => {
        element.addEventListener('mousedown', () => {
          setMapPanoramaIsDragging(true)       
        });
      });
      // Cleanup function to remove event listeners when component unmounts
      return () => {
        elements.forEach(element => {          
          element.removeEventListener('mousedown', () => {     

          });
        });
      };
    }
  }

  function requestViewportRender(){
    if (lastViewportRenderTimeoutRef.current) {
      clearTimeout(lastViewportRenderTimeoutRef.current)
    }
    if(Date.now() - lastViewportRenderRef.current > 250){
      renderViewport()
    }else{
      lastViewportRenderTimeoutRef.current = setTimeout(() => {
        renderViewport()
        lastViewportRenderTimeoutRef.current = null
      }, 250)
    }
  }

  function renderViewport(){
    try{
      lastViewportRenderRef.current = Date.now()

      if (mapRef.current) {

        const bounds = mapRef.current.getBounds()
        const northWest = bounds.getNorthEast()
        const southEast = bounds.getSouthWest()

        var tempVisibleLotsArray = []
        var tempVisibleSquaresArray = []

        if(currentSquareRef.current == null){
          
          tempVisibleSquaresArray = Object.keys(squaresRef.current).filter(key => {
            const square = squaresRef.current[key]
            //if any of the bounds are within the viewport
            if(square.bounds.south < northWest.lat() && square.bounds.north > southEast.lat() && square.bounds.east > southEast.lng() && square.bounds.west < northWest.lng()){
              return true
            }else{
              return false
            }          
          })

          tempVisibleLotsArray = Object.keys(lotsRef.current).filter(key => {
            const lot = lotsRef.current[key]
            //if any of the bounds are within the viewport
            if(lot.bounds.south < northWest.lat() && lot.bounds.north > southEast.lat() && lot.bounds.east > southEast.lng() && lot.bounds.west < northWest.lng()){
              return true
            }else{
              return false
            }          
          })

          // only rerender if the visibleLotsArray has changed
          if(JSON.stringify(visibleLotsArray) !== JSON.stringify(tempVisibleLotsArray)){
            setVisibleLotsArray(tempVisibleLotsArray)
          }

          if(JSON.stringify(visibleSquaresArray) !== JSON.stringify(tempVisibleSquaresArray)){
            setVisibleSquaresArray(tempVisibleSquaresArray)
          }
          
        }else{
                    
          




        }
        
        
      }
    }catch(err){
      console.error(err)
    }

    
  }

  function mapHandleBoundsChanged(){
    try{
      if (mapRef.current) {
        requestViewportRender()
      }
    }catch(err){
      console.error(err)
    }
  }

  function getTaskLot(_lotId){
    return new Promise((resolve, reject) => {
      var ref = firestore.collection('Operations').doc('DataLookupTables').collection('Lots').doc(_lotId);
      ref.get().then((doc) => {
        const tempLots = {}

        if(doc.exists){
          tempLots[doc.id] = doc.data()
          tempLots[doc.id].bounds = calculatePolygonBounds(doc.data().polygon)
        }

        lotsRef.current = tempLots
        //requestViewportRender()
        resolve()

      })
      .catch((error) => {
        console.error(error)
        createAlert('error', error)
        reject()
      });
    })

  }

  function getTaskSquare(_squareId){
    return new Promise((resolve, reject) => {
      //check to see if the _squareId folder exists in the AdminData folder
      const detectedChangesRef = ref(storage, `AdminData/${_squareId}`)
      listAll(detectedChangesRef).then((res) => {
        //if there is content in the folder then the square exists
        if(res.prefixes.length > 0){

          var tempSquareElements = {}
          tempSquareElements[_squareId] = createAdminSquareFromId(_squareId)
          squaresRef.current = tempSquareElements
          //requestViewportRender()
          resolve()

        }else{
          createAlert('error', 'The selected square does not exist')
          reject()
        }
      })
      .catch((error) => {
        console.error(error)
        createAlert('error', error)
        reject()
      });
    })
  }

  function getAllLots(){
    //get all the lots from Operations/DataLookupTables/Lots
    var ref = firestore.collection('Operations').doc('DataLookupTables').collection('Lots');
    ref.get().then((querySnapshot) => {
      const tempLots = {}
      querySnapshot.forEach((doc) => {
        tempLots[doc.id] = doc.data()
        tempLots[doc.id].bounds = calculatePolygonBounds(doc.data().polygon)

      });
      lotsRef.current = tempLots
      requestViewportRender()

    })
    .catch((error) => {
      console.error(error)
      createAlert('error', error)
    });
  }

  function getAllSquares(){
    
    //check which folders exist in the AdminData folder
    const detectedChangesRef = ref(storage, `AdminData`)
    // get all the folders in the detectedChangesRef
    listAll(detectedChangesRef).then((res) => {
      var tempSquareElements = {}
      res.prefixes.forEach(prefix => {
        tempSquareElements[prefix.name] = createAdminSquareFromId(prefix.name)
      });
      squaresRef.current = tempSquareElements
      requestViewportRender()
    })
    .catch((error) => {
      console.error(error)
      createAlert('error', error)
    });

  }

  function getSquareGifStatus(_squareId){
    return new Promise((resolve, reject) => {
      if(_squareId != null){
        if(squareGifImageryRef.current[_squareId] == null){
          squareGifImageryRef.current[_squareId] = {}

          //list all the files in the square/Pngs folder
          const adminGifsRef = ref(storage, `AdminData/${_squareId}/Imagery/Gifs`)
          listAll(adminGifsRef).then((res) => {      
            const promiseArray = []
            const itemRefArray = []
            res.prefixes.forEach((prefixRef) => {
              const analyticId = prefixRef.fullPath.split("/")[prefixRef.fullPath.split("/").length - 1]
              squareGifImageryRef.current[_squareId][analyticId] = {
                elementUrls: {},
                loaded: false
              }              
            }) 
            setSquareGifImagery(squareGifImageryRef.current)
            resolve()         
          })
          .catch((error) => {
            console.error(error)          
            reject()
          })
        }
      }else{
        resolve()
      }
    })
  }

  function getSquareGifImagery(_squareId, _analyticId){
    
    if(squareGifImageryRef.current?.[_squareId]?.[_analyticId]?.loaded == false){

      //calcuate overlap object 

      
      const square = squaresRef.current[_squareId]
      const overlappingLotPolygons = Object.values(lotsRef.current).filter(lot => {
        // check if bounds intersect with square bounds
        if(lot.bounds.south < square.bounds.north && lot.bounds.north > square.bounds.south && lot.bounds.east > square.bounds.west && lot.bounds.west < square.bounds.east){
          return true
        }else{
          return false
        }
      }).map(lot => lot.polygon)

      const overlapObj = calculateSquareLotsOverlapMask(square.polygon, overlappingLotPolygons)
      var squareOverlapObj = null
      //if the type is a multipolygon, set the mask to the multipolygon
      if(overlapObj.geometry.type == "MultiPolygon"){
        squareOverlapObj = overlapObj.geometry.coordinates.map(coords => coords[0])
      }else if(overlapObj.geometry.type == "Polygon"){
        squareOverlapObj = overlapObj.geometry.coordinates
      }


      //only displays gifsthat intersect with the active lots in the square
      var visibleGifs = []
      for (let index = 0; index < numberOfGifSections; index++) {
        var intersects = true;
        try{
          //north and south are calculated as the bounds of the square but the index  between 0 and 9 is used to determine the bounds of the gif
          const offsetAmount = squareSize / numberOfGifSections     
          const north = currentSquareObjectRef.current.bounds.north - (offsetAmount * index)
          const south = north - offsetAmount
          const west = currentSquareObjectRef.current.bounds.west
          const east = currentSquareObjectRef.current.bounds.east              

          //create turf polygon from bounds
          const squarePolygonArray = [[west, north],[east, north],[east, south],[west, south],[west, north]]
          intersects = calculatePolygonsIntersect(squarePolygonArray, squareOverlapObj)
        }catch(err){
          console.error(err)
        }

        if(intersects){
          visibleGifs.push(index)
        }

      }

      const adminGifsRef = ref(storage, `AdminData/${_squareId}/Imagery/Gifs/${_analyticId}`)
      listAll(adminGifsRef).then((res) => {      
        const promiseArray = []
        const itemRefArray = []
        res.items.forEach((itemRef) => {
          //get the ddwnload url for the item
          promiseArray.push(getDownloadURL(itemRef))
          itemRefArray.push(itemRef)
        })

        Promise.all(promiseArray).then((downloadElements) => {
          var gridUrlObj = {}
          downloadElements.forEach((url, index) => {
            try{
              const itemRef = itemRefArray[index]
              const gridId = itemRef.name.split('.')[0].split('-')[itemRef.name.split('.')[0].split('-').length - 1]
              if(visibleGifs.includes(parseInt(gridId))){
                gridUrlObj[gridId] = url
              }
            }catch(err){
              console.error(err)
            }
          })
          squareGifImageryRef.current[_squareId][_analyticId].elementUrls = gridUrlObj
          squareGifImageryRef.current[_squareId][_analyticId].loaded = true
          setSquareGifImagery(squareGifImageryRef.current)          
        })
      })
    }
  }

  function getSquarePngImagery(_squareId){
    return new Promise((resolve, reject) => {
      if(_squareId != null){
        //if the square imagery has already been loaded do not get it again
        if(squarePngImageryRef.current[_squareId] == null){
        
          squarePngImageryRef.current[_squareId] = {}

          //list all the files in the square/Pngs folder
          const adminPngsRef = ref(storage, `AdminData/${_squareId}/Imagery/Pngs`)
          listAll(adminPngsRef).then((res) => {      
            const promiseArray = []
            const itemRefArray = []
            res.items.forEach((itemRef) => {
              //get the ddwnload url for the item
              promiseArray.push(getDownloadURL(itemRef))
              itemRefArray.push(itemRef)
            })

            Promise.all(promiseArray).then((downloadElements) => {

              downloadElements.forEach((url, index) => {

                const itemRef = itemRefArray[index]

                const squareDateId = itemRef.name.split('.')[0]
                const squareId = _squareId

                const dateId = squareDateId.split(squareId)[1].slice(1)
                
                const endYear = dateId.split('-')[3]
                const endMonth = dateId.split('-')[4]
                const endDay = dateId.split('-')[5]

                const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay))
                const endDateString = formatDateAsId(endDate)

                const displayDate = new Date(Date.UTC(endYear, endMonth - 2, endDay))
                const displayDateId = formatDateAsId(displayDate)

                squarePngImageryRef.current[_squareId][endDateString] = {
                  url: url,
                  squareId: squareId,
                  dateId: endDateString,
                  dateRange: dateId,
                  endDateId: formatDateAsId(endDate),      
                  displayDateId: displayDateId,
                  endDate: endDate,
                  displayDate: displayDate,
                  analytics: {
                    "monthly": dateIdToAnalyticsRequestId(squareId, "monthly", dateId),
                  },
                  gifs: {
                    "monthly": dateIdToAnalyticsRequestIdShorthand("monthly", dateId)
                  },
                  loaded: false
                }

              })

              currentSquarePngDatesRef.current = squarePngImageryRef.current[_squareId]
              setCurrentSquarePngDates(currentSquarePngDatesRef.current) 
              resolve()

            }).catch((error) => {
              console.error(error)   
              reject()         
            });

          })
          .catch((error) => {
            console.error(error)          
            reject()
          })

        }else{        
          if(typeof squarePngImageryRef.current[_squareId] == 'object'){        
            currentSquarePngDatesRef.current = squarePngImageryRef.current[_squareId]
            setCurrentSquarePngDates(currentSquarePngDatesRef.current)
            resolve()
          }else{
            reject()
          }
        }
      }else{
        resolve()
      }
    })    
  }

  function getSquareAnalyticsData(_squareId, _analyticRequestId, _urlObject){

    var numberOfElements = {
      total: Object.keys(_urlObject).length,
      success: 0,
      error: 0
    }
    
    const promiseArray = []
    const promiseKeyArray = []
    try{
      Object.keys(_urlObject).forEach(key => {
        const keyString = `${key}`

        fetch(_urlObject[keyString])
        .then(response => response.json())
        .then(data => {
    
          if(data?.metaData?.squareLatLngs != null){
            //generate an array from the squareLatLngs object
            data.metaData.squareLatLngs.latArray = Object.values(data.metaData.squareLatLngs.lats)
            data.metaData.squareLatLngs.lngArray = Object.values(data.metaData.squareLatLngs.lngs)
          }
    
          squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"][key] = data
          
          numberOfElements.success += 1
          numberOfElements.total -= 1
    
          
        }).catch(error => {
              numberOfElements.error += 1
              numberOfElements.total -= 1      
              console.error(error)      
        }).finally(() => {
          if(numberOfElements.total == 0){
            //if the currentUserId is not in the _urlObject then make a copy of the Default
            if(_urlObject[activeUserId] == null && squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"]["Default"] != null){
              const objectCopy = Object.assign({}, JSON.parse(JSON.stringify(squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"]["Default"])))
              //update user specific variables
              objectCopy.metaData.displayName = user.displayName
              objectCopy.metaData.createdBy = activeUserId
              objectCopy.dateUpdated = (new Date()).getTime()
              
              //clear redundant data
              objectCopy.changes = {}
              delete objectCopy.meanChangeArray
              delete objectCopy.metaData.squareLatLngs
              squareAnalyticsRef.current[_squareId][_analyticRequestId]["data"][activeUserId] = objectCopy
            }
    
    
            //all elements have been processed
            createAlert('success', numberOfElements.error > 0 ? `Analytics Downloaded: ${numberOfElements.success} Success, ${numberOfElements.error} Error`: `Analytics Downloaded: ${numberOfElements.success} Success`)
            selectAnalytic(_squareId, _analyticRequestId) 
            setSquareAnalytics(squareAnalyticsRef.current)

          }
        })
      })
    }catch(err){
      console.error(err)
      createAlert("error", "Error pulling analytic data")
    }

  }

  function getSquareAnalyticsStatus(_squareId, _analyticRequestId){
    return new Promise(async (resolve, reject) => {
      try{
        if(_squareId != null && _analyticRequestId != null){
          //if the square analytics has already been loaded do not get it again
          if(squareAnalyticsRef.current[_squareId] == null){
            squareAnalyticsRef.current[_squareId] = {}
          }

          if(squareAnalyticsRef.current[_squareId][_analyticRequestId] == null){

            var updateObj = {
              urls: {},
              exists: false,
              data: null
            }

            const analyticId = _analyticRequestId.substring(14, _analyticRequestId.length)
            //check if the default analytic exists
            const adminDataFolder = `AdminData/${_squareId}/Analytics/DetectedChanges/${analyticId}`          
            // if it exists then list all of the files in the folder and get their urls
            const res = await listAll(ref(storage, adminDataFolder))
            //iterate over all the items in the folder
            const promiseArray = []
            const promiseKeyArray = []
            

            res.items.forEach(async (itemRef) => {
              //get the name and the url of the item
              const name = itemRef.name.split('.')[0].split('-')[itemRef.name.split('.')[0].split('-').length - 1]
              promiseArray.push(getDownloadURL(itemRef))       
              promiseKeyArray.push(name)
            })

            Promise.allSettled(promiseArray).then((promises) => {

              //foreach response
              promises.forEach((promise, index) => {
                if(promise.status == "fulfilled" && typeof promise?.value == "string"){
                  updateObj.urls[promiseKeyArray[index]] = promise.value
                  updateObj.exists = true
                }
              })
              squareAnalyticsRef.current[_squareId][_analyticRequestId] = updateObj;
              setSquareAnalytics(squareAnalyticsRef.current)
              resolve()
            })
          }else{
            resolve()
          }
        }else{
          resolve()
        }
      }catch(err){
        console.error(err)
        reject()
      }
    })
  }

  function renderDateSlider(_currentSquarePngDates){

    if(_currentSquarePngDates != null){
      const dateArray = Object.values(_currentSquarePngDates).map(dateObj => dateObj.endDate.getTime())
      const minDate = Math.min(...dateArray)
      const maxDate = Math.max(...dateArray)
      setDateSliderMinDate(minDate)
      setDateSliderMaxDate(maxDate)

      const timeMappedDataObj = {
        [currentSquareRef.current]: {
          timeData: _currentSquarePngDates
        }
      }

      const tempDateSliderMarks = calculateDateSliderMarks(timeMappedDataObj, "monthly")

      dateSliderMarksRef.current = tempDateSliderMarks
      setDateSliderMarks(tempDateSliderMarks)

      //if the dateSliderValue is null, or the value is not one of the dates in the currentSquarePngDates object, set the value to the maxDate
      if(dateSliderValue == null || !dateArray.includes(dateSliderValue)){
        handleDateSliderChange(maxDate)
      }else{
        //if changing from one square to another but with the same date get the new squares info using the dateslider change
        handleDateSliderChange(dateSliderValue)
      }

    }

  }

  function selectSquare(_squareId){
    return new Promise(async (resolve, reject) => {
      //only update the selected square if it's different than the current and the user is not processing an analytic
      if(_squareId != currentSquareRef.current && processingAnalyticIdRef.current == null){

        //resets everything
        setCurrentSquare(null)
        
        //instantiates the square
        currentSquareRef.current = _squareId
        setCurrentSquare(_squareId)
        const square = squaresRef.current[_squareId]

        if(_squareId != null && square != null){
          
          const overlappingLotPolygons = Object.values(lotsRef.current).filter(lot => {
            // check if bounds intersect with square bounds
            if(lot.bounds.south < square.bounds.north && lot.bounds.north > square.bounds.south && lot.bounds.east > square.bounds.west && lot.bounds.west < square.bounds.east){
              return true
            }else{
              return false
            }
          })
          .map(lot => lot.polygon)

          var squareOverlapObj = null
          var squareNonOverlapObj = null

          const overlapObj = calculateSquareLotsOverlapMask(square.polygon, overlappingLotPolygons)
          const nonOverlapObj = calculateSquareLotsOverlap(square.polygon, overlappingLotPolygons)
          

          //if the type is a multipolygon, set the mask to the multipolygon
          if(overlapObj.geometry.type == "MultiPolygon"){
            squareOverlapObj = overlapObj.geometry.coordinates
          }else if(overlapObj.geometry.type == "Polygon"){
            squareOverlapObj = [overlapObj.geometry.coordinates]
          }

          if(nonOverlapObj != null){
            if(nonOverlapObj.geometry.type == "MultiPolygon"){
              squareNonOverlapObj = nonOverlapObj.geometry.coordinates
            }else if(nonOverlapObj.geometry.type == "Polygon"){
              squareNonOverlapObj = [nonOverlapObj.geometry.coordinates]
            }      

            setCurrentSquareLotMask(squareNonOverlapObj)
          }
          currentSquareLotOverlapRef.current = squareOverlapObj
          currentSquareObjectRef.current = square
          setCurrentSquareObject(square)


          await getSquarePngImagery(_squareId)
          await getSquareGifStatus(_squareId)
          
        }

        requestViewportRender()
        resolve()
      }else{
        resolve()
      }
    })
  }

  function toggleGoogleMapType(_type = null){

    if(_type == 'roadmap' || _type == 'satellite'){
      setMapTypeId(_type)
    }else{      
      setMapTypeId(prev => prev === 'satellite' ? 'roadmap' : 'satellite');
    }
  }
  
  function handleDateSliderChange(_value){

    return new Promise(async (resolve, reject) => {
      try{
        //find the element in the squareImageryRef.current[selectedSquareRef] that has the displayDate.getTime() == _value
        const selectedSquarePngDates = squarePngImageryRef.current[currentSquareRef.current]
        if(selectedSquarePngDates == null){
          resolve()
        }
        const selectedSquarePngDatesArray = Object.values(selectedSquarePngDates)
        const selectedSquarePngDate = selectedSquarePngDatesArray.find((dateObj) => dateObj.endDate.getTime() == _value)

        if(selectedSquarePngDate != null){

          try{
            if(selectedSquarePngDate != null){
            
              currentSquarePngDateObjectRef.current = selectedSquarePngDate
              setCurrentSquarePngDateObject(currentSquarePngDateObjectRef.current)
                       
              squarePngImageryRef.current[currentSquareRef.current][selectedSquarePngDate.dateId].loaded = true
              
              const tempCurrentSquareVisiblePngs = Object.values(squarePngImageryRef.current[currentSquareRef.current]).filter((dateObj) => {
                return dateObj.loaded
              })

              //check if the state has changed
              if(JSON.stringify(currentSquareVisiblePngs) !== JSON.stringify(tempCurrentSquareVisiblePngs)){
                setCurrentSquareVisiblePngs(tempCurrentSquareVisiblePngs)
              }

              //check for analytics if not currently processing one
              if(processingAnalyticId == null){
                await getSquareAnalyticsStatus(currentSquareRef.current, selectedSquarePngDate.analytics.monthly)
              }
              dateSliderValueRef.current = _value
              setDateSliderValue(_value)
              setActiveDateObject(currentSquareVisiblePngs?.find((element) => element.endDate.getTime() == dateSliderValue))
              getSquareGifImagery(currentSquare)
              setTimeout(() => {
                setRefreshKey(refreshKey + 1)
                
              }, 500);
              resolve()
            }
          }catch(err){
            console.error(err)
            createAlert('error', "Error setting date")
            resolve()
          }

        }else{
          createAlert('error', 'Date not found')
          resolve()
        }
      }catch(err){
        console.error(err)
        createAlert('error', 'Error setting date')
        resolve()
      }
    });

  }

  if(!isLoaded){
    return <>
          <div className='pageContent'>       
            <div className='adminMapContainerDiv'> 
              
            </div>
          </div>
          </>
  }


  function stackAnalyticFiles(_dataObject){
    var tempChanges = {}
    try{
      tempChanges = Object.assign({}, JSON.parse(JSON.stringify(_dataObject?.Default?.changes)))
    }catch(err){
      console.error(err)
    }
    try{          
      //if there is a key for the userId then iterate over the changes and stack them
      Object.entries(_dataObject?.[activeUserId]?.changes).forEach((entry) => {
        const key = entry[0]
        const value = entry[1]

        tempChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
      })
    }catch(err){
      console.error(err)
    }
    try{          
      if(_dataObject?.["Official"]?.changes != null){
        //if there is a key for the userId then iterate over the changes and stack them
        Object.entries(_dataObject?.["Official"]?.changes).forEach((entry) => {
          const key = entry[0]
          const value = entry[1]
          tempChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
        })
      }
    }catch(err){
      console.error(err)
    }
    
    Object.keys(tempChanges).forEach((key) => {
      if(tempChanges[key].bounds == null){
        tempChanges[key].bounds = calculateProcessingGridSquareBounds(key, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)
      }
    })

    return tempChanges

  }

  function selectAnalytic(_squareId, _analyticRequestId){
    //verify the analytic exists in the squareAnalytics object
    if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.exists == true ){
      // if the data is null
      if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data == null){      
        squareAnalyticsRef.current[_squareId][_analyticRequestId].data = {}
        const urlObject = squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.urls
        getSquareAnalyticsData(_squareId, _analyticRequestId, urlObject)
      }else if(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data?.Default != null){


        processingAnalyticIdRef.current = _analyticRequestId

        //stack the data from the squareAnalytics data object 
        const tempChanges = stackAnalyticFiles(squareAnalyticsRef.current?.[_squareId]?.[_analyticRequestId]?.data)
        
        //TODO: if there is a key for the official changes then iterate over the changes and stack them
        processingAnalyticChangesRef.current = tempChanges
        setProcessingAnalyticChanges(tempChanges)
        
        
        //render the processing grid
        calculateProcessingGridMetaSquareAssignment(processingAnalyticChangesRef.current)
        setProcessingAnalyticId(processingAnalyticIdRef.current)
        const tempProcessingAnalyticShorthand = processingAnalyticIdRef.current.substring(14, processingAnalyticIdRef.current.length)
        processingAnalyticIdShorthandRef.current = tempProcessingAnalyticShorthand
        setProcessingAnalyticIdShorthand(tempProcessingAnalyticShorthand)

        getSquareGifImagery(_squareId, tempProcessingAnalyticShorthand)

        processingAnalyticLastSavedState.current = JSON.stringify(tempChanges)
        
        renderDateSliderStyle(_squareId)
        
      }else{
        createAlert('error', 'Error getting analytic data')
        clearSquareState()
      }
    }
  }

  function attemptToExitProcessing(){
    //save the current state of the square
    //exit the current state of the

    // if there are changes since last save
    if(JSON.stringify(processingAnalyticChangesRef.current) != processingAnalyticLastSavedState.current){
      setConfirmExitWithoutSavingDialogOpen(true)
    }else{
      setConfirmExitWithoutSavingDialogOpen(false)
      clearProcessingAnalyticState()

      if(reviewMode == "reviewMode"){
        //do nothing
      }else if(reviewMode == "taskMode"){
        navigate('/Tasking')
      }else if(reviewMode == "validateMode"){
        navigate('/Tasking')
      }

      
    }
  }

  async function saveProcessingAndExit(){

    saveProcessing(true, false)
    .then(() => {
      attemptToExitProcessing()
    })
    .catch((error) => {
      console.error(error)
    })
    

  }

  function getRectangleOptions(_color){
    return {
      fillColor: _color,
      strokeColor: _color,
      strokeWeight: 1,
      fillOpacity: .2,
      opacity: 1,
    }
  }

  function saveProcessing(_manual, _publish){
    setSavingChangesLoading(true)
    return new Promise((resolve, reject) => {
      try{
    
        const userChanges = {} 
        
        Object.entries(processingAnalyticChangesRef.current).forEach((entry) => {
          try{
            const key = entry[0]
            const value = entry[1]
            // if the entry has a set
            if(value.a != null){
              userChanges[key] = Object.assign({}, JSON.parse(JSON.stringify(value)))
              
                delete userChanges[key]?.bounds
              
            }
          }catch(err){
            console.error(err)
          }
        });

        squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current]['data'][activeUserId].changes = userChanges

        const analyticId = processingAnalyticIdRef.current.substring(14, processingAnalyticIdRef.current.length)
        //save the changes to the database
        const adminDataFolder = `AdminData/${currentSquareRef.current}/Analytics/DetectedChanges/${analyticId}` 

        const documentId = _publish ? "Official":activeUserId

        const detectedChangesUserUpdatedRef = ref(storage, `${adminDataFolder}/${processingAnalyticIdRef.current}-${documentId}.json`)

        const blob = new Blob([JSON.stringify(squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current]['data'][activeUserId])], {type: 'application/json'});

        //upload the training data to storage as a json file
        uploadBytes(detectedChangesUserUpdatedRef, blob)
        .then((snapshot) => {
          if(_manual){
            if(_publish){
              createAlert('success', "Changes Saved and Published")
            }else{
              createAlert('success', "Changes Saved")
            }
          }        
          processingAnalyticLastSavedState.current = JSON.stringify(processingAnalyticChangesRef.current)
          setProcessingAnalyticSaveNecessary(false)
          resolve("Changes saved")

        })
        .catch((error) => {
          console.error(error)
          if(_publish){
            createAlert('error', "There was an error saving and publishing")     
            reject("Error saving and publishing")   
          }else{
            createAlert('error', "There was an error saving the changes")        
            reject("Error saving changes")
          }
        })
        .finally(() => {
          setSavingChangesLoading(false)  
        });
      }catch(err){
        console.error(err)
        if(_publish){
          createAlert('error', 'Error saving changes')
        }
        setSavingChangesLoading(false)
        reject("Error saving changes")
      }
    })
  }

  function publishChanges(){
    
    saveProcessing(false, true)
    .then(() => {

      //submit a publish request to the database
      //operations/LotActions/PublishSquareChanges
      const publishRef = firebase.firestore().collection("Operations").doc("LotActions").collection("PublishSquareAnalytics")      

      publishRef.add({
        squareId: currentSquareRef.current,
        analyticId: processingAnalyticIdShorthandRef.current,
        userId: user.uid,
        createdBy: user.uid,
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        requestScope: "all",
        specificLots: [],
      })
      .then(() => {
        createAlert('success', 'Publish request submitted')
        //close the publish dialog
        openPublishDialog(false)

        //if the user is in validateMode then close the task
        if(reviewMode == "validateMode" || reviewMode == "taskMode"){
          setTimeout(() => {
            openCompleteAnalyticProcessingDialog(true)          
          }, 500);
        }

      })
      .catch((error) => {
        createAlert('error', 'Error submitting publish request')
        console.error(error)
      })
    })
    .catch((error) => {
      console.error(error)
      createAlert('error', 'Error saving changes')
    })


  }

  function submitChangesForReview(){

    saveProcessing(true, false)
    .then(() => {
      openPublishDialog(false)
      
      const taskRef = firebase.firestore().collection("Operations").doc("LotActions").collection("AnalyticRequestActions")

        const updateObject = {
            userId: user.uid,
            action: "submitChangesForReview",
            requestId: propsTaskId,
            squareId: propsSquareId,
            payload: {
                numberOfElements: Object.values(processingAnalyticChangesRef.current).filter(e => e.a == true).length,
                reviewer: {
                  uid: activeUserId,
                }
            },
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        }

        taskRef.add(updateObject)
        .then(() => {
            createAlert("success", "Changes submitted for review")
            navigate('/Tasking')
        })
        .catch((error) => {
            createAlert("error", "Error starting task")
            console.error("Error writing document: ", error);
        })
        .finally(() => {

        })

    })
    .catch((error) => {
      console.error(error)
      
    })

  }

  function exportImagery(){

    setAdminExportImageryLoading(true)

    const requiredDates = []
    //foreach mark
    dateSliderMarksRef.current?.marks.forEach((mark) => {
      //if the mark is within the start and end date
      if(mark.value >= exportImageryDataRef.current.startDate && mark.value <= exportImageryDataRef.current.endDate){
        //add the mark to the requiredImagery array
        requiredDates.push(mark?.dateElement?.dateRange)
      }
    })

    

    const exportObj = {
      action: "process",
      createdBy: user.uid,
      organizationId: orgObj?.selectedOrganization,      
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
      dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      squareId: currentSquareRef.current,
      requiredDates: requiredDates,
      displayName: exportImageryDataRef.current?.displayName ?? "Export",
      bounds: exportImageryDataRef.current.bounds,
      status: 'new',

    }

    //upload the export object to the database
    const exportRef = firebase.firestore().collection("Operations").doc("ImageryOperations").collection(exportObj.organizationId).doc("ExportImagery").collection("Requests")
    exportRef.add(exportObj)
    .then(() => {
      createAlert('success', 'Export request submitted')
      startExportImagery(false)
    })
    .catch((error) => {
      createAlert('error', error.message)
      console.error(error)  
    })
    .finally(() => {
      setAdminExportImageryLoading(false)
    })
  }

  function startExportImagery(_open){

    if(_open){
      

      //get index -12 from the end of the marks array
      var startIndex = dateSliderMarksRef.current?.marks?.length - 24
      //check if the index is less than 0
      if(startIndex < 0){
        startIndex = 0
      }
      const tempStartValue = dateSliderMarksRef.current?.marks?.[startIndex].value

      

      const tempEndValue = dateSliderMarksRef.current?.marks?.[dateSliderMarksRef.current?.marks?.length - 1].value
      
      exportImageryDataRef.current = {
        startDate: tempStartValue,
        endDate: tempEndValue,
      }

      setAdminExportImageryStartDate(tempStartValue)
      setAdminExportImageryEndDate(tempEndValue)
      setExportImageryDialogOpen(true)
    }else{
      exportImageryDataRef.current = {}
      setExportImageryDialogOpen(false)
      setExportImageryRectangle(null)
      setExportImageryArea(null)
      setAdminExportImageryDisplayName("")
      setAdminExportImageryAspectRatio(null)
    }
  }

  function updateAdminExportImageryDate(_date, _type){

    if(_type == "start"){
      exportImageryDataRef.current.startDate = _date
      setAdminExportImageryStartDate(_date)
      
      if(exportImageryDataRef.current.endDate == null){
        exportImageryDataRef.current.endDate = _date
        setAdminExportImageryEndDate(_date)
      }else if(new Date(_date) > new Date(exportImageryDataRef.current.endDate)){
        exportImageryDataRef.current.endDate = _date
        setAdminExportImageryEndDate(_date)
      }
    
    }else if(_type == "end"){
      exportImageryDataRef.current.endDate = _date
      setAdminExportImageryEndDate(_date)

      if(exportImageryDataRef.current.startDate == null){
        exportImageryDataRef.current.startDate = _date
        setAdminExportImageryStartDate(_date)
      }else if(new Date(_date) < new Date(exportImageryDataRef.current.startDate)){
        exportImageryDataRef.current.startDate = _date
        setAdminExportImageryStartDate(_date)
      }
    }

    
  }

  function closeAnalyticTask(){

    const taskRef = firebase.firestore().collection("Operations").doc("LotActions").collection("AnalyticRequestActions")
    const updateObject = {
      userId: user.uid,
      action: "closeAnalyticTask",
      requestId: propsTaskId,
      squareId: propsSquareId,
      payload: {},
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
  }

  taskRef.add(updateObject)
  .then(() => {
      createAlert("success", "Task closed")
      navigate('/Tasking')
  })
  .catch((error) => {
      createAlert("error", "Error closing task")
      console.error("Error writing document: ", error);
  })

  }

  function calculateProcessingGridMetaSquareFromPixelId(_pixelId){

    try{
      const xCord = parseInt(_pixelId.split('-')[0])
      const yCord = parseInt(_pixelId.split('-')[1])
      const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData
      const imgHeight = metaData.height
      const imgWidth = metaData.width

      //based on the image height and width and the processingGridSize divide the image into a grid where 00 is the top left corner and processingGridSize-1, processingGridSize-1 is the bottom right corner
      //return the grid key
      const xGridKey = Math.floor(xCord / (imgWidth / processingGridSize))
      const yGridKey = Math.floor(yCord / (imgHeight / processingGridSize))

      return `${xGridKey}${yGridKey}`
    }catch(err){
      console.error(err)
      return "00"
    }

  }


  function calculateProcessingGridMetaSquareAssignment(_dataObj){

    const tempGridObj = {}
    
    Object.entries(_dataObj).forEach((entry) => {
      const key = entry[0]
      const value = entry[1]
      const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(key)
      if(tempGridObj[renderGridKey] == null){

        tempGridObj[renderGridKey] = {}
      }
      tempGridObj[renderGridKey][key] = value
    })

    

    Object.keys(tempGridObj).forEach((key) => {
      renderProcessingGridSubSquare(key, tempGridObj[key])
    })

  }

  function renderProcessingGridSubSquare(_gridKey, _dataObj){
    try{

      procesingAnalyticGridRef.current[_gridKey] = _dataObj ?? {}

      processingAnalyticGrid[_gridKey][1](renderGridMetaSquareRectangles(_gridKey, procesingAnalyticGridRef.current[_gridKey]))
    }catch(err){
      console.error(err)
    }
  }

  function handleProcessingAnalyticClick(e, _status, _action){
    try{
      if(processingAnalyticIdRef.current != null){

        setProcessingAnalyticSaveNecessary(true)

        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData

        const latIndex = findClosestObjectId(metaData.squareLatLngs.latArray, lat, "lat") * metaData.numberOfPixels
        const lngIndex = findClosestObjectId(metaData.squareLatLngs.lngArray, lng, "lng") * metaData.numberOfPixels

        const pixelId = `${lngIndex}-${latIndex}`


        switch(currentSquareSelectedToolTypeRef.current){

          case "clicker":
            var status 

            if(_status == "default"){
              status = !(processingAnalyticChangesRef.current?.[pixelId]?.a == true) ? true:false
            }else if(_status == "active"){
              status = true
            }else if(_status == "inactive"){
              status = false
            }

            var element = processingAnalyticChangesRef.current?.[pixelId] || {a: null, l: []}
            element.a = status
            element.bounds = calculateProcessingGridSquareBounds(pixelId, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)

            //update the procesingAnalyticChangesRef
            processingAnalyticChangesRef.current[pixelId] = element
            setProcessingAnalyticChanges(processingAnalyticChangesRef.current)

            // find which grid the lat and lng are in based on the height and width of the image / processingGridSize
            const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(pixelId)

            // check if the update needs to cause a re-render of the processing grid or if it can be added to the processingBuffer
            // if it requires a grid re-render the update affected processing grid

            // if it can be added to the processingBuffer, add it to the processingBuffer and render the processingBuffer
            // else the processingBuffer is full for a given grid, update the grid and clear the buffer for that grid
            const copyObj = {...procesingAnalyticGridRef.current[renderGridKey]}
            copyObj[pixelId] = element
            renderProcessingGridSubSquare(renderGridKey, copyObj)

            break;
          
          case "paintBucket":

            //determine what the status of the pixel is
            var status 
            var oldStatus

            if(_status == "default"){
              status = !(processingAnalyticChangesRef.current?.[pixelId]?.a == true) ? true:false
              oldStatus = processingAnalyticChangesRef.current?.[pixelId]?.a
            }else if(_status == "active"){
              status = true
              oldStatus = false
            }else if(_status == "inactive"){
              status = false
              oldStatus = true
            }

            var availableNeighbours = {}
            //check if the clicked element has been clicked before
            if(processingAnalyticChangesRef.current?.[pixelId]?.a != null){
              //if the status has been set already only get neighbours that have the same status as the oldStatus
              Object.entries(processingAnalyticChangesRef.current)
              .filter(([key, element ]) => (element.a === oldStatus ))
              .forEach(([key, element]) => {
                availableNeighbours[key] = true
              })
            }else if(processingAnalyticChangesRef.current?.[pixelId]?.ml != null){
              //if the ml has been set already only get neighbours that have ml and do not have element.a set
              Object.entries(processingAnalyticChangesRef.current)
              .filter(([key, element ]) => (element.a == null && element.ml != null))
              .forEach(([key, element]) => {
                availableNeighbours[key] = true
              })
            } 

            const neighbours = recursiveFindNeighbours(pixelId, availableNeighbours, calculateNeighbours(pixelId, "diamond"), "diamond")
            const allKeys = [pixelId, ...neighbours]


            //update the procesingAnalyticChangesRef
            allKeys.forEach((key) => {

              var element = processingAnalyticChangesRef.current?.[key] || {a: null, l: []}
              element.a = status
              element.bounds = calculateProcessingGridSquareBounds(key, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)  
              
              processingAnalyticChangesRef.current[key] = element
            });
            setProcessingAnalyticChanges(processingAnalyticChangesRef.current)

            //get all the render grid keys that need to be updated
            var tempProcesingAnalyticGridObj = {}
            allKeys.forEach((key) => {
              // find which grid the lat and lng are in based on the height and width of the image / processingGridSize
              const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(key)
              if(tempProcesingAnalyticGridObj[renderGridKey] == null){
                tempProcesingAnalyticGridObj[renderGridKey] = {...procesingAnalyticGridRef.current[renderGridKey]}
              }
              tempProcesingAnalyticGridObj[renderGridKey][key] = processingAnalyticChangesRef.current[key]              
            })

            Object.keys(tempProcesingAnalyticGridObj).forEach((renderGridKey) => {
              // check if the update needs to cause a re-render of the processing grid or if it can be added to the processingBuffer
              // if it requires a grid re-render the update affected processing grid

              // if it can be added to the processingBuffer, add it to the processingBuffer and render the processingBuffer
              // else the processingBuffer is full for a given grid, update the grid and clear the buffer for that grid
                          
              renderProcessingGridSubSquare(renderGridKey, tempProcesingAnalyticGridObj[renderGridKey])
            })            
            
            break;
        
          case "polygonTool":

            if(_action == "default"){

              if(_status == "default"){
                //check if the polygontoolref is null
                if(polygonToolPolygonRef.current == null){
                  //if it is null then create a new polygonTool object
                  polygonToolPolygonRef.current = [{lat: lat, lng: lng}]
                }else{
                  //if it is not null then add the new point to the polygonTool object
                  polygonToolPolygonRef.current.push({lat: lat, lng: lng})
                }
                setPolygonToolPolygon(JSON.stringify(polygonToolPolygonRef.current))
              }else if(_status == "inactive"){
                //if the polygonTool is inactive then clear the polygonTool object
                polygonToolPolygonRef.current = null
                setPolygonToolPolygon(null)
              }

            }else if(_action == "polygonToolSelect"){              
              var status 
              if(_status == "active"){
                status = true 
              }else if(_status == "inactive"){
                status = false
              }

              const firstElement = polygonToolPolygonRef.current[0]
              var polygonArray = polygonToolPolygonRef.current.map((point) => { return [point.lng, point.lat] })

              polygonArray.push([firstElement.lng, firstElement.lat])
              
              //find the bounds of the polygon using turf
              const turfPolygon = turf.polygon([polygonArray])
              const bounds = calculatePolygonBounds(polygonToolPolygonRef.current)
              //using the metaData determine all of the squares that the bounds intersect with
              const metaData = squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData
              const squareLatLngs = metaData.squareLatLngs

              //find the north south east and west index from the squareLatLngs.lats and squareLatLngs.lngs objects
              const northIndex = findClosestObjectId(squareLatLngs.latArray, bounds.north, "lat") * metaData.numberOfPixels
              const southIndex = findClosestObjectId(squareLatLngs.latArray, bounds.south, "lat") * metaData.numberOfPixels
              const eastIndex = findClosestObjectId(squareLatLngs.lngArray, bounds.east, "lng") * metaData.numberOfPixels
              const westIndex = findClosestObjectId(squareLatLngs.lngArray, bounds.west, "lng") * metaData.numberOfPixels

              var allAffectedKeys = []
              //for i in range step * metaData.numberOfPixels
              //longitude
              for (let x = westIndex; x < eastIndex + 1; x += metaData.numberOfPixels){
                //latitude
                for (let y = northIndex; y < southIndex + 1; y += metaData.numberOfPixels){
                  const pixelId = `${x}-${y}`
                  var element = processingAnalyticChangesRef.current?.[pixelId] || {a: null, l: []}
                  element.a = status
                  element.bounds = calculateProcessingGridSquareBounds(pixelId, squareAnalyticsRef.current[currentSquareRef.current][processingAnalyticIdRef.current].data.Default.metaData, currentSquareObjectRef.current.bounds)  
                  //if the element bounds intersects with the polygon then set the status to the status
                  const boundsPolygon = turf.polygon([[[element.bounds.west, element.bounds.north], [element.bounds.east, element.bounds.north], [element.bounds.east, element.bounds.south], [element.bounds.west, element.bounds.south], [element.bounds.west, element.bounds.north]]])

                  
                  if(turf.booleanIntersects(turfPolygon, boundsPolygon).valueOf() == true){
                    processingAnalyticChangesRef.current[pixelId] = element
                    allAffectedKeys.push(pixelId)
                  }
                }
              }

              setProcessingAnalyticChanges(processingAnalyticChangesRef.current)

              //get all the render grid keys that need to be updated
              var tempProcesingAnalyticGridObj = {}
              allAffectedKeys.forEach((key) => {
                // find which grid the lat and lng are in based on the height and width of the image / processingGridSize
                const renderGridKey = calculateProcessingGridMetaSquareFromPixelId(key)
                if(tempProcesingAnalyticGridObj[renderGridKey] == null){
                  tempProcesingAnalyticGridObj[renderGridKey] = {...procesingAnalyticGridRef.current[renderGridKey]}
                }
                tempProcesingAnalyticGridObj[renderGridKey][key] = processingAnalyticChangesRef.current[key]              
              })

              Object.keys(tempProcesingAnalyticGridObj).forEach((renderGridKey) => {
                // check if the update needs to cause a re-render of the processing grid or if it can be added to the processingBuffer
                // if it requires a grid re-render the update affected processing grid

                // if it can be added to the processingBuffer, add it to the processingBuffer and render the processingBuffer
                // else the processingBuffer is full for a given grid, update the grid and clear the buffer for that grid
                            
                renderProcessingGridSubSquare(renderGridKey, tempProcesingAnalyticGridObj[renderGridKey])
              })

            }


            break;
        }

      }
    }catch(err){
      console.error(err)
      createAlert('error', 'Error processing click')
    }
  }

  function calculateNeighbours(_key, _type){
    const _x = parseInt(_key.split('-')[0])
    const _y = parseInt(_key.split('-')[1])
    const _numberOfPixels = 5
    if(_type == "square"){

      return [
        `${_x-_numberOfPixels}-${_y-_numberOfPixels}`,
        `${_x}-${_y-_numberOfPixels}`,
        `${_x+_numberOfPixels}-${_y-_numberOfPixels}`,
        `${_x-_numberOfPixels}-${_y}`,
        `${_x+_numberOfPixels}-${_y}`,
        `${_x-_numberOfPixels}-${_y+_numberOfPixels}`,
        `${_x}-${_y+_numberOfPixels}`,
        `${_x+_numberOfPixels}-${_y+_numberOfPixels}`,
      ]
    }else if(_type == "diamond"){
      return [
        `${_x}-${_y-_numberOfPixels}`,
        `${_x-_numberOfPixels}-${_y}`,
        `${_x+_numberOfPixels}-${_y}`,
        `${_x}-${_y+_numberOfPixels}`,
        ]
    }else{
      return []
    }
  }

  function recursiveFindNeighbours(_key, _touchedObject, _possibleNeighbours, _type){
    try{
      var neighbours = []
      // foreach _neighbour check if it is in the untouched array
      _possibleNeighbours.forEach((neighbourKey) => {
        if(_touchedObject[neighbourKey] == true){
          //delete the neighbour from the untouched array
          delete _touchedObject[neighbourKey]                
          neighbours.push(neighbourKey, ...recursiveFindNeighbours(neighbourKey, _touchedObject, calculateNeighbours(neighbourKey, _type), _type))
        }
      })

      return neighbours

    }catch(err){
      console.error(err)
    }
  }

  function renderGridMetaSquareRectangles(_gridKey, _dataObj){

    try{
      if(_dataObj != null){
        var categories = {
          "active": {},
          "inactive": {},
          "mlActive": {},
        } 

        var categroyGroups = []

        Object.keys(_dataObj).forEach((key) => {
          const elem = _dataObj[key]

          if(elem?.a == true){
            categories.active[key] = true
          }else if(elem?.a == false){
            categories.inactive[key] = true
          }else if(elem?.ml != null && elem?.ml >= mlConfidenceThresholdRef.current){
            categories.mlActive[key] = true
          }
        })

        // Now we can batch merge these polygons
        function batchUnion(polygons) {
          if (polygons.length === 0) {
            return null;
          }else if (polygons.length === 1){
            return polygons[0];
          }else{
            let combinedPolygon = polygons[0];


            for (let i = 1; i < polygons.length; i++) {
                try{    
                    combinedPolygon = turf.union(combinedPolygon, polygons[i]);
                }catch(e) {
                    
                }

            }

            return combinedPolygon;
          }
        }

        var largestGroup = []
        //foreach category
        Object.keys(categories).forEach((categoryKey) => {
          var category = categories[categoryKey]
          
          //while there are elements in the category
          while(Object.keys(category).length > 0){
            //get the first element
            const firstKey = Object.keys(category)[0]
            //remove the first element from the category
            delete category[firstKey]
            //create a touched object
            
            //get the neighbours of the first element
            const neighbours = recursiveFindNeighbours(firstKey, category, calculateNeighbours(firstKey, "diamond"), "diamond")

            const keys = [firstKey, ...neighbours]
            if(categoryKey == "mlActive" && keys.length > largestGroup.length){
              largestGroup = keys
            }

            //add the first element and the neighbours to a group
            categroyGroups.push({
              keys: [firstKey, ...neighbours],
              category: categoryKey
            })
          }

        })
        
        largestMLGroupRef.current[_gridKey] = largestGroup

        const categoryElements = {}

        Object.keys(categories).forEach((categoryKey) => {
          categoryElements[categoryKey] = categroyGroups.filter((group) => group.category == categoryKey).map((group) => {
            const elements = group.keys.map((key) => { return turf.polygon([boundsToPolygon(_dataObj[key].bounds).map((point) => { return [point.lng, point.lat] })])})
            const batchSize = 100;
            let batchedPolygons = [];
            for (let i = 0; i < elements.length; i += batchSize) {
              const batch = elements.slice(i, i + batchSize);
              const combinedBatch = batchUnion(batch);
              batchedPolygons.push(combinedBatch);
            }
            return {
              category: group.category,
              polygon: batchUnion(batchedPolygons)
            };

          })
        })


        const groupFormattedData = Object.keys(categoryElements).map((categoryKey) => {

          var color = "#f9a825"
          var fillOpacity = .2
          if(categoryKey == "active"){
            color = "#f9a825"
          }else if(categoryKey == "inactive"){
            color = "#BDBDBD"
          }else if(categoryKey == "mlActive"){
            color = "#F44336"
          }


          const allPolygons = categoryElements[categoryKey].map((element) => {
            try{
              let polygons = [];
              if (element.polygon.geometry.type === "Polygon") {
                // Handle single Polygon, include holes if they exist
                polygons = element.polygon.geometry.coordinates.map(ring => 
                  ring.map(coord => ({ lat: coord[1], lng: coord[0] }))
                );
              } else if (element.polygon.geometry.type === "MultiPolygon") {
                // Handle MultiPolygon, preserving outer and inner rings
                polygons = element.polygon.geometry.coordinates.map(polygon => {

                  return polygon.map(ring => {
                    return ring.map(coord => ({ lat: coord[1], lng: coord[0] }))
                  })
                }).flat(1);
              }
              return polygons
            }catch(err){
              console.error(err)
              return []
            }
          }).flat(1)

          return {
            category: categoryKey,
            polygons: allPolygons,
            color: color,
            fillOpacity: fillOpacity
          }
        
        })

        return groupFormattedData.map((group) => {

          return <Polygon 
                    key={`labeledData-${_gridKey}-${group.category}`}
                    paths={group.polygons}
                    options={{
                        strokeColor: group.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: group.color,
                        fillOpacity: group.fillOpacity,
                        zIndex: 200
                    }}
                  />
        })


      }else{
        return null
      }

    }catch(err){
      console.error(err)
      return null
    }


  }

  const handleCenterChanged = (_map) => {
      if (_map) {
        currentLocationRef.current = {lat:_map.center.lat(), lng: _map.center.lng()}
      }
  };

  const createExportImageryPolygonAtCenter = (_type) => {
    if(mapRef.current != null){

        //get the current bounds of the map using mapref
        const bounds = mapRef.current.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        const verticalDistance = Math.abs(ne.lat() - sw.lat());
        const horizontalDistance = Math.abs(ne.lng() - sw.lng());

        if(_type == "rectangle"){
          setExportImageryRectangle({
            north: currentLocationRef.current.lat + verticalDistance / 4,
            south: currentLocationRef.current.lat - verticalDistance / 4,
            east: currentLocationRef.current.lng + horizontalDistance / 4,
            west: currentLocationRef.current.lng - horizontalDistance / 4,
          })

          setTimeout(() => {
            handleExportImageryRectangleDrag()
          }, 200);
        }else if(_type == "polygon"){

          // create a polygon at the center of the map where the user is looking, make it reach half way from the center to the edge of the map in each direction
          // it will be an array of 4 points, each object will have lat and lng
          setExportImageryPolygon([
              { lat: currentLocationRef.current.lat - verticalDistance / 4, lng: currentLocationRef.current.lng - horizontalDistance / 4 },
              { lat: currentLocationRef.current.lat + verticalDistance / 4, lng: currentLocationRef.current.lng - horizontalDistance / 4 },
              { lat: currentLocationRef.current.lat + verticalDistance / 4, lng: currentLocationRef.current.lng + horizontalDistance / 4 },
              { lat: currentLocationRef.current.lat - verticalDistance / 4, lng: currentLocationRef.current.lng + horizontalDistance / 4 }
          ]);
        }
    }
  }

  const handleExportImageryRectangleDrag = () => {

    if (exportImageryRectangleRef.current) {
        //set the polygon bounds
        const tempBounds = exportImageryRectangleRef.current.getBounds();
        const bounds = {
          north: tempBounds.getNorthEast().lat(),
          south: tempBounds.getSouthWest().lat(),
          east: tempBounds.getNorthEast().lng(),
          west: tempBounds.getSouthWest().lng(),
        }

        var area = calculateRectangleArea(bounds)
        //convert square meters to square kilometers
        area = area / 1000000
        //round to 2 decimal places
        area = Math.round(area * 100) / 100

        setExportImageryArea(area)

        setAdminExportImageryAspectRatio(boundsToAspectRatio(bounds))

        exportImageryDataRef.current = {
          ...exportImageryDataRef.current,
          bounds: bounds,
          area: area,
        }
        
    }
}

  const handleExportImageryRectangleLoad = (rectangle) => {
    exportImageryRectangleRef.current = rectangle;

  }

  function boundsToAspectRatio(_bounds){

    try{
      const north = _bounds.north
      const south = _bounds.south
      const east = _bounds.east
      const west = _bounds.west
      const verticalDistance = Math.floor(Math.abs(north - south) * 1000)
      const horizontalDistance = Math.floor(Math.abs(east - west) * 1000)

      return `${verticalDistance}:${horizontalDistance}`


      //turn ratio into a 
      
    }catch(err){
      console.error(err)
      return "0:0"
    }



  }

  function updateImageryBrightness(_brightness){


    if(_brightness > 0 && _brightness <= 40){
      setImageryBrightness(_brightness)

      document.getElementById('dynamicCSS').innerHTML = `
        img[src*="firebase"] {
          filter: brightness(${Math.sqrt(_brightness)});
        }
      `;
      
      //store the brightness in local storage tied to the orgId and lotId
      localStorage.setItem(`imageryBrightness-${"adminMapLabeling"}-${taskReviewIndexOfKeyRef.current}`, _brightness)

    }

  }

  function updateMlConfidenceThreshold(_confidence){

    if(_confidence >= 0 && _confidence <= 100){
      mlConfidenceThresholdRef.current = _confidence
      setMlConfidenceThreshold(_confidence)
      localStorage.setItem(`mlConfidenceThreshold-${"adminMapLabeling"}-${taskReviewIndexOfKeyRef.current}`, _confidence)

      calculateProcessingGridMetaSquareAssignment(processingAnalyticChangesRef.current)
    }
  }


  const squareAnalyticsGifObj = squareGifImagery?.[currentSquare]?.[processingAnalyticIdShorthand]

  const numberOfExportFrames = Object.values(dateSliderMarks?.marks ?? {}).filter((mark) => mark.value >= adminExportImageryStartDate && mark.value <= adminExportImageryEndDate).length



  return (
    <>
    <div className='mapViewContent'>       
      <div className='adminMapContainerDiv'>   

          <GoogleMap           
            zoom={mapZoom} 
            center={location}             
            mapContainerClassName="adminMapViewBody"
            options={{
              styles: googleMapsStyle,
              disableDoubleClickZoom: true,

            }}
            onLoad={mapHandleOnLoad}
            onBoundsChanged={() => mapHandleBoundsChanged()}
            onCenterChanged={() => { handleCenterChanged(mapRef.current) }}
            mapTypeId={mapTypeId}
            onClick={(e)=> { 
              selectSquare(null)
            }}
            onDblClick={(e)=> {                                       
              
            }}>
              <StreetViewPanorama
                onLoad={onStreetViewLoad}
                visible={mapIsStreetViewVisible}
                options={{
                  enableCloseButton: false, // Remove the close button
                  addressControl: false,    // Remove the address info box
                  panControl: false,        // Remove the pan control
                  zoomControl: false,       // Remove the zoom control
                  fullscreenControl: false, // Remove the fullscreen control
                  motionTrackingControl: false, // Remove motion tracking control
                }}>

              </StreetViewPanorama>
              {
                exportImageryRectangle != null ? (
                  <Rectangle
                    editable={true}
                    draggable={true}
                    bounds={exportImageryRectangle}   
                    onLoad={handleExportImageryRectangleLoad}             
                    onBoundsChanged={handleExportImageryRectangleDrag}
                    options={{
                        ...getRectangleOptions(theme.palette.secondary.main),                        
                    }}/>
                  ):null
              }
              {
                visibleLotsArray.map(key => {   
                  const lot = lotsRef.current[key]
                                             
                  return lot ? (
                    <Polygon
                      key={`${key}-polygon`}
                      editable={false}
                      draggable={false}
                      paths={lot.polygon}   

                      options={{
                        ...settings.rectangleOptions,
                        fillColor: "#4CAF50",
                        strokeColor: "#4CAF50",
                        strokeWeight: 3,
                        zIndex: 100,
                        fillOpacity: .5,
                      }}/>
                  ):null
                })
              }
              {
                visibleSquaresArray.map(key => {
                  const square = squaresRef.current[key]

                  return square ? (
                    <Polygon
                      key={`${key}-square`}
                      editable={false}
                      draggable={false}
                      paths={square.polygon}   
                      onClick={() => selectSquare(key)}
                      options={{
                        ...settings.rectangleOptions,
                        fillColor: theme.palette.primary.main,
                        strokeColor: theme.palette.secondary.main,
                        strokeWeight: 3,
                        zIndex: 102,
                        fillOpacity: 0,
                      }}/>
                  ):null

                })
              }
              {
                currentSquareVisiblePngs ? currentSquareVisiblePngs.map((pngObj) => {
                  try{
                    return <GroundOverlay
                              key={`currentSquareVisiblePngs-${pngObj.dateId}`}
                              url={pngObj.url}
                              bounds={{
                                north: currentSquareObject.bounds.north,
                                south: currentSquareObject.bounds.south,
                                east: currentSquareObject.bounds.east,
                                west: currentSquareObject.bounds.west
                              }}
                              opacity={mapPanoramaIsDragging ? .5 : (["png", "gif"].includes(currentSquareSelectedImageryType) && pngObj.endDate.getTime() == dateSliderValue) ? 1:0}
                              options={{
                                clickable: false,
                                zIndex: 200,                                
                              }}
                              onLoad={() => {
                                updateDateSliderMarksLoadState(pngObj.squareId, pngObj.squareId, pngObj.endDateId)

                              }}
                            />
                  }catch(err){
                    console.error(err)
                  }
                }):null
              }
              {
                
                squareAnalyticsGifObj != null && squareAnalyticsGifObj?.loaded == true && currentSquareSelectedImageryType == "gif" ? Object.keys(squareAnalyticsGifObj?.elementUrls).map((key) => {
                  const url = squareAnalyticsGifObj?.elementUrls[key]
                  const index = key                  
                  //north and south are calculated as the bounds of the square but the index  between 0 and 9 is used to determine the bounds of the gif
                  const offsetAmount = squareSize / numberOfGifSections                  
                  const north = currentSquareObject.bounds.north - (offsetAmount * index)
                  const south = north - offsetAmount
                  const west = currentSquareObject.bounds.west
                  const east = currentSquareObject.bounds.east
                  return <GroundOverlay 
                            key={`${currentSquare}-${processingAnalyticIdShorthand}-gif-${index}}`}
                            url={url}
                            bounds={{
                              north: north,
                              south: south,
                              east: east,
                              west: west
                            }}                                    
                            opacity={ mapPanoramaIsDragging ? .5 : 1}
                            zIndex={201}
                            options={{
                              zIndex: 201,                                 
                            }}/>
                }):null
              }
              {
                showAnalytics ? Object.keys(processingAnalyticGrid).map((key) => {
                  return processingAnalyticGrid[key][0]
                }):null
              }     
              {
                currentSquareObject?.polygon ? (() => {

                  return <Polygon
                            key={`currentSquarePolygon`}
                            editable={false}
                            draggable={false}
                            paths={currentSquareObject?.polygon}   
                            options={{
                              ...settings.rectangleOptions,

                              strokeColor: theme.palette.secondary.main,
                              strokeWeight: 3,
                              zIndex: 202,
                              fillOpacity: 0,                              
                            }}
                            onClick={(e) => {
                              handleProcessingAnalyticClick(e, "default", "default")
                            }}
                            onRightClick={(e) => {
                              handleProcessingAnalyticClick(e, "inactive", "default")
                            }}/>

                })():null
              }
              {
                currentSquareLotMask && !exportImageryDialogOpen ? currentSquareLotMask.map((mask) => {

                  //sum of the abs of the lat and lng values of the mask
                  const sum = mask.reduce((acc, val) => {
                    return acc + Math.abs(val[0]) + Math.abs(val[1])
                  },0)

                  return <Polygon
                            key={`currentSquareLotMask-${sum}`}
                            editable={false}
                            draggable={false}
                            paths={mask.map(poly => poly.map(coordsArray => {
                                return {
                                  lat: coordsArray[1],
                                  lng: coordsArray[0]
                                }
                              })
                            )}   
                            options={{
                              ...settings.rectangleOptions,
                              fillColor: theme.palette.primary.main,
                              strokeColor: theme.palette.secondary.main,
                              strokeWeight: 3,
                              zIndex: 203,
                              fillOpacity: .75,
                              clickable: false,
                            }}/>

                }):null
              }
              {
                polygonToolPolygon != null ? (JSON.parse(polygonToolPolygon).length < 3 ? (
                  <Polyline
                    onLoad={(e) => {
                      polygonToolPolygonPolylineRef.current = e
                    }}
                    editable={true}
                    draggable={true}
                    path={JSON.parse(polygonToolPolygon)}
                    onMouseUp={(e) => {
                      if(polygonToolPolygonPolylineRef.current != null){                      
                        const getPaths = polygonToolPolygonPolylineRef.current.getPath()
                        
                        const array = getPaths.getArray().map((point) => {
                          return {lat: point.lat(), lng: point.lng()}
                        })
                        polygonToolPolygonRef.current = array
                        setPolygonToolPolygon(JSON.stringify(array))
                      }
                    }}
                    options={{
                      strokeColor: theme.palette.success.main,
                      strokeWeight: 3,
                      zIndex: 205,
                    }}/>
                ):(
                  <Polygon
                    onLoad={(e) => {
                      polygonToolPolygonPolygonRef.current = e
                    }}
                    editable={true}
                    draggable={true}
                    paths={JSON.parse(polygonToolPolygon)}
                    onMouseUp={(e) => {
                      
                      if(polygonToolPolygonPolygonRef.current != null){
                        const getPaths = polygonToolPolygonPolygonRef.current.getPaths()
                        const arrays = getPaths.getArray().map((path) => {
                          return path.getArray().map((point) => {
                            return {lat: point.lat(), lng: point.lng()}
                          })
                        })
                        //flatten the array
                        const array = arrays.flat(1)
                        polygonToolPolygonRef.current = array
                        setPolygonToolPolygon(JSON.stringify(array))

                      }

                    }}
                    options={{
                      ...settings.rectangleOptions,
                      fillColor: theme.palette.success.main,
                      strokeColor: theme.palette.success.main,
                      strokeWeight: 3,
                      zIndex: 205,
                      fillOpacity: .5,
                    }}
                    onClick={(e) => {
                      handleProcessingAnalyticClick(e, "active", "polygonToolSelect")
                    }}
                    onRightClick={(e) => {
                      handleProcessingAnalyticClick(e, "inactive", "polygonToolSelect")
                    }}/>
                )):null
              }
            
          </GoogleMap>
          <div className='adminMapContainerSliderDiv' style={{height: currentSquare != null ? '70px':'0px', opacity: currentSquare != null ? 1:0}} data-disable-interaction="true">       
            {
              ![currentSquarePngDates, dateSliderValue, dateSliderMarks?.marks, dateSliderMinDate, dateSliderMaxDate].includes(null) ? 
                <Slider
                  step={null}
                  value={dateSliderValue}
                  onChange={(e) => handleDateSliderChange(e.target.value)}
                  marks={dateSliderMarks?.marks}
                  min={dateSliderMinDate}
                  max={dateSliderMaxDate}
                  color="secondary"                     
                  disabled={Object.keys(currentSquarePngDates).length == 0 || currentSquare == null}     
                  className='adminMapDateSlider'
                  sx={dateSliderMarksLoadStateStyle}
                  onKeyDown={(e) => {
                    e.preventDefault();
                    
                  }}
                />:null
            }
          </div>

          {/* Overlay Elements */} 
          <div className='adminMapLeftAlignedActions'>           
            <Paper className='adminMapSelectAnalyticCard' key={`${refreshKey}-selectAnalyticCard`}>
              <div className='adminMapSelectAnalyticCardMapSelector'>
                {
                  mapIsStreetViewVisible ? 
                    <Button 
                      variant={"text"}
                      color={"primary"}  
                      onClick={() => {
                        setMapIsStreetViewVisible(false);
                        setMapPanoramaIsDragging(false)
                      }}
                      style={{flex: 1, height: '45px', lineHeight: '45px'}}
                      startIcon={<ArrowCircleLeft />}>
                        Exit Street View
                    </Button>
                  :<>
                    <Button 
                      variant={"text"}
                      color={mapTypeId == "roadmap" ? "primary":"gray"}  
                      onClick={() => {toggleGoogleMapType("roadmap")}}
                      style={{flex: 1, height: '45px', lineHeight: '45px'}}
                      >
                        Map
                    </Button>
                    <Button 
                      variant={"text"}
                      color={mapTypeId == "satellite" ? "primary":"gray"}  
                      onClick={() => {changeSelectedImgType("gmapSat")}}
                      style={{ flex: 1, height: '45px', lineHeight: '45px'}}
                      >
                        Satellite
                    </Button>
                  </>
                }
                

              </div>
              <div className='adminMapSelectAnalyticCardSquareInfo' style={{display: currentSquare != null ? 'flex':'none'}}>
                <DataDisplay 
                  icon={SelectAll} 
                  title={
                    ![taskObject, taskReviewIndexOfKey].includes(null) ? 
                      (reviewMode == "taskMode" ? "Map Task":reviewMode == "validateMode" ? "Map Validation":""):
                      currentSquare
                  } 
                  data={
                    ![taskObject, taskReviewIndexOfKey].includes(null) ?
                      `${(taskReviewIndexOfKey + 1).toString().padStart(3, "0")} - ${taskObject?.displayName}`:
                      processingAnalyticId?.substring(14, processingAnalyticId.length) ?? ""
                  } 
                  loading={false}/>
                {
                  processingAnalyticChanges != null ? null:<DataDisplay icon={Event} title={"Date"} data={currentSquarePngDateObject ? dateToLocaleUTCDateString(currentSquarePngDateObject?.endDate, 'en-us', {year: 'numeric', month: 'long', day: 'numeric'}):''} loading={false}/>
                }
                
                {
                  ![null, undefined].includes(taskReviewObject?.reviewer) ? <h5>
                    <img src={taskReviewObject?.reviewer?.photoURL} className='img'/><span><div><div><strong>Reviewer</strong></div><div>{taskReviewObject?.reviewer?.displayName}</div></div></span>
                  </h5>:null
                }
                {
                  processingAnalyticChanges != null ? <div onClick={() => setShowAnalytics(!showAnalytics)}><DataDisplay icon={showAnalytics ? CheckBox:CheckBoxOutlined} title={"Changes"} data={`${Object.values(processingAnalyticChanges)?.filter(e => e.a == true).length.toLocaleString()}/${Object.values(processingAnalyticChanges)?.filter(e => e.a == null ).length.toLocaleString()} Changes`} loading={false}/></div>:null
                }
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <h5 style={{marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Imagery</h5>
                  <div className='adminMapSelectAnalyticImagePickerArea'> 
                    <IconButton
                      onClick={() => {
                        changeSelectedImgType("png")
                      }}                    
                      disabled={!currentSquareVisiblePngs}
                      style={{backgroundColor: currentSquareSelectedImageryType == "png" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                      <Collections style={{color: theme.palette.secondary.contrastText}}/>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        
                        changeSelectedImgType("gif")
                      }}
                      disabled={(processingAnalyticId == null)}
                      style={{backgroundColor: currentSquareSelectedImageryType == "gif" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                      <Gif style={{color: (processingAnalyticId == null || squareGifImagery?.[currentSquare]?.[activeDateObject?.gifs?.monthly] == null) ? theme.palette.gray.contrastText:theme.palette.secondary.contrastText}}/>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        changeSelectedImgType("gmapSat")
                      }}
                      style={{backgroundColor: currentSquareSelectedImageryType == null ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                      <HideImage style={{color: theme.palette.secondary.contrastText}}/>
                    </IconButton>
                  </div>
                </div>
                {
                  processingAnalyticChanges != null ? <>
                      <div style={{display: true ? 'flex':'none', flexDirection: 'column'}}>
                        <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Brightness ({imageryBrightness.toFixed(1)})</h5>
                        <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                            <IconButton 
                              onClick={() => {
                                updateImageryBrightness(imageryBrightness - .5)
                              }}>
                              <BrightnessLow color='primary' />
                            </IconButton>
                            <Slider 
                                aria-label="Brightness" 
                                value={imageryBrightness} 
                                min={0}
                                max={40}
                                step={.5}
                                marks={false}
                                color='secondary'
                                onChange={(event) => {
                                    
                                    updateImageryBrightness(event.target.value)
                                }} />
                            <IconButton 
                              onClick={() => {
                                updateImageryBrightness(imageryBrightness + .5)
                              }}>
                              <BrightnessHigh color='primary' />
                            </IconButton>
                        </Stack>
                    </div>
                  </>:null
                }
                {
                  processingAnalyticChanges != null && false ? <>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Confidence ({mlConfidenceThreshold.toFixed(1)})</h5>
                        <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                            <IconButton 
                              onClick={() => {
                                updateMlConfidenceThreshold(mlConfidenceThreshold - 5)
                              }}>
                              <CropSquare color='primary' />
                            </IconButton>
                            <Slider 
                                aria-label="Brightness" 
                                value={mlConfidenceThreshold} 
                                min={0}
                                max={100}
                                step={5}
                                marks={false}
                                color='secondary'
                                onChange={(event) => {
                                    
                                  updateMlConfidenceThreshold(event.target.value)
                                }} />
                            <IconButton 
                              onClick={() => {
                                updateMlConfidenceThreshold(mlConfidenceThreshold + 5)
                              }}>
                              <Square color='primary' />
                            </IconButton>
                        </Stack>
                    </div>
                  </>:null
                }
                {
                  processingAnalyticChanges != null ? <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Tools</h5>
                    <div className='adminMapSelectAnalyticImagePickerArea'> 
                      <IconButton
                        onClick={() => {
                          changeSelectedToolType("clicker")
                        }}                    
                        
                        style={{backgroundColor: currentSquareSelectedToolType == "clicker" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                        <TouchApp style={{color: theme.palette.secondary.contrastText}}/>
                      </IconButton>
                      {/* <IconButton
                        onClick={() => {
                          
                          changeSelectedToolType("brush")
                        }}
                        style={{backgroundColor: currentSquareSelectedToolType == "brush" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                        <Gesture style={{color: theme.palette.secondary.contrastText}}/>
                      </IconButton> */}
                      <IconButton
                        onClick={() => {
                          changeSelectedToolType("paintBucket")
                        }}
                        style={{backgroundColor: currentSquareSelectedToolType == "paintBucket" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                        <FormatColorFill style={{color: theme.palette.secondary.contrastText}}/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          changeSelectedToolType("polygonTool")
                        }}
                        style={{backgroundColor: currentSquareSelectedToolType == "polygonTool" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                        <PolylineIcon style={{color: theme.palette.secondary.contrastText}}/>
                      </IconButton>
                     
                      
                    </div>
                  </div>:null
                }
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={!(squareAnalytics?.[currentSquare]?.[currentSquarePngDateObject?.analytics?.monthly]?.exists == true)}
                  startIcon={<ViewInAr />}
                  onClick={() => {
                    selectAnalytic(currentSquare, currentSquarePngDateObject.analytics.monthly)
                  }}
                  style={{display: processingAnalyticId == null && !(["taskMode", "validateMode"].includes(reviewMode)) && !exportImageryDialogOpen? 'flex':'none'}}>
                  {(squareAnalytics?.[currentSquare]?.[currentSquarePngDateObject?.analytics?.monthly]?.exists == true) ? 'Label Analytics':'No Analytics'}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={(dateSliderMarks?.marks ?? []).length == 0}
                  startIcon={<Crop />}
                  onClick={() => {
                    startExportImagery(true)
                  }}
                  style={{
                    display: !(["taskMode", "validateMode"].includes(reviewMode)) && processingAnalyticId == null && !exportImageryDialogOpen ? 'flex':'none',
                    marginTop: '10px'
                  }}>
                  {'Export Imagery'}
                </Button>
                
              </div>
            </Paper>

            <Paper className='adminExportImageryCard' style={{display: exportImageryDialogOpen ? 'flex':'none' }}>
              <h3><Crop style={{fontSize: '20px', marginRight: '10px'}}/> Export Imagery</h3>
              <Divider style={{marginBottom: '10px'}} />
              
              <DataDisplay 
                icon={Label} 
                title={"Display Name"} 
                data={
                  <TextField 
                    id="adminExportImageryDisplayName" 
                    variant="standard" 
                    value={adminExportImageryDisplayName} 
                    onChange={(e) => {
                      exportImageryDataRef.current.displayName = e.target.value
                      setAdminExportImageryDisplayName(e.target.value)
                    }} 
                    style={{width: '160px'}}/>
                } 
                loading={false}/>
              <DataDisplay icon={Straighten} title={"Area"} data={`${exportImageryArea ?? 0} km2`} loading={false}/>
              <DataDisplay icon={AspectRatio} title={"Aspect Ratio"} data={adminExportImageryAspectRatio} loading={false} />
              
              <DataDisplay icon={Crop} title={"Range"} data={
                <div className='adminExportImageryDateSelector'>
                  <FormControl variant="standard" style={{flex: 1, marginBottom: '10px'}}>
                    { adminExportImageryStartDate != null ?
                      <Select
                        labelId="adminExportImageryDateSelector-start"
                        id="adminExportImageryDateSelector-start"
                        value={adminExportImageryStartDate}
                        onChange={(e) => {
                          updateAdminExportImageryDate(e.target.value, "start")
                        }}
                        label="Start">
                        {
                          Object.keys(dateSliderMarks?.marks ?? {}).map((key) => {
                            const element = dateSliderMarks?.marks[key]

                            return <MenuItem key={`start-${element?.dateElement?.dateId}`} value={element?.value}>{dateToLocaleUTCDateString(element?.dateElement?.endDate, 'en-us', {year: 'numeric', month: 'short'})}</MenuItem>
                          })
                        }                  
                      </Select>:null
                    }
                  </FormControl>
                  <FormControl variant="standard" style={{flex: 1}}>  
                    { adminExportImageryEndDate != null ?
                      <Select
                        labelId="adminExportImageryDateSelector-end"
                        id="adminExportImageryDateSelector-end"
                        value={adminExportImageryEndDate}
                        onChange={(e) => {
                          updateAdminExportImageryDate(e.target.value, "end")
                        }}
                        label="End">
                        {
                          Object.keys(dateSliderMarks?.marks ?? {}).map((key) => {
                            const element = dateSliderMarks?.marks[key]

                            return <MenuItem key={`end-${element?.dateElement?.dateId}`} value={element?.value}>{dateToLocaleUTCDateString(element?.dateElement?.endDate, 'en-us', {year: 'numeric', month: 'short',})}</MenuItem>
                          })
                        }
                      </Select>:null
                  }
                  </FormControl>
                </div>
              } loading={false}/>
              <DataDisplay icon={numberOfImageIcons[(numberOfExportFrames > 9 ? "10":numberOfExportFrames.toString())]} title={"Number of Frames"} data={`${numberOfExportFrames} images`} loading={false} />
              
              <Divider style={{marginBottom: '10px'}} />
              <div 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <IconButton
                  onClick={() => {
                    startExportImagery(false)
                  }}                    
                  style={{marginRight: '10px'}}>
                  <Close />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if(exportImageryRectangle == null){
                      createExportImageryPolygonAtCenter('rectangle')
                    }else{
                      setExportImageryRectangle(null)
                      setExportImageryArea(0)
                    }

                  }}                    
                  style={{
                    marginRight: '10px',
                    backgroundColor: exportImageryRectangle == null ? theme.palette.secondary.main:'inherit',
                    color: exportImageryRectangle == null ? theme.palette.secondary.contrastText:theme.palette.secondary.contrastText
                  
                  }}>
                    {
                      exportImageryRectangle == null ? <Crop />:<Refresh />
                    }
                </IconButton>
                <IconButton
                  onClick={() => {
                    exportImagery()
                  }}               
                  disabled={exportImageryRectangle == null || adminExportImageryLoading}     
                  style={{
                    marginRight: '10px',
                    backgroundColor: exportImageryRectangle == null || adminExportImageryLoading ? theme.palette.gray.main:theme.palette.secondary.main,
                    color: exportImageryRectangle == null || adminExportImageryLoading ? theme.palette.gray.contrastText:theme.palette.secondary.contrastText
                  }}>
                  {
                    adminExportImageryLoading ? <div style={{height: '24px', width: '24px'}}><CircularProgress size={24} /></div>:<Publish />
                  }
                </IconButton>                
              </div>
            </Paper>
            

          </div>

          
          <Paper className='adminMapSaveAnalyticCard' style={{display: processingAnalyticId != null ? 'flex':'none'}} key={`${refreshKey}-saveChanges`}>              
            <Button
              variant='contained'
              color='secondary'
              onClick={async () => {
                if(processingAnalyticSaveNecessary){
                  saveProcessing(true, false)
                  .catch((error) => {
                    console.error(error)
                  })
                }else{
                  openPublishDialog(true)                    
                }

              }}
              startIcon={processingAnalyticSaveNecessary ? <Save />:(reviewMode == "taskMode" ? <CheckCircleOutline />:<CloudUpload />)}
              style={{minWidth: '190px'}}>
                {
                  processingAnalyticSaveNecessary ? 'Save Changes':(reviewMode == "taskMode" ? 'Submit Changes':'Publish Changes')
                }
            </Button>
            
          </Paper>

          <Paper className='adminMapExitAnalyticCard' style={{display: processingAnalyticId != null ? 'flex':'none'}} key={`${refreshKey}-endSession`}>              
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                attemptToExitProcessing()
              }}
              startIcon={<ExitToApp />}
              >
              End Session 
            </Button>
          </Paper>
      </div>
    </div>
    <Dialog
        open={confirmExitWithoutSavingDialogOpen}
        onClose={() => setConfirmExitWithoutSavingDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Looks like you have unsaved changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please save your changes before exiting
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmExitWithoutSavingDialogOpen(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>
          <Button 
            variant='contained'
            color='secondary'
            startIcon={<Save />}
            onClick={() => saveProcessingAndExit()} autoFocus>
            Save and Exit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={publishChangesDialogOpen}
        onClose={() => openPublishDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Publish Analytics?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will publish the changes to the square and make them live to our customers. Are you sure you want to publish these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openPublishDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={publishChangesDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CloudUpload />} 
            text="Publish"
            action={() => {publishChanges()}}/>


        </DialogActions>
      </Dialog>

      <Dialog
        open={submitChangesDialogOpen}
        onClose={() => openPublishDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Submit Changes for Review?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will submit the changes to the square for review by a supervisor. Are you sure you want to submit these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openPublishDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={publishChangesDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CheckCircleOutline />} 
            text="Submit"
            action={() => {submitChangesForReview()}}/>


        </DialogActions>
      </Dialog>

      <Dialog
        open={completeAnalyticProcessingDialogOpen}
        onClose={() => openCompleteAnalyticProcessingDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Close Map Review Task?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will mark this task as complete and remove it from the task list. Are you sure you want to close this task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openCompleteAnalyticProcessingDialog(false)}>Cancel</Button>
          
          <div style={{flex: 1}}></div>

          <CountdownTimerButton 
            targetMilliseconds={completeAnalyticProcessingDialogOpenTime} 
            loading={savingChangesLoading}
            icon={<CloseFullscreen />} 
            text="Close Task"
            action={() => {closeAnalyticTask()}}/>


        </DialogActions>
      </Dialog>

      

    </>
  )

}


export default AdminMapReview
