
export function boundsToPolygon(_bounds) {
  
  const polygon = [
    {lat: _bounds.north, lng: _bounds.west},
    {lat: _bounds.north, lng: _bounds.east},
    {lat: _bounds.south, lng: _bounds.east},
    {lat: _bounds.south, lng: _bounds.west},
    {lat: _bounds.north, lng: _bounds.west}
  ];
  return polygon;

}