import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

function DataDisplay({ icon: IconComponent, title, data, loading, onClick = undefined }) {

  var onClickDefined = onClick !== undefined;
  //check if onClick was passed
  if (onClick === undefined) {
    onClick = () => {};
  }

  return (
    <Box display="flex" alignItems="center" gap={2} style={{marginBottom: '15px', alignItems: 'flex-start', cursor: onClickDefined ? 'pointer':'', minHeight: '45px'}} onClick={onClick}>
      <Box>
        <IconComponent style={{fontSize: '18px', lineHeight: '20px'}}/>
      </Box>
      <Box>
        <Typography variant="subtitle1" style={{fontSize: '16px', lineHeight: '20px', fontWeight: '500'}}>{title}</Typography>
        {
          loading ? (
            <Skeleton variant="text" width={100} height={20} />
          ) : (
            <Typography variant="body2" color="textSecondary">
              {data}
            </Typography>
          )
        }
      </Box>
    </Box>
  );
}

export default DataDisplay;
