import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination'
import { CircularProgress } from '@mui/material';

const CountdownTimer = ({ targetMilliseconds, loading, icon, text, action, disabled=false }) => {
  // Calculate the total seconds left
  const calculateTotalSecondsLeft = () => {
    const difference = targetMilliseconds - +new Date();
    return difference > 0 ? Math.floor(difference / 1000) : 0;
  };

  const [totalSecondsLeft, setTotalSecondsLeft] = useState(calculateTotalSecondsLeft());

  useEffect(() => {
    // Update the countdown every second
    const timer = setInterval(() => {
      setTotalSecondsLeft(calculateTotalSecondsLeft());
    }, 100);

    // Clear the interval on component unmount
    return () => clearInterval(timer);
  }, [targetMilliseconds]); // Depend on `targetMilliseconds` to recalculate if it changes

  const countdownDisabled = totalSecondsLeft > 0;
  
  return <Button 
            onClick={action} 
            autoFocus 
            variant={'contained'} 
            color={'secondary'}
            startIcon={icon}
            disabled={countdownDisabled || loading || disabled}
            >
              <div style={{width: `${text.length * 10}px`}}>
                {
                  loading ? <CircularProgress size={12} color={'inherit'} /> : countdownDisabled ? totalSecondsLeft:text
                }
              </div>
          </Button>

};

export default CountdownTimer;
