import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import './ContactUs.css'
import { Email } from '@mui/icons-material';


function ContactUs(props) {

    const currentUser = props?.auth?.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    return (
        <div className="contactUsContentArea">
            <div className='contactUsScroller'>
                <Paper className='ContactUsContentPaper' elevation={2} >
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825', marginBottom: '15px'}}>
                        <MailIcon style={{marginRight: '10px'}}/>
                        Contact Us
                    </div>
                                        
                  
                    
                    <ContactUsForm email={currentUserEmail}/>
                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default ContactUs