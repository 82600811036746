

export function calculateDateFromMillisecond(millisecond) {
    
    //convert millisecond to int if it is a string
    try {
        if (typeof millisecond === 'string' || millisecond instanceof String) {
            millisecond = parseInt(millisecond)
        }
        // take a millisecond and return a date string of the type Month Day, Year
        var date = (new Date(millisecond))
        
        //if date is within the last 24 hours
        if(date > new Date(Date.now() - 172800000)){
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})
        }else{
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric'})
        }

    } catch (error) {
        console.log(error)
        return 'Invalid Date'
    }
  }