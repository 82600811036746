import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Account.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';

import { AccountCircle, Email, VerifiedUser } from '@mui/icons-material'



import DataDisplay from '../DataDisplay/DataDisplay';

import DefaultAccount from '../../assets/DefaultAccount.png'

function Account(props) {

    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    var profileImg = auth.currentUser.photoURL
    if (profileImg == null) {
        profileImg = DefaultAccount
    }


    return (
        <div className="accountContent">
            <div className='accountContentScroller'>
                <Paper elevation={2} className='accountContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <AccountCircle style={{marginRight: '10px'}}/>
                        Account
                    </div>
                    <div className='accountInfoArea'>
                        <img 
                            src={profileImg} 
                            className="accountInfoProfileImg"/>
                        <div className='accountInfoAreaTextHolder'>
                            <h2>{currentUser.displayName}</h2>
                            <p style={{marginBottom: '10px'}}>{`Account Created: ${calculateDateFromMillisecond(currentUser?.metadata?.createdAt)}`}</p>
                            <DataDisplay icon={Email} title={"Email"} data={currentUserEmail} loading={false}/>
                            <DataDisplay icon={VerifiedUser} title={"User Id"} data={currentUser.uid} loading={false}/>


                        </div>
                    </div>
                    <div className='accountContentArea'>
                    </div>

                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Account