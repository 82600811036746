import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import firebase from 'firebase/compat/app'
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL, list } from "firebase/storage";
import { GoogleMap, GroundOverlay, Polygon, Rectangle, StreetViewPanorama, useLoadScript } from '@react-google-maps/api';
import './MapView.css';
import googleMapsStyle from '../../assets/googleMapsStyle.json'

import { boundsToPolygon } from '../../functions/boundsToPolygon';
import { polygonsToOutline } from '../../functions/polygonsToOutline';
import { calculatePolygonsIntersect } from '../../functions/calculatePolygonsIntersect';
import { calculateProcessingGridSquareBounds } from '../../functions/calculateProcessingGridSquareBounds'
import { dateIdToAnalyticsRequestId } from '../../functions/dateIdToAnalyticsRequestId';
import { dateIdToAnalyticsRequestIdShorthand } from '../../functions/dateIdToAnalyticsRequestIdShorthand';
import { dateIdToUTCDateObject } from '../../functions/dateIdToUTCDateObject';  
import { calculateRelativeDateSliderMarkValue } from '../../functions/calculateRelativeDateSliderMarkValue';
import { calculateDateSliderMarks } from '../../functions/calculateDateSliderMarks';
import { calculateDateMarkStyles } from '../../functions/calculateDateMarkStyles';
import { dateRangeIdToDate } from '../../functions/dateRangeIdToDate';
import { dateRangeIdToString } from '../../functions/dateRangeIdToString';
import { lngLatToSquareId } from '../../functions/lngLatToSquareId';
import { polygonToBounds } from '../../functions/polygonToBounds';
import { squareIdToLatLng } from '../../functions/squareIdToLatLng';

import AnalyticRendering from '../../components/AnalyticRendering/AnalyticRendering'
import { availableAnalytics } from '../../components/AnalyticRendering/AnalyticRendering'

import { Button, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack,  Slider, Switch, styled } from '@mui/material';
import { ArrowCircleLeft, BrightnessHigh, BrightnessLow, ControlCamera, Visibility, Image, InvertColorsOff, InvertColors, CalendarMonth, DateRange, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Key } from '@mui/icons-material';
import { formatDateAsId } from '../../functions/formatDateAsId';
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString';


function MapView(props) {

  const orgObj = props.organizationObj
  const theme = useTheme();
  const demoMode = props.demoMode;
  const user = props.user

  const AOIDISTANCE = .25

  const orgId = demoMode ? "Demo":orgObj?.selectedOrganization /*props.user.uid*/;
  const createAlert = props.createAlert
  const firestore = firebase.firestore();
  const storage = getStorage();
  const navigate = useNavigate();

  const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"})
  const {lotId, dateValue, analyticId} = useParams();

  const mapRef = useRef(null);
  const pegmanListerTimeoutCountRef = useRef(0)
  const mapPanoramaIsDraggingRef = useRef(false)
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [mapIsStreetViewVisible, setMapIsStreetViewVisible] = useState(false)
  const [mapPanoramaIsDragging, setMapPanoramaIsDragging] = useState(false);
  const [zoom, setZoom] = useState(12)
  const [location, setLocation] = useState({lat: 28.538336, lng: -81.379234})

  const dateSliderValueRef = useRef(null)
  const [dateSliderValue, setDateSliderValue] = useState(null)
  const [dateId, setDateId] = useState(null)

  const showAnalyticsRef = useRef(true)
  const showPNGImageryRef = useRef(true)
  const [showAnalytics, setShowAnalytics] = useState(true)
  const [showPNGImagery, setShowPNGImagery] = useState(true)


  const timeMappedDataRef = useRef({});
  const [reRenderTimeMappedData, setReRenderTimeMappedData] = useState(0)
  const selectedLotKeyRef = useRef(null);
  const selectedLotRef = useRef(null);
  const selectedAnalyticKeyRef = useRef(null);
  const selectedAnalyticRef = useRef(null);
  const organizationLotsRef = useRef({});

  const analyticDataRef = useRef({});
  const [currentAnalyticData, setCurrentAnalyticData] = useState({})
  const displayAnalyticDataRef = useRef({})
  const [displayAnalyticData, setDisplayAnalyticData] = useState({})

  const analyticStateRef = useRef(null)
  const [analyticState, setAnalyticState] = useState(null)


  const dateSliderMarksRef = useRef([])
  const dateSliderMarksLoadStateRef = useRef({})
  const availablePNGImageryRef = useRef({})
  const [dateSliderMarksLoadState, setDateSliderMarksLoadState] = useState({})
  const [dateSliderMarks, setDateSliderMarks] = useState([]) 
  const [dateSliderMarksLoadStateStyle, setDateSliderMarksLoadStateStyle] = useState({})
  const [availablePNGImagery, setAvailablePNGImagery] = useState({})


  const stepIntervalRef = useRef('yearly')
  const [stepInterval, setStepInterval] = useState('yearly')

  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false)

  const [selectedLotKey, setSelectedLotKey] = useState(null);
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedAnalyticKey, setSelectedAnalyticKey] = useState(null);
  const [selectedAnalytic, setSelectedAnalytic] = useState("none");
  const [organizationLots, setOrganizationLots] = useState({});

  const [imageryBrightness, setImageryBrightness] = useState(10)
  const [imagerySaturation, setImagerySaturation] = useState(1)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }

  }, [])

  useEffect(() => {
    //clearState()
    init()
  }, [orgObj?.selectedOrganization])
  
  useEffect(() => {

    const tempAvailableDateMarks = calculateDateSliderMarks(timeMappedDataRef.current[selectedLotKeyRef.current], stepIntervalRef.current)
    if(JSON.stringify(tempAvailableDateMarks) != JSON.stringify(dateSliderMarksRef.current)){
      dateSliderMarksRef.current = tempAvailableDateMarks
      setDateSliderMarks(tempAvailableDateMarks)
    }

    const tempAvailablePNGImagery = calculateAvailablePNGImagery(timeMappedDataRef.current)
    
    if(JSON.stringify(tempAvailablePNGImagery) != JSON.stringify(availablePNGImageryRef.current)){
 
      availablePNGImageryRef.current = tempAvailablePNGImagery
      setAvailablePNGImagery(tempAvailablePNGImagery)
    }

    const tempAvailableAnalyticData = calculateAvailableAnalyticData(timeMappedDataRef.current[selectedLotKeyRef.current])
    if(JSON.stringify(tempAvailableAnalyticData) != JSON.stringify(analyticDataRef.current)){
      analyticDataRef.current = tempAvailableAnalyticData
      setCurrentAnalyticData(tempAvailableAnalyticData)
    }

    const tempDisplayAnalyticData = tempAvailableAnalyticData[selectedAnalyticKeyRef.current]?.['analytics']?.[selectedAnalyticRef.current] ?? {}
    if(JSON.stringify(tempDisplayAnalyticData) != JSON.stringify(displayAnalyticDataRef.current)){
      displayAnalyticDataRef.current = tempDisplayAnalyticData
      setDisplayAnalyticData(tempDisplayAnalyticData)
    }

  }, [reRenderTimeMappedData])

  
  function init() {

    getOrganizationBillingLots()

  }

  function handleKeyDown(event){

    if(event.keyCode == 83){
      //s key
      //set the date to the first date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current?.marks, selectedAnalyticKeyRef.current, -23), true)
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 68){
      //d key
      //set the date to the middle date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current?.marks, selectedAnalyticKeyRef.current, -12), true)       
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 70){
      //f key
      //set the date to the last date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(dateSliderMarksRef.current?.marks, selectedAnalyticKeyRef.current, 0), true)
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 37){
      //left arrow key
      //check if there is a previous date in the dateSliderMarks
      const currentIndex = dateSliderMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
      if(currentIndex > 0){
        handleDateSliderChange(dateSliderMarksRef.current?.marks[currentIndex - 1].value, true)
      }
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 39){
      //right arrow key
      //check if there is a next date in the dateSliderMarks
      const currentIndex = dateSliderMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
      if(currentIndex < dateSliderMarksRef.current?.marks?.length - 1){
        handleDateSliderChange(dateSliderMarksRef.current?.marks[currentIndex + 1].value, true)
      }
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 38 || event.keyCode == 69){
      //up arrow key or e key
      //toggle png imagery visibility
      showPNGImageryRef.current = !showPNGImageryRef.current
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 87){
      //w key
      toggleGoogleMapType('roadmap')
    }else if(event.keyCode == 82){
      //r key
      toggleGoogleMapType('hybrid')
    }else{
      // console.log(event.keyCode)
    }

  }

  

  function getOrganizationBillingLots() {
    
    if((user != null && orgObj?.selectedOrganization != null) || demoMode){
      var query 
      if(demoMode){
        query = firestore.collection('Billing').doc('Demo').collection('Lots').where("metaData.active", "==", true).get();
      
      }else{
        query = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').where("metaData.active", "==", true).get();
      }
      query.then((querySnapshot) => {
        var lots = {}
        querySnapshot.forEach((doc) => {
          try{
            const data = doc.data()
            lots[doc.id] = data
            lots[doc.id].id = doc.id
            
            const bounds = polygonToBounds(data.polygon)
            lots[doc.id].bounds = bounds

            //calculate required squares and bounds
            //based on the bounds find the squares that are required for the lot //squares are defined as .25 x .25 degree squares

            const requiredSquaresArea = {
              north: Math.ceil(bounds.north/0.25)*.25,
              south: Math.floor(bounds.south/0.25)*.25,
              east: Math.ceil(bounds.east/0.25)*.25,
              west: Math.floor(bounds.west/0.25)*.25
            }

            var requiredSquares = []
            var lotPolygon = data.polygon.map(point => [point.lng, point.lat])
            //if the first and last points are not the same, add the first point to the end of the array
            if(lotPolygon[0] != lotPolygon[lotPolygon.length - 1]){
              lotPolygon.push(lotPolygon[0])
            }

            //iterate through the bounds and find the squares that are required
            for(var x = requiredSquaresArea.west; x <= requiredSquaresArea.east; x += .25){
              for(var y = requiredSquaresArea.south; y <= requiredSquaresArea.north; y += .25){
                //create turf polygon from bounds
                const squarePolygonArray = [[x, y], [x, y + .25], [x + .25, y + .25], [x + .25, y], [x, y]]
                const intersects = calculatePolygonsIntersect(squarePolygonArray, [lotPolygon]) 
                if(intersects){                  
                  const squareId = lngLatToSquareId(x, y)
                  requiredSquares.push(squareId)
                }
              }
            }

            lots[doc.id].requiredSquares = requiredSquares
            var requiredSquaresBounds = {}
            requiredSquares.forEach(squareId => {
              requiredSquaresBounds[squareId.replaceAll(".", "")] = calculateImgSquareBounds(squareId)
            })
            lots[doc.id].requiredSquaresBounds = requiredSquaresBounds
            
          }catch(error){
            console.error("Error getting lot data: " + error)
          }

        });

        organizationLotsRef.current = lots
        setOrganizationLots(lots)

        if(lotId != null && lots[lotId] != null){
          setTimeout(() => {
            selectLot(lotId)
          }, 100)
        }else if(Object.keys(lots).length > 0 && selectedLotKey == null){
          setTimeout(() => {
            selectLot(Object.values(lots).sort((a, b) => a?.displayName.toString().localeCompare(b.displayName?.toString()))[0].id)            
          }, 100);
        }

      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
    }
  }

  function toggleStepInterval(){
    if(stepIntervalRef.current == 'monthly'){

      //calculate styles for the dateSliderMarks

      
      const tempAvailableDateMarks = calculateDateSliderMarks(timeMappedDataRef.current[selectedLotKeyRef.current], stepIntervalRef.current)
      handleDateSliderChange(tempAvailableDateMarks.max, true)
      
      stepIntervalRef.current = 'yearly'
      setStepInterval('yearly')

    }else if(stepIntervalRef.current == 'yearly'){
      stepIntervalRef.current = 'monthly'
      setStepInterval('monthly')
    }

    setDateSliderMarksLoadStateStyle(calculateDateMarkStyles(calculateDateSliderMarks(timeMappedDataRef.current[selectedLotKeyRef.current], stepIntervalRef.current)?.marks, dateSliderMarksLoadStateRef.current[selectedLotKeyRef.current], selectedAnalyticKeyRef.current, theme))


    setReRenderTimeMappedData(Math.random())
  }

  function calculateImgSquareBounds(_gridId){

    const gridIdSplit = _gridId.replaceAll(".", "").split('-')
    const xVal = (parseInt(gridIdSplit[0])/1000) - 180
    const yVal = (parseInt(gridIdSplit[1])/1000) - 90

    return {
      north: yVal + .25,
      south: yVal,
      west: xVal, 
      east: xVal + .25
    }
  }

  
  

  

  function mapHandleOnLoad(map) {
    mapRef.current = map;    

    initPegmanListener()
    centerOnSelectedLot()

  };

  const onStreetViewLoad = useCallback((streetViewPanorama) => {
    // Check if the user is in Street View mode
    setMapIsStreetViewVisible(streetViewPanorama.getVisible());

    // Listen for changes to the visibility of Street View
    streetViewPanorama.addListener("visible_changed", () => {
      setMapIsStreetViewVisible(streetViewPanorama.getVisible());
      setMapPanoramaIsDragging(false)
    });
    

  }, []);

  function initPegmanListener(){

    pegmanListerTimeoutCountRef.current += 1
    // Select all elements with the class "gm-svpc"
    const elements = document.querySelectorAll('.gm-svpc');
    if(elements.length == 0 && pegmanListerTimeoutCountRef.current < 20){      
      setTimeout(() => {
        initPegmanListener()
      }, 500);      
    }else{

      // Add event listeners to each element
      elements.forEach(element => {
        element.addEventListener('mousedown', () => {
          setMapPanoramaIsDragging(true)       
        });
      });
      // Cleanup function to remove event listeners when component unmounts
      return () => {
        elements.forEach(element => {          
          element.removeEventListener('mousedown', () => {     

          });
        });
      };
    }
  }

  

  async function selectLot(_lotId){

    if(organizationLotsRef.current[_lotId] != null){
      
      selectedLotRef.current = organizationLotsRef.current[_lotId]
      setSelectedLot(organizationLotsRef.current[_lotId])
      selectedLotKeyRef.current = _lotId
      setSelectedLotKey(selectedLotKeyRef.current)

      //if demo mode
      if(orgId == "Demo"){
        //update the web url route
        navigate(`/Demo/${_lotId}`)

      }else{
        //update the web url route
        navigate(`/Organization/MapView/${_lotId}`)

      }
      //check if there is a brightness setting for the lot in local storage
      var brightness = 1
      var localStorageBrightness = localStorage.getItem(`imageryBrightness-${orgId}-${_lotId}`)
      if(localStorageBrightness != null){
        brightness = parseFloat(localStorageBrightness)
      }else if(organizationLotsRef.current[_lotId]?.defaultSettings?.brightness != null){
        brightness = parseFloat(organizationLotsRef.current[_lotId]?.defaultSettings?.brightness)
      }

      var saturation = 1
      var localStorageSaturation = localStorage.getItem(`imagerySaturation-${orgId}-${_lotId}`)
      if(localStorageSaturation != null){
        saturation = parseFloat(localStorageSaturation)
      }else if(organizationLotsRef.current[_lotId]?.defaultSettings?.saturation != null){
        saturation = parseFloat(organizationLotsRef.current[_lotId]?.defaultSettings?.saturation)
      }

      updateImageryStyle(brightness, saturation)


      

      setReRenderTimeMappedData(Math.random())

      //clear the selected analytic data
      selectAnalyticSetKey(orgId, _lotId, null)

      centerOnSelectedLot()


      var imgPromiseArray = []
      //foreach square in requiredSquares, get the square imagery
      organizationLotsRef.current[_lotId]?.requiredSquares?.forEach(async (squareId) => {
        imgPromiseArray.push(getSquareImagery(orgId, _lotId, squareId.replaceAll(".", "")))        
      })
      await Promise.all(imgPromiseArray)

      var analyticsPromiseArray = []
      //foreach square in requiredSquares, get the square analytic data
      organizationLotsRef.current[_lotId]?.requiredSquares?.forEach(async (squareId) => {
        analyticsPromiseArray.push(getSquareAnalytics(orgId, _lotId, squareId.replaceAll(".", "")))        
      })
      await Promise.all(analyticsPromiseArray)


      //calculate the available analytics 
      const tempAvailableAnalyticData = calculateAvailableAnalyticData(timeMappedDataRef.current[selectedLotKeyRef.current])
      //first analytic in the available analytic data
      const lastAnalytic = Object.entries(tempAvailableAnalyticData).sort((a, b) => b[1]?.displayDate.getTime() - a[1].displayDate.getTime())?.[0]?.[0]


      if(lastAnalytic != null){
        selectAnalyticSetKey(orgId, _lotId, lastAnalytic )
      }

      setReRenderTimeMappedData(Math.random())

      const availableDateMarks = calculateDateSliderMarks(timeMappedDataRef.current[_lotId], stepIntervalRef.current)
      
      if(selectedAnalyticKeyRef.current != null){
        //TODO: load the first middle and last date in the dateSliderMarks
        handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, 0), true)
        setTimeout(() => {
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -6), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -11), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -23), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -35), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -47), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -59), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -71), false)
          handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarks?.marks, selectedAnalyticKeyRef.current, -83), false)          
        }, 1000);


      }else if(availableDateMarks?.marks?.length > 0){
        handleDateSliderChange(availableDateMarks?.marks[availableDateMarks?.marks?.length - 1].value, true)
      }
      


    }else{
      
      const availableDateMarks = calculateDateSliderMarks(timeMappedDataRef.current[_lotId], stepIntervalRef.current)
      
      //set the date to the most recent date
      setReRenderTimeMappedData(Math.random())
      handleDateSliderChange(availableDateMarks?.marks[availableDateMarks?.marks?.length - 1].value, true)


       
    }

  }

  async function selectAnalyticSetKey(_organizationId, _lotId, _analyticKey){

    if(_analyticKey == null){
      selectedAnalyticKeyRef.current = null
      setSelectedAnalyticKey(null)      
      selectedAnalyticRef.current = null
      setSelectedAnalytic("none")
      analyticStateRef.current = null
      setAnalyticState(null)
      return
    }else{
      selectedAnalyticKeyRef.current = _analyticKey
      setSelectedAnalyticKey(_analyticKey)   
      
      return
    }
  }

  function selectAnalytic(_organizationId, _lotId, _analyticKey, _selectedAnalyticId){
  
    selectedAnalyticRef.current = _selectedAnalyticId
    setSelectedAnalytic(_selectedAnalyticId)

    analyticStateRef.current = null
    setAnalyticState(null)

    setReRenderTimeMappedData(Math.random())

    var promiseArrayIndex = []
    var promiseArray = []

    //check if the data has been rendered
    //foreach square in timeMappedDataRef.current?.[_lotId]
    Object.keys(timeMappedDataRef.current?.[_lotId] ?? {}).forEach((squareId) => {
      const element = timeMappedDataRef.current?.[_lotId]?.[squareId]?.timeData?.[_analyticKey]?.analytics?.[_selectedAnalyticId]
      if(element != null && element.data == null){
        //fetch the data
        promiseArrayIndex.push(squareId)
        promiseArray.push(fetchAnalyticData(_organizationId, _lotId, _analyticKey, _selectedAnalyticId, element))
      }
    })

    Promise.all(promiseArray)
    .then((res) => {
      res.forEach((promiseData, index) => {
        try{
          const squareId = promiseArrayIndex[index]
          timeMappedDataRef.current[_lotId][squareId]['timeData'][_analyticKey]['analytics'][_selectedAnalyticId] = promiseData
        }catch(error){
          console.error("Error fetching analytic data: " + error)
        }
      })

      setReRenderTimeMappedData(Math.random())
    })


  }

  function fetchAnalyticData(_organizationId, _lotId, _analyticKey, _selectedAnalyticId, _element){

    if(_selectedAnalyticId == "PixelGroupChangePercentage"){
      return new Promise(async (resolve, reject) => {
        try{
          //get the first element in the folder
          const folderContents = await listAll(_element.folderRef)

          if(folderContents.items.length > 0){
            
            //find the first item with -Default.json in the name
            const defaultItem = folderContents.items.find(item => item.name.includes("-Default.json"))

            if(defaultItem != null){

              if(_element.url == null){
                const url = await getDownloadURL(defaultItem)
                _element.url = url
              }

              const response = await fetch(_element.url)
              const data = await response.json()

              resolve({
                data: data,
                url: _element.url,
                folderRef: _element.folderRef
              })

            }else{
              throw new Error("Default item not found")
            }
          }

          
  
        }catch(error){
          console.error("Error fetching analytic data: " + error)
          resolve({
            data: null,
            url: null,
            folderRef: _element.folderRef
          })
        }
  
      })

    }else{
      console.error(`Analytic type ${_selectedAnalyticId} not found`)
      return new Promise((resolve, reject) => {
        resolve({
          data: null,
          url: null,
          folderRef: _element.folderRef
        })
      })
    }


    

  }

  function collectAllClusters(obj, requiredSquaresBounds, squareMetaData) {
    const clusters = [];

    // Helper function to parse the `${squareIdLng}-${squareIdLat}-${x}-${y}` format
    function parseKey(key) {
        const [squareIdLng, squareIdLat, x, y] = key.split('-');
        
        return { 
            squareId: `${squareIdLng}-${squareIdLat}`,
            x: parseInt(x, 10),
            y: parseInt(y, 10)
        };
    }

    // Helper function to construct a key from squareId, x, and y coordinates
    function constructKey(squareId, x, y) {
        return `${squareId}-${x}-${y}`;
    }

    // Function to find the matching squareId when moving to a different square
    function findMatchingSquare(squareId, direction) {
        const currentBounds = requiredSquaresBounds[squareId];
        for (const [otherSquareId, bounds] of Object.entries(requiredSquaresBounds)) {
            if (direction === 'up' && currentBounds.north === bounds.south) {
                return otherSquareId;
            }
            if (direction === 'left' && currentBounds.west === bounds.east) {
                return otherSquareId;
            }
            if (direction === 'right' && currentBounds.east === bounds.west) {
                return otherSquareId;
            }
            if (direction === 'down' && currentBounds.south === bounds.north) {
                return otherSquareId;
            }
        }
        return null;
    }

    // Recursive function to collect elements in a cluster
    function recursiveCollect(key, cluster) {
        if (!obj.hasOwnProperty(key)) {
            return; // If the key doesn't exist in the object, do nothing
        }

        cluster.push(key); // Collect the key
        delete obj[key]; // Remove the key from the object to prevent revisiting

        const { squareId, x, y } = parseKey(key); // Parse current key

        // Define the four directions to check
        const directions = [
            { dx: 5, dy: 0, direction: 'right' },   // right
            { dx: -5, dy: 0, direction: 'left' },   // left
            { dx: 0, dy: 5, direction: 'down' },    // down
            { dx: 0, dy: -5, direction: 'up' }      // up
        ];

        // Check each direction recursively
        for (const { dx, dy, direction } of directions) {
            let newSquareId = squareId;
            let newX = x + dx;
            let newY = y + dy;

            // Check if moving outside the square
            if (newX < 0 || newY < 0 || newX > squareMetaData[squareId].width || newY > squareMetaData[squareId].height) {
                const matchingSquare = findMatchingSquare(squareId, direction);

                if (matchingSquare) {
                    newSquareId = matchingSquare;

                    if (direction === 'left') {
                        const width = squareMetaData[matchingSquare].width;
                        newX = width - (width % 5); // Highest value divisible by 5 within the width
                    }

                    if (direction === 'up') {
                        const height = squareMetaData[matchingSquare].height;
                        newY = height - (height % 5); // Highest value divisible by 5 within the height
                    }

                    if (direction === 'right') {
                        newX = 0; // Start from 0 in the new square
                    }

                    if (direction === 'down') {
                        newY = 0; // Start from 0 in the new square
                    }
                }
            }

            const newKey = constructKey(newSquareId, newX, newY);
            if (obj.hasOwnProperty(newKey)) {
                recursiveCollect(newKey, cluster);
            }
        }
    }

    // Main function logic: iterate through all keys in the object
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const cluster = [];
            recursiveCollect(key, cluster);
            clusters.push(cluster);
        }
    }

    return clusters;
  }

  function initTimeDataObject(_squareId, _dateId, _endYear, _endMonth, _endDay){
    
    const endDate = new Date(Date.UTC(_endYear, _endMonth - 1, _endDay))

    return {
      squareId: _squareId,
      dateId: _dateId,
      endDateId: formatDateAsId(endDate),
      endDate: endDate,
      displayDate: dateIdToUTCDateObject(_dateId),          
              
    }

  }

  function getSquareAnalytics(_organizationId, _lotId, _squareId){
    return new Promise(async (resolve, reject) => {
      if(_organizationId != null && _lotId != null && _squareId != null){
        if(timeMappedDataRef.current[_lotId] == null){
          timeMappedDataRef.current[_lotId] = {}
        }
        if(timeMappedDataRef.current[_lotId][_squareId] == null){
          timeMappedDataRef.current[_lotId][_squareId] = {
            analyticDataFetched: null,
            pngImageryFetched: null,
            timeData: {}
          }
        }

        var promiseArrayIndex = []
        var promiseArray = []
        if(timeMappedDataRef.current[_lotId][_squareId].analyticDataFetched == null){
          timeMappedDataRef.current[_lotId][_squareId].analyticDataFetched = true
          
          //listen to the storage for the square analytic data
          const analyticRef = ref(storage, `OrganizationData/${_organizationId}/LotData/${_lotId}/${_squareId}/Analytics`)
          //list all of the folders in the AnalyticRef folder
          const analyticFolders = await listAll(analyticRef)

          //foreach prefix
          analyticFolders.prefixes.forEach((prefixRef) => {
            //get the name of the prefix
            const analyticType = prefixRef.name.split("/").pop()
            promiseArrayIndex.push(analyticType)
            promiseArray.push(listAll(prefixRef))
            
          })

          Promise.all(promiseArray)
          .then((res) => {

            res.forEach(async (promiseData, index) => {
              const analyticType = promiseArrayIndex[index]
              
              //foreach date folder
              promiseData.prefixes.forEach((dateFolderRef) => {
                //get the name of the date folder
                const folderName = dateFolderRef.name.split("/").pop()
                const endYear = folderName.split('-')[1]
                const endMonth = folderName.split('-')[2]
                const endDay = folderName.split('-')[3]

                const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay))
                const endDateString = formatDateAsId(endDate)

                if(timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString] == null){
                  
                  timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString] = initTimeDataObject(_squareId, endDateString, endYear, endMonth, endDay)
                }
  
                //check if the analytics data is already in the timeMappedData object
                if(timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString]['analytics'] == null){
                  timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString]['analytics'] = {}  
                }     
                if(timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString]['analytics'][analyticType] == null){
                  timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString]['analytics'][analyticType] = {
                    folderRef: dateFolderRef,
                    url: null,
                    data: null,
                  }  
                }     
                                
              })            
            })
            resolve()
          });
        }
      }
    });
    
  }

  function getSquareImagery(_organizationId, _lotId, _squareId){
    return new Promise((resolve, reject) => {
      if(_organizationId != null && _lotId != null && _squareId != null){
        if(timeMappedDataRef.current[_lotId] == null){
          timeMappedDataRef.current[_lotId] = {}
        }
        if(timeMappedDataRef.current[_lotId][_squareId] == null){
          timeMappedDataRef.current[_lotId][_squareId] = {
            analyticDataFetched: null,
            pngImageryFetched: null,
            timeData: {}
          }
        }

        var promiseArrayIndex = []
        var promiseArray = []
        if(timeMappedDataRef.current[_lotId][_squareId].pngImageryFetched == null){
          timeMappedDataRef.current[_lotId][_squareId].pngImageryFetched = true
          
          //listen to the storage for the square imagery
          const pngRef = ref(storage, `OrganizationData/${_organizationId}/LotData/${_lotId}/${_squareId}/Imagery/PNGs`)
          promiseArrayIndex.push("png")
          promiseArray.push(listAll(pngRef))
        }


        Promise.all(promiseArray)
        .then((res) => {
          res.forEach(async (promiseData, index) => {
            if(promiseArrayIndex[index] == "png"){
              promiseData.items.forEach((itemRef) => {            
                try{
                  const fileName = itemRef.name.split(".")[0]
                  const dateId = fileName.split(`${_squareId}-`)[1]
              
                  // const startYear = dateId.split('-')[0]
                  // const startMonth = dateId.split('-')[1]
                  // const startDay = dateId.split('-')[2]
                  const endYear = dateId.split('-')[3]
                  const endMonth = dateId.split('-')[4]
                  const endDay = dateId.split('-')[5]
    
                  const endDateString = `${endYear}-${endMonth}-${endDay}`
    
                  if(timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString] == null){                    
                    timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString] = initTimeDataObject(_squareId, endDateString, endYear, endMonth, endDay)
                  }
    
                  //check if the png imagery is already in the timeMappedData object
                  if(timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString].pngImagery == null){
                    timeMappedDataRef.current[_lotId][_squareId]['timeData'][endDateString].pngImagery = {
                      fileRef: itemRef,
                      url: null,
                    }  
                  }                 
                }catch(error){
                  console.error("Error getting square imagery: " + error)
                } 
              }) 
            }            
          })
          resolve()
        })
        .catch((error) => {
          console.error("Error getting square imagery: " + error)
          resolve()
        })
      }else{
        resolve()
      }    
    })  
  }  

  function centerOnSelectedLot(){

    if(selectedLotRef.current?.bounds != null && mapRef.current != null){
      panToBounds(selectedLotRef.current.bounds)
    }  
  }

  function panToBounds(_bounds){
    if(mapRef.current){
      mapRef.current.fitBounds(_bounds)
    }
  }

  

  function calculateAvailableAnalyticData(_lotData){
    try{
      var availableAnalyticData = {}

      Object.keys(_lotData ?? {})
      .forEach((squareId) => {
        availableAnalyticData[squareId] = {}

        Object.keys(_lotData[squareId]?.timeData ?? {})
        .forEach((dateId) => {
          const dateObj = _lotData[squareId]?.timeData[dateId]
          if(dateObj?.analytics != null){
            availableAnalyticData[squareId][dateId] = dateObj?.analytics
          }
        })
      })
      //format by dates
      var returnObj = {}
      Object.keys(availableAnalyticData ?? {})
      .forEach((squareId) => {
        Object.keys(availableAnalyticData[squareId] ?? {})
        .forEach((dateId) => {
          const dateObj = availableAnalyticData[squareId][dateId]
          const timeDataObj = _lotData[squareId]?.timeData?.[dateId]

          if(returnObj[dateId] == null){
            returnObj[dateId] = {
              "displayDate": timeDataObj?.displayDate,
              "analytics": {}
            }            
          }

          Object.keys(dateObj ?? {})
          .forEach((analyticKey) => {
            if(returnObj[dateId]["analytics"][analyticKey] == null){
              returnObj[dateId]["analytics"][analyticKey] = {}
            }
            if(returnObj[dateId]["analytics"][analyticKey][squareId] == null){
              returnObj[dateId]["analytics"][analyticKey][squareId] = {}
            }
            returnObj[dateId]["analytics"][analyticKey][squareId] = dateObj[analyticKey]
          })         
        })
      })


      return returnObj
    }catch(error){
      console.error("Error calculating available analytic data: " + error)
      return {}
    }
  }

  function calculateAvailablePNGImagery(_allData){
    try{

      var availablePNGImagery = {}

      Object.keys(_allData ?? {})
      .forEach((lotId) => {
        
        availablePNGImagery[lotId] = {}
        const lotData = _allData[lotId]

        Object.keys(lotData).forEach((squareId) => {
          availablePNGImagery[lotId][squareId] = {}

          Object.keys(lotData[squareId]?.timeData ?? {})
          .forEach((dateId) => {
            const dateObj = lotData[squareId]?.timeData[dateId]
            if(dateObj?.pngImagery?.url != null){
              availablePNGImagery[lotId][squareId][dateObj?.endDateId] = dateObj?.pngImagery?.url
            }
          })
        })
      })

      return availablePNGImagery
    }catch(error){
      console.error("Error calculating available png imagery: " + error)
      return {}
    }

  }

  function handleDateSliderChange(_value, _render){
    
    if(_render){
      dateSliderValueRef.current = _value
      setDateSliderValue(_value)
    }

    
    //check if the date is in the timeMappedData object
    if(timeMappedDataRef.current[selectedLotKeyRef.current] != null){

      var foundDate = null;
    
      for (let squareId in timeMappedDataRef.current[selectedLotKeyRef.current]){
        const timeData = timeMappedDataRef.current[selectedLotKeyRef.current][squareId]?.timeData
        if(timeData != null){
          const selectedKey = Object.keys(timeData).find((key) => {
              const date = timeData[key].endDate.getTime()
              return date == _value
            })
          if(selectedKey != null){
            foundDate = timeData[selectedKey]          
            break
          }
        }
      }

      if(foundDate != null){

        if(_render){
          setDateId(foundDate?.endDateId)
        }

        const promiseArrayIndex = []
        const promiseArray = []
        Object.keys(timeMappedDataRef.current[selectedLotKeyRef.current])
        .forEach((squareId) => {
          const timeData = timeMappedDataRef.current[selectedLotKeyRef.current][squareId]?.timeData
          if(timeData != null){
            const selectedKey = Object.keys(timeData).find((key) => {
                const date = timeData[key].endDate.getTime()
                return date == _value
              })
            if(selectedKey != null){
              const element = timeData[selectedKey]
              if(element.pngImagery != null && element.pngImagery.url == null){
                promiseArrayIndex.push({
                  squareId: squareId,
                  key: selectedKey
                })
                promiseArray.push(getDownloadURL(element.pngImagery.fileRef))              
              }
            }
          }
        })

        if(promiseArray.length > 0){
          Promise.all(promiseArray)
          .then((res) => {
            res.forEach((url, index) => {
              const squareId = promiseArrayIndex[index].squareId
              const key = promiseArrayIndex[index].key
              timeMappedDataRef.current[selectedLotKeyRef.current][squareId].timeData[key].pngImagery.url = url
            })

            setReRenderTimeMappedData(Math.random())

          })
          .catch((error) => {
            console.error("Error getting png imagery: " + error)
          })
        }
      }
    }
  }

  

  function updateDateSliderMarksLoadState(_lotId, _squareId, _dateId){

    if(dateSliderMarksLoadStateRef.current[_lotId] == null){
      dateSliderMarksLoadStateRef.current[_lotId] = {}
    }
    if(dateSliderMarksLoadStateRef.current[_lotId][_squareId] == null){
      dateSliderMarksLoadStateRef.current[_lotId][_squareId] = {}
    }
    dateSliderMarksLoadStateRef.current[_lotId][_squareId][_dateId] = true
    setDateSliderMarksLoadState(dateSliderMarksLoadStateRef.current)

    setDateSliderMarksLoadStateStyle(calculateDateMarkStyles(dateSliderMarksRef.current?.marks, dateSliderMarksLoadStateRef.current[_lotId], selectedAnalyticKeyRef.current, theme))
    

  }

  function toggleGoogleMapType(_type = null){

    if(_type == 'roadmap' || _type == 'hybrid'){
      setMapTypeId(_type)
    }else{      
      setMapTypeId(prev => prev === 'hybrid' ? 'roadmap' : 'hybrid');
    }
  }

  function updateImageryStyle(_brightness, _saturation){
    
    var tempBrightness = imageryBrightness
    var tempSaturation = imagerySaturation

    if(_brightness > 0 && _brightness <= 40){
      setImageryBrightness(_brightness)
      tempBrightness = _brightness
    }
    if(_saturation > 0 && _saturation <= 4){    
      setImagerySaturation(_saturation)
      tempSaturation = _saturation
    }
    document.getElementById('dynamicCSS').innerHTML = `
      img[src*="firebase"] {
        filter: brightness(${Math.sqrt(_brightness)}) saturate(${_saturation});
      }
    `;


    //store the brightness in local storage tied to the orgId and lotId
    localStorage.setItem(`imageryBrightness-${orgId}-${selectedLotKeyRef.current}`, _brightness)

    localStorage.setItem(`imagerySaturation-${orgId}-${selectedLotKeyRef.current}`, _saturation)

    

  }


  const totalExpectedImages = Object.keys(availablePNGImagery[selectedLotKey] ?? {}).length
  const totalAvailableImages = Object.keys(availablePNGImagery[selectedLotKey] ?? {}).map((squareId) => { try{return availablePNGImagery[selectedLotKey][squareId][dateId]}catch(err){return []}}).filter(e => e != undefined).length
  const displayDate = dateSliderMarks?.marks?.find(a => a.value == dateSliderValue)?.dateElement?.displayDate ?? null


  return (
    <div className="mapViewContent">
      <div className='mapViewHeader'>        
        <FormControl 
          size="small" 
          className="mapViewHeaderLotPicker" 
          key="lotForm">
          <InputLabel 
              color="primary"
              size="small">Lot</InputLabel>
          {
            selectedLotKey != null ? (
              <Select              
                value={selectedLotKey}
                label="Lot"                            
                onChange={(event) => {
                    selectedLotKeyRef.current = event.target.value;
                    setSelectedLotKey(selectedLotKeyRef.current);
                    selectLot(selectedLotKeyRef.current)
                }}
                color="primary"
                className='mapViewHeaderLotPickerSelect'>
                {
                    Object.values(organizationLots)
                      .filter(lotObj => lotObj != null)
                      .sort((a, b) => a?.displayName.toString().localeCompare(b.displayName?.toString()))
                      .map((lotObj) => {
                        
                        
                        return <MenuItem value={lotObj.id} key={`lot-${lotObj.id}`}>{lotObj?.displayName}</MenuItem>

                    })
                }                      
              </Select>
            ): null
          }       
        </FormControl> 
        
        <FormControl 
          size="small" 
          className="mapViewHeaderLotPicker"
          key="analyticForm" 
          style={{
            display: 'none'
          }}>
          <InputLabel 
              color="primary"
              size="small">Analytics</InputLabel>
          {
            Object.keys(currentAnalyticData).length > 0 ? (
              <Select              
                value={selectedAnalyticKey ?? ""}
                label="Analytics"
                onChange={(event) => {
                  selectAnalyticSetKey(orgId, selectedLotKey, event.target.value);
                }}
                color="primary"
                className='mapViewHeaderLotPickerSelect'>
                {
                    Object.entries(currentAnalyticData)                      
                      .sort((a, b) => a[1]?.displayDate.getTime() - b[1].displayDate.getTime())
                      .map((entry) => {   
                        const key = entry[0]
                        const analyticElement = entry[1]                

                        return <MenuItem value={key} key={`lot-analytics-${key}`}>{dateToLocaleUTCDateString(
                          analyticElement.displayDate, 
                          "en-US", 
                          {year: 'numeric', month: 'long'})
                          }</MenuItem>

                    })
                }                      
              </Select>
              ): null
          }       
        </FormControl> 
        <FormControl 
            size="small" 
            className="mapViewHeaderLotPicker"
            key="availableAnalyticForm" 
            style={{
              display: false && selectedAnalyticKey != null ? 'flex':'none',
              
            }}>
            <InputLabel 
              color="primary"
              size="small">Selected Analytic</InputLabel>
            <Select              
                value={selectedAnalytic ?? "none"}
                label="Selected Analytic"
                onChange={(event) => {
                  selectAnalytic(orgId, selectedLotKey, selectedAnalyticKey, event.target.value);
                }}
                color="primary"
                className='mapViewHeaderLotPickerSelect'>
                  <MenuItem value={"none"} key={`lot-analytics-null`}>None</MenuItem>
                {
                    Object.keys(currentAnalyticData[selectedAnalyticKey]?.analytics ?? {})                      
                      .map((key) => {     
                        try{                   
                          const analytic = currentAnalyticData[selectedAnalyticKey]?.analytics?.[key]
                          if(availableAnalytics[key] != null){
                            return <MenuItem value={key} key={`lot-analytics-${key}`}>{availableAnalytics[key]?.displayName}</MenuItem>
                          }else{
                            return null
                          }
                        }catch(err){
                          console.error(err)
                          return null
                        }

                    })
                }                      
              </Select>
        </FormControl>
        <Button
            variant='contained'
            color='primary'
            onClick={() => {
              navigate(`/Organization/Lots/${selectedLotKey}`)
            }}
            startIcon={<Visibility />}
            className='hidden-mobile'
            style={{ textAlign: 'left', justifyContent: 'flex-start', display: demoMode ? 'none':'flex'}}>
              View Lot
          </Button>
        <div 
          style={{flex: 1}}/>
        

        <h4 className="hidden-mobile">          
          {
            displayDate != null ?
              dateToLocaleUTCDateString(
                displayDate, 
                "en-US", 
                {year: 'numeric', month: 'long'})
              : null
          }
        </h4>
        <div className='hidden-mobile' style={{marginLeft: '15px', width: '120px',}}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              toggleStepInterval()
            }}
            startIcon={stepInterval == "monthly" ? <CalendarMonth />:<DateRange />}
            
            style={{ textAlign: 'left', justifyContent: 'flex-start'}}>
              {stepInterval}
          </Button>          
        </div>     
        <IconButton
            color="primary"
            onClick={() => {
              toggleStepInterval()
            }}
            className='shown-mobile'>
              {stepInterval == "monthly" ? <CalendarMonth />:<DateRange />}
        </IconButton>

        <IconButton
          color="primary"
          onClick={() => {
            centerOnSelectedLot()
          }}
          disabled={selectedLotKey == null}
          style={{marginLeft: '10px'}}>
            <ControlCamera />
        </IconButton>
            
      </div>
      <Paper 
        className='mapViewActionsDiv'>
          <div className='mapViewCardMapSelector'>
          {
                mapIsStreetViewVisible ? 
                  <Button 
                    variant={"text"}
                    color={"primary"}  
                    onClick={() => {
                      setMapIsStreetViewVisible(false);
                      setMapPanoramaIsDragging(false)
                    }}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    startIcon={<ArrowCircleLeft />}>
                      Exit Street View
                  </Button>
                :<>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "roadmap" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("roadmap")}}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Map
                  </Button>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "hybrid" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("hybrid")}}
                    style={{ flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Satellite
                  </Button>
                </>
              }
          </div>
          <div className='mapViewActionsDivContent' style={{display: !mapIsStreetViewVisible && mobileMenuExpanded ? 'flex':'none'}}>
            
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Switch 
                      color='secondary' 
                      checked={showPNGImagery}
                      onChange={(event) => {
                        showPNGImageryRef.current = event.target.checked
                        setShowPNGImagery(showPNGImageryRef.current)
                      }}/>
                  } 
                  label="Show Imagery"/>
                  <Chip style={{display: totalAvailableImages == totalExpectedImages || totalExpectedImages > 0 ? 'none':'flex' }} label={totalAvailableImages == totalExpectedImages ? "Complete":`${totalAvailableImages} of ${totalExpectedImages} Images`} icon={<Image />} color='secondary'  />
              </FormGroup>
              <div style={{display: showPNGImagery ? 'flex':'none', flexDirection: 'column'}}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Brightness ({imageryBrightness.toFixed(1)})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness - .5, imagerySaturation)
                        }}>
                        <BrightnessLow color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Brightness" 
                          value={imageryBrightness} 
                          min={0}
                          max={40}
                          step={.5}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                              updateImageryStyle(event.target.value, imagerySaturation)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness + .5, imagerySaturation)
                        }}>
                        <BrightnessHigh color='primary' />
                      </IconButton>
                  </Stack>
              </div>
              <div style={{display: showPNGImagery ? 'flex':'none', flexDirection: 'column'}}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Saturation ({imagerySaturation.toFixed(1)})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness, imagerySaturation - .1)
                        }}>
                        <InvertColorsOff color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Brightness" 
                          value={imagerySaturation} 
                          min={0}
                          max={4}
                          step={.1}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                              updateImageryStyle(imageryBrightness, event.target.value)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness, imagerySaturation + .1)
                        }}>
                        <InvertColors color='primary' />
                      </IconButton>
                  </Stack>
              </div>
              <Divider style={{
                  display: selectedAnalytic != "none" ? 'flex':'none',
                }}/>
              <FormGroup
                style={{
                  display: selectedAnalytic != "none" ? 'flex':'none',
                }}>
                <FormControlLabel 
                  control={
                    <Switch 
                      color='secondary' 
                      checked={showAnalytics}
                      onChange={(event) => {
                        showAnalyticsRef.current = event.target.checked
                        setShowAnalytics(showAnalyticsRef.current)
                      }}/>
                  } 
                  label="Show Analytics"/>
              </FormGroup>
              {
                availableAnalytics[selectedAnalytic] != null ? availableAnalytics[selectedAnalytic]["control"](analyticStateRef, setAnalyticState):null
              }     
          </div>   

          <Button
            variant='text'
            color='primary'
            startIcon={mobileMenuExpanded ? <KeyboardDoubleArrowUp />:<KeyboardDoubleArrowDown />}
            onClick={() => {
              setMobileMenuExpanded(!mobileMenuExpanded)
            }}
            style={{margin: '5px', width: '100%', display: !mapIsStreetViewVisible ? 'flex':'none'}}>
              Settings
          </Button>
          
      </Paper>
      
      {
        isLoaded ? 
          <GoogleMap 
            zoom={zoom} 
            center={location}        
            mapContainerClassName='mapViewBody'
            mapTypeId={mapTypeId}
            options={{
              styles: googleMapsStyle,
              disableDoubleClickZoom: true,
            }}
            onLoad={mapHandleOnLoad}>
              <StreetViewPanorama
                onLoad={onStreetViewLoad}
                visible={mapIsStreetViewVisible}
                options={{
                  enableCloseButton: false, // Remove the close button
                  addressControl: false,    // Remove the address info box
                  panControl: false,        // Remove the pan control
                  zoomControl: false,       // Remove the zoom control
                  fullscreenControl: false, // Remove the fullscreen control
                  motionTrackingControl: false, // Remove motion tracking control
                }}>

              </StreetViewPanorama>
              {
                selectedLot?.polygon != null ?
                  <Polygon
                    path={selectedLot?.polygon}
                    zIndex={101}
                    options={{
                      strokeColor: theme.palette.secondary.main,
                      fillOpacity: 0.0,
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      zIndex: 101,
                    }}
                  />
                  : null
              }
              {
                Object.keys(availablePNGImagery[selectedLotKey] ?? {})
                .map((squareId) => {

                  try{
                    const tempLotKey = selectedLotKey
                    const bounds = organizationLots[tempLotKey]?.requiredSquaresBounds[squareId]   
                    
                    const dateIdUrlArray = Object.keys(availablePNGImagery[selectedLotKey][squareId])

                    return dateIdUrlArray.map((key) => {
                      const url = availablePNGImagery[selectedLotKey][squareId][key]

                      //render png imageryw
                      return <GroundOverlay
                                key={`currentSquareVisiblePngs-${selectedLotKey}-${squareId}-${key}`}
                                url={url}
                                bounds={bounds}
                                opacity={dateId == key && showPNGImagery ? (mapPanoramaIsDragging ? .75:1):0}
                                zIndex={100}
                                options={{
                                  clickable: false,
                                  zIndex: 100,                                  
                                }}
                                onLoad={() => {
                                  updateDateSliderMarksLoadState(tempLotKey, squareId, key)

                                }}
                              />                    
                    })
  
                    
                  }catch(err){
                    console.error(err)
                    return null
                  }
                })
              }              
              {
                //render the selected analytic data
                showAnalytics ? (() => {
                  try{                    
                    if(displayAnalyticData != null){
                      return availableAnalytics[selectedAnalytic] != null ? availableAnalytics[selectedAnalytic]["render"](selectedLot.id, displayAnalyticData, analyticStateRef, setAnalyticState):null
                    }else{
                      return null
                    }
                  }catch(err){
                    console.error(err)
                    return null
                  }                  
                })():null

              }
              
              
          </GoogleMap>
          :
          <div className='mapViewBodyLoading' style={{backgroundColor: theme.palette.primary.halfOpacity, }}>
            <CircularProgress color="secondary" />
          </div>
        
      }
      <Paper className='mapViewFooter'>
        {
          dateSliderMarks?.marks?.length > 0  ? (                 
              <Slider
                step={null}
                value={dateSliderValue}
                onChange={(e) => {
                   
                  handleDateSliderChange(e.target.value, true)
                }}
                marks={dateSliderMarks?.marks}
                min={dateSliderMarks?.min}
                max={dateSliderMarks?.max}
                color="secondary"                       
                sx={dateSliderMarksLoadStateStyle}
                onKeyDown={(e) => {
                  e.preventDefault();
                  
                }}/>

            ):<div style={{display: 'flex', alignItems:'center'}}><CircularProgress size={"20px"} color='primary'/><h4 style={{marginLeft: '15px'}}>Lot Data Processing...</h4></div>
        }
        
      </Paper>
    </div> 
  )
}


export default MapView