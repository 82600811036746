import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Pricing.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';

import { formatAsDollar } from '../../functions/formatAsDollar';
import { TravelExplore, Widgets } from '@mui/icons-material';

function Pricing(props) {

    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const navigate = useNavigate()
    const firestore = firebase.firestore();
    const sharedStateObj = props.sharedStateObj

    const [area, setArea] = useState(500)
    const pricingSchedule = props.generalAppConfig?.StripePricingSchedule


    
    
    

    return (
        <div className="pricingContent">
            <div className='pricingContentScroller'>
                <Paper elevation={2} className='pricingContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825', marginBottom: '30px'}}>
                        <Widgets style={{marginRight: '10px'}}/>
                        Pricing
                    </div>       

                    <div 
                        className='pricingOptionsArea'>
                        {
                            Object.keys(pricingSchedule).sort((a, b) => pricingSchedule[a].index - pricingSchedule[b].index).map((key, index) => {
                                const element = pricingSchedule[key]

                                const costPer = element?.price/100

                                return (
                                    <div 
                                        className='pricingOption'
                                        onClick={() => {
                                            sharedStateObj.sharedStateRef.current = {
                                                ...sharedStateObj.sharedStateRef.current,
                                                checkout_selectedPricingPlan: key
                                            }           

                                            //if signed in /Organization/CreateLot
                                            if (loggedIn) {
                                                navigate('/Organization/CreateLot')
                                            }else{
                                                ///Welcome/CreateLot
                                                navigate('/Welcome/CreateLot')
                                            }
                                        }}
                                        style={{/*backgroundColor: selectedPricingPlan == key ? '#f9a825':""*/}}>
                                        <h1>{element?.name}</h1>
                                        <h3>{element?.tagDescription}</h3>
                                        <h2>{formatAsDollar(costPer)}<span> km<sup>2</sup> / {element?.billingSchedule}</span></h2>
                                        <h4>{element?.savings}</h4>
                                        <Button 
                                            variant='contained'
                                            color={'primary'}
                                            style={{marginBottom: '10px', }}
                                            startIcon={<TravelExplore />}
                                            >
                                            {loggedIn ? "Create Lot":"Start Exploring"}
                                        </Button>

                                        {/* <p>{`${formatAsDollar(element?.price/100)}/${element?.units} each ${element?.billingSchedule}`}</p>
                                        <p>{optionSummaryAlt(key)}</p>
                                        <h3>{`${formatAsDollar(costPer)}/${element?.billingSchedule}`}</h3>
                                        <p>Lot Size: {convertSquareMetersToSquareKm(polygonArea)} km²</p>
                                        <p><strong>{element?.savings ?? ""}</strong></p> */}
                                    </div>
                                )

                            })
                        }                                                        

                    </div>             
                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Pricing