import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Legal.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';

import { formatAsDollar } from '../../functions/formatAsDollar';
import { Policy, TravelExplore, Widgets } from '@mui/icons-material';

function Legal(props) {

    

    
    
    

    return (
        <div className="legalContent">
            <div className='legalContentScroller'>
                <Paper elevation={2} className='legalContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825', marginBottom: '30px'}}>
                        <Policy style={{marginRight: '10px'}}/>
                        Terms of Service
                    </div>       

                    <h1 >Lot Eagle LLC Terms of Service</h1>
                    <h5>Effective Date: October 17, 2024</h5>

                    <h2>1. Acceptance of Terms</h2>
                    <p>Welcome to Lot Eagle LLC ("Lot Eagle," "we," "us," or "our"). By accessing or using our services, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not use our services.</p>

                    <h2>2. Description of Services</h2>
                    <p>Lot Eagle provides satellite imagery basemaps and a web interface for users to view and interact with the imagery, including basic analytics derived from the data ("Services"). Our Services are offered through various subscription tiers, including Basic (monthly billing) and Premium (annual billing).</p>

                    <h2>3. Eligibility</h2>
                    <p>Our Services are intended for businesses and organizations. By using our Services, you represent and warrant that you have the authority to enter into these Terms on behalf of the entity you represent.</p>

                    <h2>4. User Accounts</h2>
                    <p>
                        <ul>
                            <li><strong>Account Creation:</strong> To access our Services, you must register for an account by providing accurate and complete information.</li>
                            <li><strong>User Verification:</strong> Authentication is completed through the sign-in method provided.</li>
                            <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                        </ul>
                    </p>

                    <h2>5. Fees and Payment</h2>
                    <p>
                        <ul>
                            <li><strong>Pricing Model:</strong> Our Services are subscription-based, with a one-time payment counting as the first payment of the subscription.</li>
                            <li><strong>Billing Cycle:</strong> Billing occurs monthly for the Basic tier and annually for the Premium tier.</li>
                            <li><strong>Payment Methods:</strong> Payments are processed through Stripe using card processing.</li>
                            <li><strong>Refund Policy:</strong> All purchases are non-refundable. Exceptions may be made at our sole discretion.</li>
                        </ul>
                    </p>
                    
                    <h2>6. License and Intellectual Property</h2>
                    <p>
                        <ul>
                            <li><strong>Proprietary Rights:</strong> Lot Eagle owns all rights, title, and interest in and to the Services, including software and data created through our processes and algorithms.</li>
                            <li><strong>License Grant:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use the Services for your internal business purposes.</li>
                            <li><strong>Downloadable Data:</strong> Users may download the data they have paid for.</li>
                            <li><strong>Third-Party Content:</strong> Our Services incorporate the Google Maps API. Your use of Google Maps is subject to Google's Terms of Service.</li>
                        </ul>
                    </p>

                    <h2>7. Acceptable Use Policy</h2>
                    <p>
                        <ul>
                            <li>
                                <strong>Prohibited Actions:</strong>
                                <ul>
                                    <li>Redistribution of data without our written consent.</li>
                                    <li>Any activity that violates applicable laws or regulations.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>User Responsibilities:</strong>
                                <ul>
                                    <li>Use the Services in compliance with all applicable laws.</li>
                                    <li>Maintain the confidentiality of your account credentials.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Enforcement:</strong>
                                <ul>
                                    <li>We reserve the right to suspend or terminate accounts for violations of this policy.</li>
                                    <li>Legal action may be taken against unauthorized redistribution or misuse of data.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <h2>8. Termination</h2>
                    <p>
                        <ul>
                            <li><strong>Termination Rights:</strong> Either party may terminate these Terms at any time.</li>
                            <li>
                                <strong>Post-Termination:</strong>
                                <ul>
                                    <li>Upon termination, your access to the Services will cease.</li>
                                    <li>Data will remain available for download for 90 days after termination.</li>
                                    <li>After 90 days, data may be removed from our servers.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>

                    <h2>9. Disclaimers and Limitation of Liability</h2>
                    <p>
                        <ul>
                            <li>
                                <strong>Disclaimers:</strong> 
                                <ul>
                                    <li>Services are provided "as is" without warranties of any kind.</li>
                                    <li>We do not guarantee the accuracy, completeness, or reliability of the data.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Limitation of Liability:</strong>
                                <ul>
                                    <li>To the maximum extent permitted by law, Lot Eagle shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with the Services.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    
                    <h2>10. Indemnification</h2>
                    <p>You agree to indemnify and hold harmless Lot Eagle, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising from your use of the Services or violation of these Terms.</p>

                    <h2>11. Modifications to the Terms</h2>
                    <p>
                        <ul>
                            <li><strong>Update Policy:</strong> We reserve the right to modify these Terms at any time. Changes will be effective upon posting.</li>
                            <li><strong>Notification:</strong> Users will be notified of significant changes via email.</li>
                            <li><strong>User Consent:</strong> Continued use of the Services after changes constitutes acceptance of the new Terms.</li>
                        </ul>
                    </p>

                    <h2>12. Governing Law and Dispute Resolution</h2>
                    <p>
                        <ul>
                            <li><strong>Governing Law:</strong> These Terms are governed by the laws of the State of South Dakota, USA.</li>
                            <li><strong>Dispute Resolution:</strong> Any disputes arising from these Terms shall be resolved through mediation in South Dakota.</li>
                        </ul>
                    </p>

                    <h2>13. Compliance and Regulations</h2>
                    <p>We comply with the European Space Agency (ESA) Sentinel Data Legal Notice, as applicable. For more information, please refer to the ESA Sentinel Data Legal Notice.</p>

                    <h2>14. Support and Maintenance</h2>
                    <p>
                        <ul>
                            <li><strong>Customer Support:</strong> Support is available via email at <a href="mailto:support@loteagle.com">support@loteagle.com</a></li>
                            <li><strong>Maintenance:</strong> While no scheduled downtimes are planned, we will communicate any significant maintenance periods or issues to users as needed.</li>
                        </ul>
                    </p>

                    <h2>15. Confidentiality</h2>
                    <p>
                        <ul>
                            <li><strong>Sensitive Information:</strong> Payment information is securely processed and stored by Stripe. We do not store your card information on our servers.</li>
                            <li><strong>Data Protection:</strong> We employ industry-standard security measures to protect your personal information.</li>
                        </ul>
                    </p>

                    <h2>16. Advertising and Marketing</h2>
                    <p>
                        <ul>
                            <li><strong>User Communications:</strong> We may send you promotional emails, newsletters, and updates about new features. You may opt out of these communications at any time.</li>
                            <li><strong>Data Usage:</strong> Your email may be used to create lookalike audiences for marketing purposes.</li>
                        </ul>
                    </p>
                    
                    <h2>17. Miscellaneous Provisions</h2>
                    <p>
                        <ul>
                            <li><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and Lot Eagle regarding the use of the Services.</li>
                            <li><strong>Severability:</strong> If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in effect.</li>
                            <li><strong>Assignment:</strong> You may not assign or transfer your rights or obligations under these Terms without our prior written consent. We may assign or transfer our rights and obligations without restriction.</li>
                        </ul>
                    </p>

                    <h2>18. Contact Information</h2>
                    <p>If you have any questions about these Terms, please contact us at:</p>
                    <p>
                        Lot Eagle LLC<br/>
                        41 W Highway 14 PMB 2757<br/>
                        Spearfish, SD 57783-1148<br/>
                        United States<br/>
                        Email: <a href="mailto:support@loteagle.com">support@loteagle.com</a>
                    </p>

                    <p><strong>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</strong></p>
                    
                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Legal